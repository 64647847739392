import { all } from "redux-saga/effects";
import auth from "./Auth";
import team from "./Team";
import member from "./Member";
import manageTeams from "./manage-teams";
import Invitation from "./Invitation"

export default function* rootSaga() {
    yield all([
        auth(),
        team(),
        member(),
        Invitation(),
        manageTeams(),
    ]);
}
