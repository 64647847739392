import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  CardBody,
  Button,
  Modal as Mod,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Tooltip,
} from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import isEmpty from "lodash/isEmpty";
import { UnfreezeUI } from "./../../assets/plugins/nucleo/js/freeze-ui";
import { isNull, map } from "lodash";

const Profile = () => {
  const user = useSelector((state) => ({
    userProfile: state.auth?.profile,
    activeCompany: state.auth?.profile?.activeCompany,
    companyList: state.auth?.profile?.companyList,
    activeTeamId: state.team.selectedTeamID,
    activeUserId: state.team.activeUserID,
    all: state.team.all,
  }));
  const team = useSelector((state) => ({
    loading: state.team.loading,
    activeTeam: state.team.activeTeam,
    activeUserID: state.team.activeUserID,
    teams: state.team.teams,
    profileID: state.auth.profile.id,
    all: state.team.all,
    selectedTeamID: state.team.selectedTeamID,
    active: state.team.active,
    week: state.team.week,
  }));
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [rSelected, setRSelected] = useState(0);
  const [toolOpen, setToolOpen] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleTool = () => {
    setToolOpen(!toolOpen);
  };

  const handleChange = (value) => {
    let obj = { Team: value.Team, activeTeamid: value.activeTeamid };
    localStorage.setItem("teamData", JSON.stringify(obj));
    dispatch({
      type: "team/SET_SELECT_TEAM_ID",
      payload: {
        selectedTeamID: value.activeTeamid,
        activeUserID: user.userProfile?.id,
        activeTeam: {
          Team: value.Team,
          activeTeamid: value.activeTeamid,
        },
        companyId: user.activeCompany.companyId,
      },
    });
    setModal(false);
    setTimeLogDays(rSelected, value.activeTeamid);
    UnfreezeUI();
  };

  const setTimeLogDays = (days, id, start, end) => {
    dispatch({
      type: "team/SET_TIMELOG_DAY_RANGE",
      payload: { days, start, end },
    });

    dispatch({
      type: "member/SET_TIMELOG_DAY_RANGE",
      payload: { days, start, end },
    });

    dispatch({
      type: "team/SET_TODAY_TIMES",
      payload: {
        selectedTeamID: id,
        day: days,
      },
    });
    dispatch({
      type: "team/SET_TRENDS_DATA",
      payload: {
        selectedTeamID: id,
        week: team.week,
      },
    });
    // dispatch({
    //   type: "team/SET_TOP_USERS",
    //   payload: {
    //     selectedTeamID: id,
    //     day: days,
    //   },
    // });

    if (user.all) {
      dispatch({
        type: "team/GET_ALL_TEAM_TIMELOG",
        payload: {
          days,
          start,
          end,
          teamId: id,
        },
      });
    } else {
      dispatch({
        type: "team/GET_MANAGER_TEAM_COLLECTION",
        payload: {
          days,
          start,
          end,
          activeTeamId: id,
          activeUserId: user.activeUserId,
        },
      });
    }
  };

  return (
    <>
      <div style={{ paddingTop: "5rem!important" }}>
        {/* {userData !== [] ? ( */}
        <Container className="pt-md-2" fluid>
          <Card>
            <CardBody className="d-flex justify-content-center flex-column align-items-center">
              <h3 style={{ color: "#B2DD4C" }}>Profile</h3>
              <h4 className="mt-3">
                Name:{" "}
                <span style={{ color: "#B2DD4C" }}>
                  {user?.userProfile?.name}
                </span>
              </h4>
              <h4 className="mt-3">
                Active Company:{" "}
                <span style={{ color: "#B2DD4C" }}>
                  {user?.activeCompany?.companyName}
                </span>
              </h4>
              <h4 className="mt-3">
                Active Team:{" "}
                <span style={{ color: "#B2DD4C" }}>{team?.active?.Team}</span>
              </h4>
              {team?.teams?.data?.length <= 1 ? (
                <>
                  <Tooltip
                    placement="right"
                    isOpen={toolOpen}
                    target="oneTeam"
                    toggle={toggleTool}
                  >
                    Only one team detected. It is selected as default.
                  </Tooltip>
                  <Button id="oneTeam" className="mt-4 w-25">
                    Change Team
                  </Button>
                </>
              ) : (
                <Button className="mt-3" onClick={() => toggle()}>
                  Change Team
                </Button>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Mod isOpen={modal} toggle={toggle} style={{ marginTop: "100px" }}>
        <ModalHeader toggle={toggle}>Choose a Team</ModalHeader>
        <ModalBody>
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              {!isEmpty(team.teams) ? (
                map(team.teams.data, (value) => {
                  if (value.teamRemoved) return null;
                  return (
                    <Col md={12} className="mt-1">
                      <Button
                        className="modal-button w-100"
                        onClick={() => {
                          handleChange(value);
                        }}
                      >
                        {value.Team}
                      </Button>
                    </Col>
                  );
                })
              ) : (
                <PulseLoader color="#36d7b7" />
              )}
            </Row>
            {/* </Input> */}
          </Container>
        </ModalBody>
      </Mod>
    </>
  );
};

export default Profile;
