import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Col, FormFeedback } from "reactstrap";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import {
  FreezeUI,
  UnfreezeUI,
} from "./../../../assets/plugins/nucleo/js/freeze-ui";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { FiChevronRight } from "react-icons/fi";
import SelectTeam from "../SelectTeam";
export default function SelectCompany() {
  const teamReducer = useSelector((state) => ({
    selectTeams: state.team.selectTeams,
  }));
  const history = useHistory();
  const authState = useSelector((state) => ({ ...state.auth }));
  const isUserAuthorized = authState.authorized;
  const companyList = authState.profile?.companyList ?? [];
  const [selectedCompany, setCompany] = useState();
  const [error, setError] = useState({ isError: false, msg: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("teamData", []);
    // Redirect unAuthorized users to home page
    if (!authState.loading && !isUserAuthorized) {
      history.push("/");
    }
    if (authState.loading) FreezeUI();
    if (!authState.loading) UnfreezeUI();
  }, [authState]);

  const itemTemplate = (option) => {
    return (
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          textTransform: "capitalize",
        }}
      >
        <div className="font-weight-bold">{option.companyName}</div>
        <div>
          <FiChevronRight size={21} />
        </div>
      </div>
    );
  };

  const handleNavigation = () => {
    if (!selectedCompany) {
      setError({
        isError: true,
        msg: "Please select at least one of the companies.",
      });
    } else {
      try {
        dispatch({
          type: "team/SWITCH_COMPANY_ACTIVE_TEAM",
          payload: {
            companyId: selectedCompany,
            userId: authState?.profile?.id,
          },
        });
      } catch (error) {
        return error;
      }
    }

  };
  console.log(teamReducer.selectTeams.data)
  return (
    <>{teamReducer?.selectTeams?.data?.length>0 ? <SelectTeam/> :
      <Col lg="5" md="7">
        <Card className="bg-secondary border-0">
          <CardHeader className="bg-transparent p-0 m-0 border-0">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>Select a company</h2>
              <small>
                Select any company to navigate to the relevant dashboard.
              </small>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-4">
            <SelectButton
              optionLabel="companyName"
              optionValue="companyId"
              className="company_select-btn"
              itemTemplate={itemTemplate}
              options={companyList}
              value={selectedCompany}
              onChange={(e) => setCompany(e.value)}
            >
            </SelectButton>
            {error.isError && (
              <FormFeedback className="d-block text-center">
                {error.msg}
              </FormFeedback>
            )}
            <div className="w-100 text-center mt-3">
              <Button
                type="button"
                onClick={() => handleNavigation()}
                label="Navigate"
                className="p-button p-button-secondary"
                icon="pi pi-arrow-right"
                iconPos="right"
              />
            </div>
          </CardBody>
        </Card>
      </Col>}
    </>
  );
}
