import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import listPlugin from "@fullcalendar/list"; // a plugin!
import Select from "react-select";
import { usePrevious } from "../../hooks/userPrevious";
import ExportDataModal from "../../components/Extra/export-data-modal";
import moment from "moment";
import firebase from "../../config";
import BlockUi from "react-block-ui";

const UserAttendance = () => {
  const database = firebase.firestore();
  const dispatch = useDispatch();
  const teams = useSelector((state) => ({
    teams: state.team.teams,
  }));
  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  const user = useSelector((state) => ({
    userProfile: state.auth?.profile,
    activeCompany: state.auth?.profile?.activeCompany,
    companyList: state.auth?.profile?.companyList,
    activeTeamId: state.team.selectedTeamID,
    activeUserId: state.team.activeUserID,
    all: state.team.all,
  }));

  const teamReducer = useSelector((state) => (
    state.team.active
  ))

  let prevTeams = usePrevious(teams?.teams);
  let currentTeams = teams?.teams;
  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let members = successPayload ? successPayload.data : [];
  const [options, setOptions] = useState([]);
  const [selectOption, setSelectOptions] = useState({});
  const [statess, setStatess] = useState({
    open: "",
    selectedTitle: "",
  });
  const [timeLogInfo, setTimeLogInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCalenderMonthCalled, setCalenderMonthCalled] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const renderEventContent = (eventInfo) => {
    return eventInfo.event.title === "red" ? (
      <div style={{ background: "red" }}>
        <b>{eventInfo.timeText}</b>
        <i>Leave</i>
      </div>
    ) : eventInfo.event.title === "green" ? (
      <div style={{ background: "green" }}>
        <b>{eventInfo.timeText}</b>
        <i>Present</i>
      </div>
    ) : (
      eventInfo.event.title === "orange" && (
        <div style={{ background: "orange" }}>
          <b>{eventInfo.timeText}</b>
          <i>Half day</i>
        </div>
      )
    );
  };

  useEffect(() => {
    if(user.activeCompany.role === "Member") return
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
      }
    }
  }, [teams]);

  useEffect(() => {
    if(user.activeCompany.role === "Member"){
      let member = {
        value: user.userProfile.id,
        label: user.userProfile.name
      }
      handleSelectChange(member);
      return
    }
    if (members) {
      setOptions(
        members?.map((member) => ({
          value: member.userUID,
          label: member.userName,
        }))
      );
    }
  }, [members]);

  const getMembersTimeInfo = async (month, year, userId) => {
    const daysInMonth = new Date(year, month, 0).getDate();
    const formattedDays = [];
    const teamId = teamReducer.activeTeamid ;
    for (let day = 1; day <= daysInMonth; day++) {
      const formattedDay = `${day.toString().padStart(2, "")}:${month
        .toString()
        .padStart(2, "")}:${year}`;
      formattedDays.push(formattedDay);
    }
    for (let day = 0; day <= formattedDays.length; day++) {
      setIsLoading(true);
      let data;
      if (formattedDays[day] !== undefined && teamId && userId) {
        await database
          .collection("teamDatabase")
          .doc(teamId)
          .collection("members")
          .doc(userId)
          .collection("days")
          .doc(formattedDays[day])
          .get()
          .then((response) => {
            if (response.exists) {
              data = response.data();
              // let isPunchInIncluded;
              if (data?.leave || !(data?.lastPunchinoutStatus)) {
                // isPunchInIncluded = Object?.values(data?.punchinout)?.includes(
                //   "Present"
                // );
                const momentDate = moment(formattedDays[day], "DD:MM:YYYY");
                const formattedDate = momentDate.format("YYYY-MM-DD");
                const newTimeLogInfo = {
                  Status: "Leave",
                  date: formattedDate,
                  title: "red",
                };
                setTimeLogInfo((prevData) => [...prevData, newTimeLogInfo]);
              } else if(!(data?.leave) && Object?.values(data?.punchinout)?.includes("punchin")) {
                const momentDate = moment(formattedDays[day], "DD:MM:YYYY");
                const formattedDate = momentDate.format("YYYY-MM-DD");
                const newTimeLogInfo = {
                  Status: "Present",
                  date: formattedDate,
                  title: "green",
                };
                setTimeLogInfo((prevData) => [...prevData, newTimeLogInfo]);
              }else {
                const originalDate = formattedDays[day];
                const momentDate = moment(originalDate, "D:M:YYYY");
                const formattedDate = momentDate.format("YYYY-MM-DD");
                const newTimeLogInfo = {
                  Status: "Leave",
                  date: formattedDate,
                  title: "red",
                };
                setTimeLogInfo((prevData) => [...prevData, newTimeLogInfo]);
              }
              // if (isPunchInIncluded) {
              //   for (let key in data?.punchinout) {
              //     const date = moment(key).format("YYYY-MM-DD");
              //     data.Status = "Present";
              //     data.date = date;
              //     data.title = "green";
              //   }
              // } else {
              //   for (let key in data?.punchinout) {
              //     const date = moment(key).format("YYYY-MM-DD");
              //     data.Status = "Leave";
              //     data.date = date;
              //     data.title = "red";
              //   }
              // }
              // setTimeLogInfo((prevData) => [...prevData, data]);
            } else {
              const originalDate = formattedDays[day];
              const momentDate = moment(originalDate, "D:M:YYYY");
              const formattedDate = momentDate.format("YYYY-MM-DD");
              const newTimeLogInfo = {
                Status: "Leave",
                date: formattedDate,
                title: "red",
              };
              setTimeLogInfo((prevData) => [...prevData, newTimeLogInfo]);
            }
          });
      }
    }
    setIsLoading(false);
    return formattedDays;
  };

  useEffect(() => {
    const date = new Date();
    if (selectOption && selectOption?.value) {
      getCalenderMonth(date, user?.userProfile?.id);
    }
    return () => {};
  }, [selectOption]);

  function getCalenderMonth(date, userId) {
    if (user?.activeTeamId && !isCalenderMonthCalled) {
      setTimeLogInfo([]);
      const currentDate = new Date();
      if (
        date.getFullYear() === currentDate.getFullYear() &&
        date.getMonth() <= currentDate.getMonth()
      ) {
        const month = date?.getMonth() + 1;
        const year = date?.getFullYear();
        getMembersTimeInfo(month, year, userId);
        setCalenderMonthCalled(true);
      }
    }
  }

  const [dataFetched, setDataFetched] = useState(false);
  const [selectedUser, setSelectedUser] = useState(user)
  const handleSelectChange = (option) => {
    setSelectedUser(option)
    dispatch({
      type: "member/GET_USER_TIMELOG_DATA",
      payload: {
        activeUserName: option.label,
      }});
    setShowCount(true);
    setSelectOptions(option);
    setTimeLogInfo([]); // Reset the timeLogInfo state
    if (option && option.value) {
      const month = currentMonth.getMonth() + 1;
      const year = currentMonth.getFullYear();
      if (!dataFetched) {
        getMembersTimeInfo(month, year, option.value);
        setCalenderMonthCalled(true);
      } else {
        setDataFetched(false); // Reset the dataFetched flag
        setCalenderMonthCalled(false);
      }
    }
  };

  const [presentCount, setPresentCount] = useState(0);
  const [leaveCount, setLeaveCount] = useState(0);

  const calculatePresentAndLeave = () => {
    const presentItems = timeLogInfo.filter((item) => item.title === "green");
    const leaveItems = timeLogInfo.filter((item) => item.title === "red");

    setPresentCount(presentItems.length);
    setLeaveCount(leaveItems.length);
  };

  useMemo(() => {
    calculatePresentAndLeave();
  }, [timeLogInfo]);

  let activeUser = {
    activeTeamId: user.activeTeamId,
    activeUserId:selectedUser.value?selectedUser.value: user.userProfile.id,
  }
  return (
    <div style={{ paddingTop: "5rem!important" }}>
      <Container className="pt-md-2" fluid>
        <Card className="w-100 p-2 mb-1">
          <div className="d-flex justify-content-between sub-header">
            {showCount && selectOption && selectOption.value ? (
              <div className="d-flex align-items-center flex-row">
                <h4>
                  Present:{" "}
                  <span style={{ color: "green" }}>
                    {!isLoading ? presentCount : "0"}
                  </span>
                </h4>
                <h4 className="ml-4">
                  Leaves:{" "}
                  <span style={{ color: "red" }}>
                    {!isLoading ? leaveCount : "0"}
                  </span>
                </h4>
              </div>
            ) : 
            user.activeCompany.role !== "Member" ? (
              <Row className="align-items-center">
                <Col className="d-flex align-items-center flex-row">
                  <h4>Please Select an employee</h4>
                </Col>
              </Row>
            ):''}
            <Row>
              <Col xs={12} className="d-flex align-items-center mt-md-0">
                {user.activeCompany.role !== "Member" && (
                  <div className="mr-4 w-50">
                    <Select
                      options={options}
                      className="w-100"
                      placeholder="Select member"
                      values={selectOption}
                      onChange={handleSelectChange}
                      name="member"
                    />
                  </div>
                )}
                <Button
                  color="info"
                  size="sm"
                  onClick={() =>
                    setStatess({
                      open: "export-data",
                      selectedTitle: "Export Data",
                    })
                  }
                >
                  Export Data
                </Button>
              </Col>
            </Row>
          </div> 
        </Card>
        {selectOption.value ? (
          timeLogInfo?.length > 0 && !isLoading ? (
            <FullCalendar
              plugins={[dayGridPlugin, listPlugin]}
              datesSet={(arg) => {
                if (arg.start && arg.start instanceof Date) {
                  const date = arg.view.currentStart;
                  setCurrentMonth(date);
                  setCalenderMonthCalled(false);
                  getCalenderMonth(date, selectOption?.value);
                }
              }}
              initialView={window.innerWidth < 769 ? 'listWeek' : 'dayGridMonth'}
              // defaultView="dayGridMonth"
              lazyFetching={true}
              headerToolbar={{
                left: "title",
                center: "",
                right: "prev next",
              }}
              weekends={true}
              eventContent={renderEventContent}
              events={timeLogInfo.map((item) => ({
                title: item.title,
                date: item.date,
              }))}
              initialDate={currentMonth}
            />
          ) : (
            <div className="loader-container">
              <BlockUi tag="div" blocking message="Loading, please wait" />
            </div>
          )
        ) : (
          <div></div>
        )}
        {/* {timeLogInfo?.length > 0 && !isLoading ? <FullCalendar
          plugins={[dayGridPlugin, listPlugin]}
          datesSet={(arg) => {
            if (arg.start && arg.start instanceof Date) {
              const date = arg.view.currentStart;
              setCurrentMonth(date);
              setCalenderMonthCalled(false);
              getCalenderMonth(date, selectOption?.value);
            }
          }}
          defaultView="dayGridMonth"
          lazyFetching={true}
          headerToolbar={{
            left: "title",
            center: "",
            right: "prev next",
          }}
          weekends={true}
          eventContent={renderEventContent}
          events={timeLogInfo.map((item) => ({
            title: item.title,
            date: item.date,
          }))}
          initialDate={currentMonth}
        /> : <div className="loader-container">
          <BlockUi
            tag="div"
            blocking
            message="Loading, please wait"
          />
        </div>} */}
        <Modal
          isOpen={statess.open === "export-data"}
          size="sm"
          toggle={() =>
            setStatess({
              open: "",
              selectedTitle: "",
            })
          }
        >
          <ModalHeader
            toggle={() => setStatess({ open: "", selectedTitle: "" })}
          >
            {statess.selectedTitle}
          </ModalHeader>
          <ModalBody>
            <div style={{ padding: "20px" }}>
              <ExportDataModal user={activeUser} isAttendance={true} />
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default UserAttendance;
