import {
    all,
    takeEvery,
    put,
    call,
} from "redux-saga/effects";
import actions from "../../actions/allActions";
import teamService from '../../services/manageTeams';

export function* GET_MEMBERS({ payload }) {
    yield put({ type: "teams/GET_MEMBERS_PENDING" })
    try {
        let { success, data } = yield call(teamService.getAllTeamMembersData, { ...payload });
        if (success) {
            yield put({ type: "teams/GET_MEMBERS_SUCCESS", payload: { success: true, data: [...data] } })
        } else {
            yield put({ type: "teams/GET_MEMBERS_FAILED", payload: { success: false, data: "No data available" } })
        }
    } catch (e) {
        yield put({ type: "teams/GET_MEMBERS_FAILED", payload: e })
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_MEMBERS, GET_MEMBERS)
    ]);
}