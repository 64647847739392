import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Card,
} from "reactstrap";
import { Column } from "primereact/column";
import { isEmpty } from "lodash";
import firebase from "../../config";
import { DataTable } from "primereact/datatable";
import PulseLoader from "react-spinners/PulseLoader";
import { usePrevious } from "../../hooks/userPrevious";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";

const database = firebase.firestore();

const LeaveRequest = () => {
  const teams = useSelector((state) => ({
    projects: state.team.projects,
    active: state.team.active,
    teams: state?.team?.teams,
  }));
  const user = useSelector((state) => ({
    activeTeamId: state.team.selectedTeamID,
  }));
  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  let prevTeams = usePrevious(teams?.teams);
  let currentTeams = teams?.teams;
  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let members = successPayload ? successPayload.data : [];
  const { activeCompany } = useSelector((state) => state.auth.profile);
  const { profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [regList, setRegList] = useState([]);
  const [, setTaskRowIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState(null);

  const getRegData = () => {
    setLoading(true);
    setRegList([]);
    const data = [];
    database
      .collection("membersDatabase")
      .doc(profile.id)
      .get()
      .then(async (res) => {
        const response = res.data();
        const req = response?.leaveRequests;
        for (let i = 0; i < req?.length; i++) {
          await database
            .collection("teamDatabase")
            .doc(teams.active.activeTeamid)
            .collection("leaveRequests")
            .doc(req[i])
            .get()
            .then(async (res) => {
              const auto = res?.data();
              if (auto && auto?.status !== "Declined") {
                data.push({ ...auto, id: req[i] });
              }
              setRegList(data);
            });
        }
        setLoading(false);
      });
  };

  const pad2 = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  // handle approval
  const handleApprove = async (e) => {
    e.preventDefault();
    let timeTemp = selectedData?.leaveDate.split("/").join("-")
    let time = new Date(timeTemp);
    let exists = false;
    let d = time.setHours(time.getHours());
    d = time.setMinutes(time.getMinutes());
    d = new Date(d);

    delete selectedData.assignee;
    delete selectedData.requestDate;
    delete selectedData.status;
    var month = pad2(d.getMonth() + 1); //months (0-11)
    var day = pad2(d.getDate()); //day (1-31)
    var year = d.getFullYear();
    let dateId = day + ":" + month + ":" + year;
    dateId = dateId.replace(/(^|-|:)0+/g, "$1");

    return database
      .runTransaction(async (transaction) => {
        const ref = database.collection("teamDatabase").doc(user?.activeTeamId).collection("leaveRequests").doc(selectedData?.id);
        delete selectedData.id;
        const memb = database
          .collection("teamDatabase")
          .doc(user?.activeTeamId)
          .collection("members")
          .doc(selectedData?.userId)
          .collection("days")
          .doc(dateId);

        await transaction?.get(memb).then(async (res) => {
          if (res?.exists) {
            exists = true;
          }
        });
        if (exists === true) {
          await transaction.update(memb, {
            leave:true
          });
        } else {
          await transaction.set(memb, {
            leave: true,
          });
        }
        await transaction?.update(ref, { status: "Approved" });
      })
      .then((res) => {
        toggle();
        addNotification({
          message: "Request approved successfully",
          level: notificationStates.success,
        });
        getRegData();
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          message: "Request approval error",
          level: notificationStates.error,
        });
      });
  };

  // Decline regulation
  const handleDecline = async () => {
    return database
      .runTransaction(async (transaction) => {
        const ref = database.collection("teamDatabase").doc(user?.activeTeamId).collection("leaveRequests").doc(selectedData?.id);
        const memb = database.collection("membersDatabase").doc(selectedData?.assignee);

        await transaction.update(ref, { status: "Declined" });
        await transaction?.update(memb, {
          leaveRequests: firebase.firestore.FieldValue.arrayRemove(
            selectedData?.id
          ),
        });
      })
      .then((res) => {
        toggle();
        addNotification({
          message: "Request declined successfully",
          level: notificationStates.success,
        });
        getRegData();
      })
      .catch((error) => {
        addNotification({
          message: "Error declining request",
          level: notificationStates.error,
        });
        return error;
      });
  };

  const toggle = () => {
    // if (selectedData) {
    setModal(!modal);
  };

  const fieldIndex = (rowData, column) => {
    setTaskRowIndex(column.rowIndex + 1);
    return column.rowIndex + 1 + "";
  };

  // const paginatorLeft = (
  //   <Button type="button" icon="pi pi-refresh" className="p-button-text" />
  // );
  // const paginatorRight = (
  //   <Button type="button" icon="pi pi-cloud" className="p-button-text" />
  // );

  const ReporterTemplet = ( rowData ) => {
    return (
      members?.length > 0 &&
      members?.filter((a) => {
        if (a.userUID == rowData?.userId) {
          return a;
        }
      })[0]?.userName
    );
  }

  const ReportDateTempelate = (rowData) => {
    let dateObject = rowData?.requestDate?.toDate();
    var day = dateObject?.getDate();
    var month = dateObject?.getMonth() + 1;
    var year = dateObject?.getFullYear();

    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    let dateString = day + "-" + month + "-" + year;
    return dateString ? dateString : "";
  };

  const TaskDateTempelate = (rowData) => {
    var dateStringtemp = rowData?.leaveDate;
    var parts = dateStringtemp?.split("-");
    var yeartemp = parseInt(parts[0]);
    var monthtemp = parseInt(parts[1]) - 1;
    var daytemp = parseInt(parts[2]);

    // Create a Date object
    var dateObject = new Date(yeartemp, monthtemp, daytemp);

    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1;
    var year = dateObject.getFullYear();

    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    let dateString = day + "-" + month + "-" + year;
    return dateString? dateString : "";
  };

  const actionTempelate = (rowData) => {
    if (rowData) {
      if (rowData?.status === "Approved") {
        return <h4>Completed</h4>;
      } else {
        return (
          <Button
            className="request-action-btn"
            onClick={() => {
              toggle();
              setSelectedData(rowData);
            }}
          >
            Process
          </Button>
        );
      }
    }
  };

  useEffect(() => {
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
      }
    }
    if (!isEmpty(teams.active)) {
      dispatch({
        type: "team/GET_PROJECT",
        payload: { companyId: activeCompany.companyId },
      });
      if (loading) {
        getRegData();
      }
    }
  }, [activeCompany]);

  return (
    <>
      <div style={{ paddingTop: "5rem!important" }}>
        <Container className="pt-md-2" fluid>
          {loading ? (
            <div className="w-100 d-flex justify-content-center">
              <PulseLoader color="#36d7b7" />
            </div>
          ) : (
            <Card className="bg-secondary shadow mt-2">
              <DataTable
                className="mt-2 task-table task-page-table"
                responsiveLayout="scroll"
                value={regList}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={50}
                rowsPerPageOptions={[10, 20, 50]}
                // paginatorLeft={paginatorLeft}
                // paginatorRight={paginatorRight}
              >
                <Column
                  field="#"
                  header="ID"
                  body={fieldIndex}
                  className="first-col td-start"
                ></Column>
                <Column
                  field="comment"
                  header="Comment"
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="type"
                  header="Type"
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="leaveDate"
                  header="Leave Date"
                  body={TaskDateTempelate}
                  sortable
                  className="first-col td-start"
                ></Column>
                <Column
                  field="reportDate"
                  header="Report Date"
                  body={ReportDateTempelate}
                  sortable
                  className="first-col td-start"
                ></Column>
                <Column
                  field="reporter"
                  header="Reporter"
                  body={ReporterTemplet}
                  className="td-start"
                  sortable
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  sortable
                  className="td-start"
                ></Column>
                <Column
                  field="action"
                  header="Action"
                  body={actionTempelate}
                  className="td-start"
                  sortable
                ></Column>
              </DataTable>
            </Card>
          )}
        </Container>
      </div>

      {/* Modal for accepting or declining regularization */}
      <Modal isOpen={modal} toggle={toggle} className="regulationModal">
        <ModalHeader toggle={toggle}>Process Form</ModalHeader>
        <ModalBody className="pt-2 pl-4">
          <h4>Please accept or decline this application</h4>
          <h5>Note:- Request can not be update and delete after proceed..</h5>
          <br />
          <Form onSubmit={handleApprove}>
            <Button color="primary">Accept</Button>
            <Button onClick={handleDecline} color="danger">
              Decline
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LeaveRequest;
