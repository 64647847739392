import React, { PureComponent } from "react";
import map from "lodash/map";
import { connect } from "react-redux";
import "./style.css";
import { Card, Container, Row, Col } from "reactstrap";

import Popover from "@material-ui/core/Popover";

class WorkedMostLeastHours extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }

  workedMostHoursPopup = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };
  workedLeastHoursPopup = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };

  filterUnProductiveTime(times, users) {
    const filtered = users
      .filter((user) => {
        const hours = Math.floor(times[user].idleMinutes / 60);
        times[user].idleMinutes = times[user].idleMinutes % 60;
        times[user].idleHours += hours;
        if (times[user].idleHours > 0) {
          return times[user].idleHours > 0;
        } else if (
          times[user].idleHours === 0 &&
          times[user].idleMinutes > 0
        ) {
          return times[user].idleMinutes > 0;
        }
      })
      .map((user) => {
        return times[user];
      })
      .sort((a, b) => {
        return (
          a.idleHours +
          a.idleMinutes / 60 -
          (b.idleHours + b.idleMinutes / 60)
        );
      })
      .splice(0, 4);
      console.log(filtered)
    return filtered;
  }

  unProductiveTime(times) {
    const n = Object.keys(times);
    const filtered = this.filterUnProductiveTime(times, n);
    const f = Object.keys(filtered);
    var colorArray = ["yellow", "pink", "ash", "cyan", "purple"];
    return f.map((element) => {
      const percent =
        ((filtered[element].idleHours +
          filtered[element].idleMinutes / 60) /
          (filtered[element].totalHours +
            filtered[element].totalMinutes / 60)) *
        100;

      const rand = Math.floor(Math.random() * colorArray.length);
      return (
        <Row className="mr-0">
          <div className=" pr-0 bar-label-name pl-3">
            <div
              className={`inline-block circle-radialbar ${colorArray[rand]}`}
            >
              {filtered[element].userName.charAt(0)}
            </div>
            <div className="inline-block">
              {filtered[element].userName.replace(/ .*/, "")}
            </div>
          </div>
          <div className="pt-1 pl-3 percentage-data">
            <div
              class="bar-line-container inline-block line-container"
              style={{ width: `${percent}%` }}
            ></div>
            <div className="bar-label">
              {filtered[element].idleHours}h{" "}
              {filtered[element].idleMinutes}m
            </div>
          </div>
        </Row>
      );
    });
  }

  filterProductiveTime(times, users) {
    const filtered = users
      .filter((user) => {
        const hours = Math.floor(times[user].productiveMinutes / 60);
        times[user].productiveMinutes = times[user].productiveMinutes % 60;
        times[user].productiveHours += hours;
        if (times[user].productiveHours > 0) {
          return times[user].productiveHours > 0;
        } else if (
          times[user].productiveHours === 0 &&
          times[user].productiveMinutes > 0
        ) {
          return times[user].productiveMinutes > 0;
        }
      })
      .map((user) => {
        return times[user];
      })
      .sort((a, b) => {
        return (
          b.productiveHours +
          b.productiveMinutes / 60 -
          (a.productiveHours + a.productiveMinutes / 60)
        );
      })
      .splice(0, 4);

    return filtered;
  }

  productiveTime(times) {
    if (Object.keys(times).length !== 0) {
      const n = Object.keys(times);
      const filtered = this.filterProductiveTime(times, n);
      const f = Object.keys(filtered);
      var colorArray = ["yellow", "pink", "ash", "cyan", "purple"];
      return f.map((element) => {
        const percent =
          ((filtered[element].productiveHours +
            filtered[element].productiveMinutes / 60) /
            (filtered[element].totalHours +
              filtered[element].totalMinutes / 60)) *
          100;
        const rand = Math.floor(Math.random() * colorArray.length);
        return (
          <Row className="mr-0">
            <div className=" pr-0 bar-label-name pl-3">
              <div
                className={`inline-block circle-radialbar ${colorArray[rand]}`}
              >
                {filtered[element].userName.charAt(0)}
              </div>
              <div className="inline-block">
                {filtered[element].userName.replace(/ .*/, "")}
              </div>
            </div>
            <div className="pt-1 pl-3 percentage-data">
              <div
                class="bar-line-container inline-block line-container"
                style={{ width: `${percent}%` }}
              ></div>
              <div className="bar-label">
                {filtered[element].productiveHours}h{" "}
                {filtered[element].productiveMinutes}m
              </div>
            </div>
          </Row>
        );
      });
    }
  }

  render() {
    // const { teamLogs } = this.props;
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Container className="mt-2" fluid>
          <Row >
            <Col lg={6} xs={12} className="mt-2 pr-lg-1">
              <Card className="shadow p-3">
                <div style={{ minHeight: `auto` }} className="p--1">
                  <Row>
                    <div className="col">
                      <span className="headline">Worked most hours</span>
                    </div>
                  </Row>
                  <Row className="dashboard-section-body worked-most-hours">
                    <div className="col">
                      <div class=" bar-container">
                        {this.props.topUsers && this.productiveTime(this.props.topUsers)}
                      </div>
                    </div>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col lg={6} xs={12} className="productive mt-2">
              <Card className="shadow p-3">
                <div style={{ minHeight: `auto` }} className="p--1">
                  <Row>
                    <div className="col">
                      <span className="headline">Worked least hours</span>
                    </div>
                  </Row>
                  <Row className="dashboard-section-body worked-least-hours">
                    <div className="col">
                      <div class="bar-container">
                        {this.props.topUsers && this.unProductiveTime(this.props.topUsers)}
                      </div>
                    </div>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkedMostLeastHours);
