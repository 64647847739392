import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export default function InvitationForm({ invitationData, authState, invitationId }) {

    const history = useHistory();
    const dispatch = useDispatch();
    const acceptInviteState = useSelector((state) => ({ ...state.invitation.acceptInvite }));
    const { pending, success, failure, successPayload, failurePayload } = acceptInviteState


    const handleAction = (type) => {

        if (type === '1') {
            // Accepts user invitation
            const payload = {
                invitationId,
                companyDetails: { ...invitationData.invitedBy?.companyDetails },
                teamDetails: invitationData?.invitedTo,
                userDetails: {
                    userId: authState?.profile?.id,
                    userName: authState?.profile?.name,
                    userEmail: authState?.profile?.email,
                    invitedAs: invitationData?.invitedAs
                }
            }
            dispatch({ type: 'user/ACCEPT_INVITATION', payload: { ...payload } })
        } else {
            localStorage.removeItem('pendingInvitation');
            history.push(`/dashboard-team?select=1`);
        }
    }

    useEffect(() => {
        if (success) {
            localStorage.removeItem('pendingInvitation');
            history.push(`/dashboard-team?cid=${invitationData?.invitedBy?.companyDetails?.companyId}`);
            window.location.reload();
        }
    }, [success])

    return (
        <>
            <p><small>Accept the invitation</small></p>
            <p className="font-weight-bold mt-1">
                You have been invited to join the {' '}
                <b className="text-dark">{invitationData?.invitedBy?.fullName}'s</b> {' '}
                {invitationData?.invitedAs != 'Admin'
                    ? <>team (<b className="text-dark">{invitationData?.invitedTo?.teamName}</b>)</>
                    : <>company (<b className="text-dark">{invitationData?.invitedBy?.companyDetails?.companyName}</b>)</>
                }
                {' '}
                {invitationData?.invitedAs != 'Admin' &&
                    <>in <b className="text-dark">{invitationData?.invitedBy?.companyDetails?.companyName}'s Company</b>
                        {' '}
                    </>
                }
                as a <b className="text-dark">{invitationData?.invitedAs}</b>.
            </p>
            <div className="text-center">
                <div className='row my-4'>
                    <div className='col-6 pl-0 '>
                        <Button type='button' onClick={() => handleAction('0')} label="Decline" className="p-button-outlined p-button-danger p-button w-100" />
                    </div>
                    <div className='col-6 pr-0'>
                        <Button loading={pending} type='button' onClick={() => handleAction('1')} label="Accept" className='p-button p-button-primary w-100' />
                    </div>
                </div>
                {/* <Button className="mt-4 w-50" color="danger">
                    <Spinner style={{ height: '1rem', width: '1rem' }} /> : "Decline"}
                </Button>
                <Button className="mt-4 w-50" color="primary">
                    {acceptInviteState.pending ? <Spinner style={{ height: '1rem', width: '1rem' }} /> : "Accept"}
                </Button> */}
            </div>
        </>
    );
}