import React, { useState, useEffect } from "react";
import { Button, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  errorRenderer,
  reactSelectErrorStyles,
} from "./../../../utils/utility";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { usePrevious } from "./../../../hooks/userPrevious";
import {
  FreezeUI,
  UnfreezeUI,
} from "./../../../assets/plugins/nucleo/js/freeze-ui";
import { addNotification, notificationStates } from "../../../components/Notification";

export default function TeamInviteForm() {

  const FormSchema = Yup.object().shape({
    emails: Yup.array()
      .of(Yup.string().required("At least 1 email address"))
      .min(1, "At least 1 email address is required")
      .required("At least 1 email address is required"),
    userType: Yup.string().required("Select access level of user").nullable(),
    memberOfTeams: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
      .test(
        "is-required",
        "You need to select at least one team",
        function (values) {
          if (
            this.resolve(Yup.ref("userType")) === "Member" ||
            this.resolve(Yup.ref("userType")) === "Manager"
          ) {
            return values && values.length > 0;
          }
          return true;
        }
      ),
    managingTeams: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
      .test(
        "is-required",
        "You need to select at least one team",
        function (values) {
          if (
            this.resolve(Yup.ref("userType")) === "Manager" ||
            this.resolve(Yup.ref("userType")) === "Head"
          ) {
            return values && values.length > 0;
          }
          return true;
        }
      ),
    projects: Yup.array()
      .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
      .test(
        "is-required",
        "You need to select at least one project",
        function (values) {
          if (this.resolve(Yup.ref("userType")) === "Client") {
            return values && values.length > 0;
          }
          return true;
        }
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      emails: [],
      userType: "",
      memberOfTeams: [],
      managingTeams: [],
    },
  });
  const dispatch = useDispatch();
  const authState = useSelector((state) => ({ ...state.auth }));
  const teamState = useSelector((state) => ({ ...state.team }));
  const inviteTeamState = useSelector((state) => ({
    ...state.manageTeams.inviteTeam,
  }));
  const prevInviteTeamState = usePrevious(inviteTeamState);
  const { success, failure } = inviteTeamState;
  const [emails, setEmails] = useState([]);
  const userType = watch("userType");

  useEffect(() => {
    getProjectData();
    setValue("userType", "Member");
    register("emails");
  }, []);

  useEffect(() => {
    if (prevInviteTeamState !== inviteTeamState) {
      if (success == true || failure == true) {
        UnfreezeUI();
        reset({
          emails: [],
          managingTeams: [],
          userType: "Member",
          memberOfTeams: [],
        });
        setEmails([]);
        register("emails");
      }
    }
  }, [inviteTeamState]);

  function getTeamOptions() {
    let userTeams = teamState.teams.data;
    let finalizeTms = [];
    if (userTeams) {
      if (userTeams.length > 0) {
        userTeams.map((item, index) => {
          // if (["Manager", "Admin", "Head"].includes(item.Role)) {
          finalizeTms.push({ label: item.Team, value: item.activeTeamid });
          // }
        });
      }
    }
    return [...finalizeTms];
  }

  function getProjectOptions() {
    let userProjects = teamState.projects;
    let finalizeTms = [];
    if (userProjects) {
      if (userProjects.length > 0) {
        userProjects.map((item, index) => {
          // if (["Manager", "Admin", "Head"].includes(item.Role)) {
          if (item?.deleted !== true) {
            finalizeTms.push({ label: item.data.project_name, value: item.id });
          }
          // }
        });
      }
    }
    return [...finalizeTms];
  }

  function getProjectData() {
    dispatch({
      type: "team/GET_PROJECT",
      payload: {
        companyId: authState.profile?.activeCompany?.companyId,
      },
    });
  }

  function handleTeamInviteSubmit(data) {
    if (data.userType === "Admin") {
      data["managingTeams"] = getTeamOptions();
    }
    FreezeUI();
    try {
      dispatch({
        type: "manage-teams/SEND_INVITE",
        payload: {
          ...data,
          sender: {
            name: authState.profile?.name,
            role: authState.profile?.role,
            userId: authState.profile?.id,
            email: authState.profile?.email,
            companyDetails: { ...authState.profile?.activeCompany },
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const isValidEmail = (email) => {
    const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    return emailRegex.test(email);
  };

  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (value) => {
    const newInputValue = typeof value === 'string' ? value : value.target.value;
    setInputValue(newInputValue);
  };

  const handleBlur = (e) => {
    e.preventDefault();

    if (!document.querySelector('#teamInviteForm').parentElement.contains(e.relatedTarget)) 
      return;
    
    const newEmail = inputValue.trim();
    if ( newEmail !== '') {
      if (isValidEmail(newEmail)) {
        const newEmails = [...emails, newEmail];
        setEmails(newEmails);
        setValue("emails", newEmails);
        setInputValue('');
      } else {
        e.target.focus();
        addNotification({
          message: `Invalid email format! Please enter a valid email address.!`,
          level: notificationStates.error,
        })
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue !== '') {
        if (isValidEmail(trimmedValue)) {
          const newEmails = [...emails, trimmedValue];
          setEmails(newEmails);
          setValue("emails", newEmails);
          setInputValue('');
        } else {
          addNotification({
            message: `Invalid email format! Please enter a valid email address.!`,
            level: notificationStates.error,
          })
        }
      }
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleTeamInviteSubmit)}>
        <div className="pl-lg-1">
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Email address(es)
                </label>
                <TagsInput
                  className="form-control-alternative form-control react-tagsinput-input"
                  inputProps={{
                    placeholder: "Type email addresses here",
                    onBlur: handleBlur,
                    onChange: handleInputChange,
                    onKeyDown: handleKeyDown,
                    value: inputValue
                  }}
                  onlyUnique={true}
                  onValidationReject={(e) => {
                    console.log(e);
                  }}
                  addKeys={[32, 9, 13]}
                  value={emails}
                  ref={register}
                  onChange={(values) => {
                    setEmails(values);
                    setValue("emails", values);
                    clearErrors("emails");
                  }}
                  validationRegex={
                    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
                  }
                />
                {errorRenderer({ ...errors }, "emails")}
              </FormGroup>
            </Col>
          </Row>
        </div>

        {/*  ACCESS LEVEL */}
        <h6 className="heading-small text-muted mb-1">Access Level</h6>
        <div className="pl-lg-4">
          <Row>
            <Col md="12">
              <FormGroup className="my-1" check>
                <Input
                  name="userType"
                  value="Member"
                  innerRef={register}
                  id="user"
                  type="radio"
                />{" "}
                <Label for="user" check>
                  Team Member
                </Label>
              </FormGroup>
              <FormGroup className="my-1" check>
                <Input
                  name="userType"
                  innerRef={register}
                  type="radio"
                  id="manager"
                  value="Manager"
                />{" "}
                <Label for="manager" check>
                  Manager
                </Label>
              </FormGroup>
              {/* <FormGroup className="my-1" check>
                <Input
                  name="userType"
                  innerRef={register}
                  type="radio"
                  id="head"
                  value="Head"
                />{" "}
                <Label for="head" check>
                  Head
                </Label>
              </FormGroup> */}
              <FormGroup className="my-1" check>
                <Input
                  name="userType"
                  innerRef={register}
                  type="radio"
                  id="admin"
                  value="Admin"
                />{" "}
                <Label for="admin" check>
                  Admin
                </Label>
              </FormGroup>
              {/* <FormGroup className="my-1" check>
                <Input
                  name="userType"
                  innerRef={register}
                  type="radio"
                  value="Client"
                  id="client"
                />{" "}
                <Label for="client" check>
                  Client
                </Label>
              </FormGroup> */}
              {errorRenderer(errors, "userType")}
            </Col>
          </Row>
        </div>

        {["Manager", "Head"].includes(userType) && (
          <>
            <h6 className="heading-small text-muted my-2">Teams they manage</h6>
            <div className="pl-lg-4">
              <Row>
                <Col md="6">
                  <Controller
                    name={"managingTeams"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          styles={
                            errors.managingTeams ? reactSelectErrorStyles : {}
                          }
                          className="react-select"
                          classNamePrefix="select"
                          placeholder="Select teams"
                          isMulti
                          onChange={(selectedItem) => {
                            setValue("managingTeams", selectedItem);
                            clearErrors("managingTeams");
                          }}
                          options={getTeamOptions()}
                        />
                      );
                    }}
                  />
                  {errorRenderer({ ...errors }, "managingTeams")}
                </Col>
              </Row>
            </div>
          </>
        )}
        {/* we will use this in future */}
        {!["Admin", "Head", "Client"].includes(userType) && (
          <>
            <h6 className="heading-small text-muted my-2">
              Teams they're members of
            </h6>
            <div className="pl-lg-4 mb-2">
              <Row>
                <Col md="6">
                  <Controller
                    name={"memberOfTeams"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          styles={
                            errors.memberOfTeams ? reactSelectErrorStyles : {}
                          }
                          className="react-select"
                          classNamePrefix="select"
                          placeholder="Select teams"
                          isMulti
                          value={getValues("memberOfTeams") ?? []}
                          onChange={(selectedItem) => {
                            setValue("memberOfTeams", selectedItem);
                            clearErrors("memberOfTeams");
                          }}
                          options={getTeamOptions()}
                        />
                      );
                    }}
                  />
                  {errorRenderer({ ...errors }, "memberOfTeams")}
                </Col>
              </Row>
            </div>
          </>
        )}

        {["Client"].includes(userType) && (
          <>
            <h6 className="heading-small text-muted my-2">Projects</h6>
            <div className="pl-lg-4 mb-2">
              <Row>
                <Col md="6">
                  <Controller
                    name={"projects"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          styles={errors.projects ? reactSelectErrorStyles : {}}
                          className="react-select"
                          classNamePrefix="select"
                          placeholder="Select project"
                          isMulti
                          value={getValues("projects") ?? []}
                          onChange={(selectedItem) => {
                            setValue("projects", selectedItem);
                            clearErrors("projects");
                          }}
                          options={getProjectOptions()}
                        />
                      );
                    }}
                  />
                  {errorRenderer({ ...errors }, "projects")}
                </Col>
              </Row>
            </div>
          </>
        )}

        <div className="pl-lg-1 mt-5">
          <Button color="primary" size="md" type="submit">
            Send Invite
          </Button>
        </div>
      </Form>
    </>
  );
}
