import React from "react";
import ReactDOM from "react-dom";
import Root from "./root/Root";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import 'react-block-ui/style.css';
// import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "./assets/css/prime-react/lara-light-indigo.css"; //theme
// import "./assets/css/prime-react/speeddial.css"; //speeddial
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css';
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(
  <Root store={configureStore} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
