import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { errorRenderer } from "../../../utils/utility";
import { classNames } from "primereact/utils";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import teamService from "../../../services/manageTeams";

import {
  addNotification,
  notificationStates,
} from "../../../components/Notification";

export default function DeleteTeamDialog({
  toggleDeleteTeamDialog,
  deleteTeamDialog,
  teamDetails,
}) {
  /**
   * Declarations
   */
  const dispatch = useDispatch();
  const authState = useSelector((state) => ({ ...state.auth }));
  const teamState = useSelector((state) => ({ ...state.team }));
  let userProfile = authState?.profile;
  let [requesting, setRequesting] = useState(false);

  const handleDeleteTeam = async () => {
    try {
      setRequesting(true);
      let reqPayload = {
        selectedTeam: { ...teamDetails },
        ownerId: userProfile.id,
        companyId: userProfile?.activeCompany?.companyId,
      };
      let response = await teamService.deleteTeam({ ...reqPayload });
      if (response.success) {
        dispatch({
          type: "team/GET_USER_ACTIVE_TEAM",
          payload: {
            userId: userProfile.id,
            companyId: userProfile?.activeCompany?.companyId,
            role: userProfile?.role,
            activeTeamid: teamState?.active?.activeTeamid,
            resetTeamsData: true,
          },
        });
        setRequesting(false);
        toggleDeleteTeamDialog(false);
        addNotification({
          message: response.data,
          level: notificationStates.success,
        });
      } else {
        addNotification({
          message: response.data,
          level: notificationStates.error,
        });
      }
    } catch (e) {
      setRequesting(false);
    }
  };

  return (
    <Dialog
      blockScroll
      draggable={false}
      header="Delete team"
      contentClassName="rounded-bottom"
      visible={deleteTeamDialog}
      onHide={() => toggleDeleteTeamDialog(false)}
      style={{ width: "360px" }}
    >
      <div>
        <p>
          Team members will lose access to the Team’s assigned Workspaces,
          unless they are members of another team that is assigned to those
          workspaces or were assigned individually
        </p>
        <p>
          Are you sure you want to delete the group{" "}
          <b className="text-capitalize">{teamDetails.teamName}</b>?
        </p>
        <div className="w-100 row m-0">
          <div className="col-6 pl-0 ">
            <Button
              type="button"
              label="Cancel"
              onClick={() => toggleDeleteTeamDialog(false)}
              color="secondary"
              className="p-button-outlined p-button-secondary p-button-sm w-100"
            />
          </div>
          <div className="col-6 pr-0">
            <Button
              type="submit"
              loading={requesting}
              label="Delete"
              onClick={() => handleDeleteTeam()}
              color="danger"
              className="p-button-sm p-button-danger w-100"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
