import XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { saveAs } from "file-saver";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import teamDatabase from "../../services/team";

import "bootstrap-daterangepicker/daterangepicker.css";

const ExportDataModal = ({ user, name, isAttendance = false }) => {
  const activeUserId = user.activeUserId;
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => ({
    activeUserName: state.member.activeUserName,
  }));
  const activeTeamId  = useSelector((state) => ({
    activeTeamId: state.team.selectedTeamID,
  }));

  const attendance = useSelector((state) => ({
    loading: state.team.teamLoading,
    days: state.team.days,
    all: state.team.all,
  }));

  let isAttend = attendance?.days?.filter(
    (item) => item.punchData && !item.leave
  );

  let isTimelog = attendance?.days?.filter(item => item.timeLog !== undefined)

  const allProjects = useSelector((state) => ({
    projects: state.team.projects,
  }));
  const [activeTeamMembers, setActiveTeamMembers] = useState({});
  const [showButtons, setShowButtons] = useState(false);

  const fetchTeamData = async () => {
    if (activeTeamId.activeTeamId) {
      const response = await teamDatabase.getTeamData(activeTeamId.activeTeamId);
      setActiveTeamMembers(response?.data?.memberDetails || {});
    }
  };

  useEffect(() => {
    fetchTeamData();
  }, [activeTeamId.activeTeamId]);
  const handlePunchObject = ({ day, punchData }) => {
    const unOrderedData = [];
    for (let key in punchData) {
      if (punchData.hasOwnProperty(key)) {
        unOrderedData.push({
          timestamp: moment(key).unix(),
          time: moment(key).format("hh:mm:ss A"),
          type: punchData[key],
        });
      }
    }

    const orderedData = unOrderedData.sort((x, y) => {
      return x.timestamp - y.timestamp;
    });
    let momentObj = moment(day, "DD-MM-YYYY");
    const newDateString = momentObj.format("MM-DD-YYYY");
    const attendanceRow = [newDateString, "Present"];
    orderedData.map(({ time: x, type }, i) => {
      if (i === 0 && type === "punchout") {
        attendanceRow.splice(2, 0, "");
      }
      attendanceRow.push(x);
    });
    return attendanceRow;
  };

  const parseJsonToExcel = (rawData, mode) => {
    let data = rawData.filter((item) => item.punchData && !item.leave);
    let row = [];
    let ExcelFileName = "";
    if (mode === "attendance") {
      let maxPunchDataLength = 0;

      for (let i = 0; i < data.length; i++) {
        const pnchDtaKeys = Object.keys(data[i].punchData);
        const currentLength = pnchDtaKeys.length;
        if (currentLength > maxPunchDataLength) {
          maxPunchDataLength = currentLength;
        }
      }

      let innerArray = ["Date", "Status"];
      for (let i = 0; i < Math.ceil(maxPunchDataLength / 2); i++) {
        innerArray.push("Punch In", "Punch Out");
      }
      if (maxPunchDataLength % 2 !== 0) {
        innerArray.pop();
      }
      row.push(innerArray);
      data.forEach((x) => {
        row.push(handlePunchObject(x));
      });

      let dataToSort = row.slice(1);

      dataToSort.sort((a, b) => (a[0] > b[0] ? 1 : -1));

      row = [row[0]].concat(dataToSort);
      ExcelFileName = `${activeUser.activeUserName || name} Attendance Data`;
    } else {
      let tempTimelog = [];
      rawData.forEach((x) => {
        const temp = handleTimeLog(x);
        tempTimelog = [...tempTimelog, ...temp];
      });

      row.push([
        "Date",
        "Project Name",
        "Task Name",
        "Task Details",
        "Task Hours",
        "Task Minutes",
        "Idle Minutes",
        "Rates($)",
        "Total Earnings($)",
        "Screen Shot",
      ]);

      tempTimelog.flatMap((x) => row.push(x));
      ExcelFileName = `${activeUser.activeUserName || name} Timelog Data`;
    }

    exportTableToExcel(row, ExcelFileName);
  };

  const exportTableToExcel = (data, filename = "Data") => {
    const sheet = XLSX.utils.aoa_to_sheet(data);

    const wbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wbook, sheet, filename);

    const wbout = XLSX.write(wbook, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `${filename}.xlsx`
    );
  };

  const handleTimeLog = ({ day, timeLog }) => {
    const unOrderedData = [];
    const timeLogRow = [];
    for (let key in timeLog) {
      let projectName = allProjects.projects.find(
        (project) => timeLog[key].projectId === project.id
      ).data.project_name;
      let rates = 0;
      if (activeTeamMembers && activeTeamMembers[activeUserId]) {
        rates = activeTeamMembers[activeUserId]?.rates || 0;
      }
      if (timeLog.hasOwnProperty(key)) {
        unOrderedData.push({
          timestamp: moment(key).unix(),
          time: moment(key).format("hh:mm:ss A"),
          projectName,
          taskName: timeLog[key].taskName,
          taskDetails: timeLog[key].taskDetails,
          idleMinutes: timeLog[key].idleMinutes,
          taskMinutes: timeLog[key].taskMinutes,
          taskHours: timeLog[key].taskHours,
          rates,
          totalEarnings: ((timeLog[key].taskHours + timeLog[key].taskMinutes / 60) * rates).toFixed(2),
          screenShot: Object.values(timeLog[key].screenshots),
        });
      }
    }

    const orderedData = unOrderedData.sort((x, y) => {
      return x.timestamp - y.timestamp;
    });
    orderedData.map(
      (
        {
          projectName,
          taskName,
          taskDetails,
          taskHours,
          taskMinutes,
          idleMinutes,
          rates,
          totalEarnings,
          screenShot,
        },
        i
      ) => {
        timeLogRow.push([
          day,
          projectName,
          taskName,
          taskDetails,
          taskHours,
          taskMinutes,
          idleMinutes,
          rates,
          totalEarnings,
          ...screenShot,
        ]);
      }
    );
    return timeLogRow;
  };
  return (
    <div className="chart-sm">
      <Row>
        <Col md={12}>
          <DateRangePicker
            initialSettings={{
              locale: {
                applyLabel: "Get Data",
              },
            }}
            onApply={(_event, { startDate, endDate }) => {
              dispatch({
                type: "team/GET_USER_TIMELOG_DATA",
                payload: {
                  user,
                  startDate: startDate.format("DD-MM-YYYY"),
                  endDate: endDate.format("DD-MM-YYYY"),
                },
              });
              setShowButtons(true);
            }}
          >
            <input type="text" className="form-control form-control-sm" />
          </DateRangePicker>
        </Col>
      </Row>
      {showButtons && isTimelog && isTimelog?.length > 0 && (
        <div
          style={{
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {isAttend && isAttend.length > 0 && (
            <Button
              size="sm"
              onClick={() => parseJsonToExcel(attendance.days, "attendance")}
              color="info"
            >
              Download Attendance Data
            </Button>
          )}
          <br />
          {!isAttendance &&<Button
            size="sm"
            onClick={() => parseJsonToExcel(attendance.days, "timelog")}
            color="info"
          >
            Download Timelog Data
          </Button>}
        </div>
      )}
      {attendance.loading && (
        <div
          style={{
            display: "flex",
            padding: "10px",
            justifyContent: "center",
          }}
        >
          <Spinner color="info" />
        </div>
      )}
    </div>
  );
};

export default ExportDataModal;
