const appConfig = {
    // firebaseCallbackURL: "http://localhost:3001/dashboard-team",
    // firebaseCallbackURL: "https://workfh-meeting.web.app",
    firebaseCallbackURL: "https://app.wfhgo.com",

    roles: [
        { value: 'Member', label: 'Member' },
        { value: 'Manager', label: 'Manager' },
        { value: 'Head', label: 'Head' },
        { value: 'Admin', label: 'Admin' },
        { value: 'Client', label: 'Client' },
    ]
}
export default appConfig;