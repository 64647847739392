import moment from "moment";
import actions from "../actions/Team";

const loadTeam = localStorage.getItem("teamData");
const loadAllTeam = localStorage.getItem("Team");
const members = localStorage.getItem("members");
const topUserProjects = localStorage.getItem("topProjects");

const initialState = {
  loading: false,
  loadingProject: true,
  teamLoading: false,
  selectedTeamID: null,
  activeUserID: null,
  selectedDate: moment().format("DD:M:YYYY"),
  week: moment().isoWeek().toString(),
  timelogDayRange: 0,
  start: "",
  end: "",
  activeTeam: {},
  teamMembers: [],
  teams: loadAllTeam?.length === 0 ? [] : JSON.parse(loadAllTeam),
  teamLogs: [],
  days: [],
  projects: [],
  selectedProject: {},
  tasks: [],
  all: true,
  teamData: {},
  weekData: {},
  topData: {},
  membersData: {},
  threshold: "",
  attendanceData: [],
  active: loadTeam?.length === 0 ? [] : JSON.parse(loadTeam),
  teamTimeData: {},
  topProjects: [],
  selectedProject: "",
  selectTeams: [],
  sevenDayData: {},
  thirtyDayData: {},
  untrackedUsers: {},
  days: 0,
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
