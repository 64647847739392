import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import moment from "moment";
import map from "lodash/map";
import size from "lodash/size";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Header from "../../components/Header";
import Screenshots from "./screenshots";
import Modal from "../../components/Modal";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import '../../assets/css/Calendar.module.css'
import "../../assets/css/custom.css";
import "../../assets/css/Calendar.css";

import { useHistory } from "react-router-dom";
import { FreezeUI, UnfreezeUI } from "../../assets/plugins/nucleo/js/freeze-ui";
import { useQuery } from "react-query";
import teamService from "../../services/auth";
import { useLocation } from "react-router";
import queryString from "query-string";
import DashboardIndividual from "./individualDashboard";
import { useSelector } from "react-redux";

function timeConvert(mins) {
  var hours = parseInt(mins / 60);
  var minutes = mins % 60;
  return `${hours} hours ${minutes} minutes`;
}
function idleTimeConvert(mins) {
  var hours = parseInt(mins / 60);
  var minutes = mins % 60;
  return `${hours} Hr ${minutes} Min`;
}

const Dashboard = ({
  loading,
  activeUserID,
  teamLoading,
  selectedTeamID,
  userProfile,
  teamLogs,
  dispatch,
  all,
  timelogDayRange,
  start,
  end,
  active,
  projects,
}) => {
  const selectedDateRef = useRef(moment().format("DD/MM/YYYY"));
  const [selectedImages, setSelectedImage] = useState([]);
  const [punchinout, setPunchinout] = useState({});
  const [loggedTime, setLoggedTime] = useState({});
  const [checkintime, setCheckintime] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [open, setOpen] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [isImageBox, setImageBox] = useState(false);
  const [eventModalTitle, setModalTitle] = useState("");

  const location = useLocation();
  const { i: invitationId, select, cid } = queryString.parse(location.search);
  const invitationData = JSON.parse(localStorage.getItem("pendingInvitation"));
  const history = useHistory();

  const {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    isIdle,
    isStale,
    isFetched,
    error,
    data: userResponse,
  } = useQuery(
    ["getCurrentUserDetails", userProfile?.id],
    async () => {
      let { success, data } = await teamService.getCurrentUserDetails(
        userProfile?.id
      );
      if (success == true) return { success, data };
      else throw "Unable to found data! Please try again later!";
    },
    { enabled: select && select == "0" ? false : !!userProfile?.id }
  );

  if (isFetching) {
    FreezeUI();
  }
  if (isSuccess || isError) {
    UnfreezeUI();
  }

  const getEventStartTime = (event, date) => {
    let minutes = event.taskMinutes;
    let hoursInMinutes = event.taskHours * 60;
    let timeInMinutes = minutes + hoursInMinutes;

    let eventStartMoment = moment.utc(date).subtract(timeInMinutes, "minutes");

    return eventStartMoment;
  };

  useEffect(() => {
    console.log("in component of dashboard");
    if (!isEmpty(active)) {
      let date = 5;
      let newDate = [];
      for (let n = 0; n <= date; n++) {
        newDate.push(moment().subtract(n, "days").format("D:M:YYYY"));
      }
      dispatch({
        type: "team/GET_TIME_DATA",
        payload: {
          selectedTeamID: active?.activeTeamid,
          days: newDate,
        },
      });
      if (all) {
        dispatch({
          type: "team/GET_ALL_TEAM_TIMELOG",
          payload: {
            days: timelogDayRange,
            start,
            end,
            teamId: active?.activeTeamid,
          },
        });
      } else {
        dispatch({
          type: "team/GET_MANAGER_TEAM_COLLECTION",
          payload: {
            days: timelogDayRange,
            start,
            end,
            activeTeamId: active?.activeTeamid,
            activeUserId: activeUserID,
          },
        });
      }
    }
  }, [activeUserID, all]);

  const handleCloseEventModal = () => {
    setEventModalOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
    setPunchinout({});
    setSelectedType("");
    setSelectedImage([]);
  };
  const handleImageClose = () => {
    setSelectedImage([]);
    setImageBox(false);
  };
  const getHoursPerDay = (date) => {
    let logs = teamLogs.selected || [];

    let arrayEvents = [];
    for (const day of logs) {
      let dateString = day.date.split(":").join("-");

      let minutes = 0;
      if (day.timelog) {
        const entries = Object.entries(day.timelog);
        for (const [date, data] of entries) {
          minutes += data.taskHours * 60;
          minutes += data.taskMinutes;
        }
        arrayEvents.push([moment(dateString).toString(), minutes]);
      }
    }
    return arrayEvents;
  };
  const time_convert = (num) => {
    let hours = Math.floor(num / 60);
    let minutes = num % 60;
    return hours + " hrs " + minutes + " mins";
  };
  let arrayTimeOfDay = getHoursPerDay();

  const customHeaderHours = (date) => {
    for (const evento of arrayTimeOfDay) {
      if (moment(evento[0]).isSame(date, "day")) {
        return time_convert(evento[1]);
      }
    }
  };

  const renderCheckingTime = () => {
    return (
      <>
        <thead className="thead-light">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {map(checkintime, (value, index) => {
            return (
              <tr key={index}>
                <td>{moment(new Date(index)).format("DD/MM/YYYY")}</td>
                <td>{moment(new Date(index)).format("hh:mm:ss A")}</td>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };
  const renderPunchinout = () => {
    return (
      <>
        <thead className="thead-light">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {map(punchinout, (value, index) => {
            return (
              <tr key={index}>
                <td>{moment(new Date(index)).format("DD/MM/YYYY")}</td>
                <td>{moment(new Date(index)).format("hh:mm A")}</td>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  };

  const handleSelect = (images) => {
    if (isEmpty(images)) return null;
    setSelectedImage(Object.values(images));
    setImageBox(true);
  };
  const renderLoggedTime = () => {
    const timeInminutes =
      Number(loggedTime.taskHours) * 60 + Number(loggedTime.taskMinutes);
    const startTime = moment(new Date(selectedDateRef.current))
      .subtract(timeInminutes, "minutes")
      .format("hh:mm A");
    const endTime = moment(new Date(selectedDateRef.current)).format("hh:mm A");
    let idletime = loggedTime.idleMinutes;
    let effectiveIdleHours = idletime / 60;
    let effectiveIdleMinutes = Math.round(
      (effectiveIdleHours - Math.floor(effectiveIdleHours)) * 60
    );

    let totalminutes = loggedTime.taskHours * 60 + loggedTime.taskMinutes;
    let effectiveTotalMinutes = totalminutes - idletime;
    let effectiveHours = effectiveTotalMinutes / 60;
    let effectiveminutes = Math.round(
      (effectiveHours - Math.floor(effectiveHours)) * 60
    );
    return (
      <>
        <tbody
          style={{ fontSize: "16px", color: "#3c4043", padding: "6px 0px" }}
        >
          <tr style={{ fontSize: "22px", fontWeight: 400 }}>
            <span style={{ color: "#039be5", fontSize: "17px" }}>
              <ion-icon name="square"></ion-icon>
            </span>{" "}
            &nbsp;&nbsp;
            {loggedTime.taskName.charAt(0).toUpperCase() +
              loggedTime.taskName.slice(1)}
          </tr>
          <tr className="ml-5">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {`${moment(selectedDateRef.current).format(
              "dddd, MMMM DD"
            )} . ${startTime} - ${endTime}`}
          </tr>
          <br />
          <tr style={{ padding: "6px 0px" }}>
            <ion-icon name="cafe-outline"></ion-icon>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Active Time :{" "}
            {Math.floor(effectiveHours) > 0
              ? `${Math.floor(effectiveHours)} hrs ${effectiveminutes} mins`
              : ` ${effectiveminutes} mins`}
          </tr>
          <tr style={{ padding: "6px 0px" }}>
            <ion-icon name="hourglass-outline"></ion-icon>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Idle Time :{" "}
            {Math.floor(effectiveIdleHours) > 0
              ? `${Math.floor(
                  effectiveIdleHours
                )} hrs ${effectiveIdleMinutes} mins`
              : `${effectiveIdleMinutes} mins`}
          </tr>
          <tr style={{ padding: "6px 0px" }}>
            <ion-icon name="scan-outline"></ion-icon>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Screen shots :{" "}
            <a
              href="!#"
              onClick={(e) => {
                e.preventDefault();
                handleSelect(loggedTime.screenshots);
              }}
            >
              {size(loggedTime.screenshots)}
            </a>
          </tr>
          <tr></tr>
        </tbody>
      </>
    );
  };

  useEffect(() => {
    if (cid && isFetched && isSuccess && userResponse) {
      localStorage.setItem("cid", cid);
      let uData = userResponse?.data;
      let selectedCompany = uData.companyList.filter(
        (i) => i.companyId === cid
      )[0];
      dispatch({
        type: "user/SET_STATE",
        payload: {
          profile: {
            ...userProfile,
            role: selectedCompany
              ? selectedCompany.role
              : uData.companyList[0].role,
            companyList: !isEmpty(uData) ? uData?.companyList ?? [] : [],
            activeCompany: selectedCompany,
          },
        },
      });
    }
  }, [isFetched, isSuccess, userResponse, cid]);

  if ((!select || select == "1") && !isFetching) {
    if (invitationData) {
      // Checks If user comes via invitation URL, then will be redirected to Invitation Page
      history.push(`/invitation?i=${invitationData?.invitationId}&ref=signup`);
    } else {
      // Check number of companies that user involves
      if (isSuccess && userResponse.success) {
        if (!isEmpty(userResponse.data)) {
          // If it has multiple companies, will be redirected to selection page
          if (!cid) {
            if (userResponse.data?.companyList.length > 1) {
              history.push("/select-company");
            }
            if (userResponse.data?.companyList.length <= 0)
              history.push("/add-company");
          }
        } else {
          // If it doesn't have any company, will be redirected to Add Company
          history.push("/add-company");
        }
      }
    }
  }

  return (
    <>
      {history.location.pathname === "/dashboard-individual" ? (
        <DashboardIndividual />
      ) : (
        <Header />
      )}

      <Modal
        style={{ maxWidth: "500px" }}
        size={"sm"}
        isOpen={eventModalOpen}
        onClose={handleCloseEventModal}
        title={eventModalTitle}
      >
        <div style={{ fontFamily: "Helvetica" }}>
          <div
            className="table-responsive"
            style={{ padding: "0px 20px 40px 40px" }}
          >
            <div className="align-items-center table-flush">
              {selectedType === "timelog" && renderLoggedTime()}
              {selectedType === "punchinout" && renderPunchinout()}
              {selectedType === "checkintime" && renderCheckingTime()}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ width: "20px" }}
        size={"md"}
        isOpen={open}
        onClose={handleClose}
        title=""
      >
        <div
          className="table-responsive"
          style={{ padding: "0px 20px 40px 40px" }}
        >
          <div className="align-items-center table-flush">
            {selectedType === "timelog" && renderLoggedTime()}
            {selectedType === "punchinout" && renderPunchinout()}
            {selectedType === "checkintime" && renderCheckingTime()}
          </div>
        </div>
      </Modal>
      <Screenshots
        isOpen={isImageBox}
        onClose={handleImageClose}
        images={selectedImages}
      />
    </>
  );
};

Dashboard.propTypes = {
  loading: PropTypes.bool,
  teamLoading: PropTypes.bool,
  profileID: PropTypes.string,
  selectedTeamID: PropTypes.string,
  selectedDate: PropTypes.string,
  teams: PropTypes.instanceOf(Array),
  teamMembers: PropTypes.instanceOf(Array),
  teamLogs: PropTypes.instanceOf(Array),
  dispatch: PropTypes.func,
};

Dashboard.defaultProps = {
  loading: false,
  teamLoading: false,
  profileID: "",
  selectedTeamID: null,
  teamMembers: [],
  teamLogs: [],
  teams: [],
  selectedDate: moment().format("DD:M:YYYY"),
  dispatch: () => {},
};
export default Dashboard;
