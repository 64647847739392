/* eslint-disable no-sequences */
import { all, takeLatest, put, call } from "redux-saga/effects";
import orderBy from "lodash/orderBy";
import teamDatabase from "../services/team";
import actions from "../actions/Team";
import {
  addNotification,
  notificationStates,
} from "../components/Notification";
import filter from "lodash/filter";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import moment from "moment";
import { lte } from "lodash";
import { RiWindowsFill } from "react-icons/ri";
import _ from "lodash";

export function* SET_SELECTED_MEMBER_ID({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      activeUserID: payload,
      all: false,
    },
  });
}

export function* SET_THRESHOLD({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      companyId: payload,
      threshold: payload,
      loading: true,
    },
  });

  const threshold = yield call(
    teamDatabase.setThreshold,
    payload.companyId,
    payload.threshold
  );

  if (threshold.success) {
    yield put({
      type: "team/SET_STATE",
      payload: {
        loading: false,
      },
    });
  }
}
export function* GET_THRESHOLD({ payload }) {
  console.log(payload, "payload in GET_THRESHOLD");
  yield put({
    type: "team/SET_STATE",
    payload: {
      companyId: payload,
    },
  });

  const threshold = yield call(teamDatabase.getThreshold, payload.companyId);

  if (threshold.success) {
    yield put({
      type: "team/SET_STATE",
      payload: {
        threshold: threshold?.data,
      },
    });
  }
}

export function* GET_TIME_DATA({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      days: payload?.days,
      loading: true,
    },
  });

  let time7Days = [];

  for (var i = 0; i < payload.days.length; i++) {
    time7Days.push(
      yield call(
        teamDatabase.getTimes,
        payload?.selectedTeamID,
        payload.days[i]
      )
    );
  }

  yield put({
    type: "team/SET_STATE",
    payload: {
      attendanceData: time7Days,
      loading: false,
    },
  });
}

export function* SET_SELECT_DATE({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      selectedDate: payload,
    },
  });
}

export function* SET_SELECT_TEAM({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      active: payload.activeTeam,
    },
  });

  // const activeTeam = yield call(
  //   teamDatabase.setActiveTeamId,
  //   payload.selectedTeamID,
  //   payload.activeUserID
  // );

  const selectedTeam = yield call(
    teamDatabase.getTeamData,
    payload.selectedTeamID
  );

  const teamMembers = yield call(
    teamDatabase.getTeamMembers,
    payload?.selectedTeamID
  );

  const getMember = yield call(
    teamDatabase.getActiveTeamID,
    payload?.activeUserID,
    payload?.selectedTeamID
  );

  const getActiveTeam = yield call(
    teamDatabase.getActiveTeam,
    payload?.activeUserID,
    payload?.selectedTeamID,
    payload?.companyId
  );

  localStorage.setItem("members", JSON.stringify(teamMembers?.data));
  if (selectedTeam.success) {
    yield put({
      type: "team/SET_STATE",
      payload: {
        selectedTeamID: payload?.selectedTeamID,
        teamMembers: teamMembers?.data,
        activeTeam: {
          ...getActiveTeam?.data?.Team,
          ...getMember?.data,
          ...teamMembers?.data,
        },
      },
    });
  }
  if (window.location.toString().includes("/team-attendance")) {
    window.location.reload();
  }
}

export function* SELECT_TEAM_DATA({ payload }) {
  yield put({
    type: actions.SET_TEAM_DATA,
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      active: payload.active,
    },
  });
}

export function* SET_TRENDS_DATA({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      week: payload?.week,
      days: payload?.days,
    },
  });

  // let week = moment().isoWeek();

  const weekData = yield call(
    teamDatabase.getWeekData,
    payload?.selectedTeamID,
    payload?.week
  );

  yield put({
    type: "team/SET_STATE",
    payload: {
      weekData: weekData.data,
    },
  });
}

export function* SET_TOP_USERS({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      days: payload?.day,
    },
  });

  const teamMembersDetails = yield call(
    teamDatabase.getTeamMembers,
    payload?.selectedTeamID
  );

  if (payload?.day === 0 || payload?.day === 1) {
    const date = moment().subtract(payload?.day, "days").format("D:M:YYYY");
    let users = {};

    const topData = yield call(
      teamDatabase.getTopData,
      payload?.selectedTeamID,
      date
    );

    if (topData.success) {
      const n = Object.keys(topData.data);
      n.forEach((user) => {
        // eslint-disable-next-line array-callback-return
        teamMembersDetails.data.some((arrVal) => {
          if (arrVal.userUID === user) {
            if (topData.data[user].totalMinutes > 59) {
              topData.data[user].totalMinutes = 0;
              topData.data[user].totalHours += 1;
            }
            if (topData.data[user].idleMinutes > 59) {
              topData.data[user].idleMinutes = 0;
              topData.data[user].idleHours += 1;
            }
            topData.data[user].productiveHours =
              topData.data[user].totalHours - topData.data[user].idleHours;
            topData.data[user].productiveMinutes =
              topData.data[user].totalMinutes - topData.data[user].idleMinutes;
            if (topData.data[user].productiveMinutes < 0) {
              topData.data[user].productiveMinutes =
                60 + topData.data[user].productiveMinutes;
              topData.data[user].productiveHours -= 1;
            }
            users = {
              ...users,
              [user]: {
                userName: arrVal.userName,
                timelogs: topData.data[user].timelogs,
                totalHours: topData.data[user].totalHours,
                totalMinutes: topData.data[user].totalMinutes,
                idleHours: topData.data[user].idleHours,
                idleMinutes: topData.data[user].idleMinutes,
                productiveHours: topData.data[user].productiveHours,
                productiveMinutes: topData.data[user].productiveMinutes,
              },
            };
          }
        });
      });
      yield put({
        type: "team/SET_STATE",
        payload: {
          topData: users,
          membersData: teamMembersDetails.data,
        },
      });
    } else {
      yield put({
        type: "team/SET_STATE",
        payload: {
          topData: {},
        },
      });
    }
  }

  if (payload?.day === 7 || payload?.day === 30) {
    let users = {};
    let newArray = [];
    let time7Days = [];
    let fData = [];

    let d = payload?.day;
    let n = [];

    for (d; d >= 1; --d) {
      let date = moment().subtract(d, "days").format("D:M:YYYY");
      console.log("Date : ", date);
      time7Days.push(
        yield call(teamDatabase.getTopData, payload?.selectedTeamID, date)
      );
    }
    console.log(time7Days);
    time7Days.forEach((day) => {
      if (day.success) {
        fData.push(day.data);
        n = Object.keys(day.data);
        newArray = [...newArray, ...n];
      }
    });
    let arr = newArray.concat();
    for (var i = 0; i < arr.length; ++i) {
      for (var j = i + 1; j < arr.length; ++j) {
        if (arr[i] === arr[j]) {
          arr.splice(j, 1);
        }
      }
    }
    let timelogs = {};
    fData.forEach((user) => {
      _.each(_.keys(user), function (key) {
        if (key in timelogs) {
          if (key === "pBIQcmwRN2VVOTlZu08U9Lz6A602") {
            console.log("User : ", {
              Thours: timelogs[key].totalHours,
              Tminutes: timelogs[key].totalMinutes,
              Uhours: user[key].totalHours,
              Uminutes: user[key].totalMinutes,
            });
          }
          if (user[key].totalMinutes > 59) {
            user[key].totalMinutes = user[key].totalMinutes % 60;
            user[key].totalHours += 1;
          }
          if (user[key].idleMinutes > 59) {
            user[key].idleMinutes = user[key].idleMinutes % 60;
            user[key].idleHours += 1;
          }
          timelogs[key].totalHours += user[key].totalHours;
          timelogs[key].totalMinutes += user[key].totalMinutes;
          timelogs[key].idleHours += user[key].idleHours;
          timelogs[key].idleMinutes += user[key].idleMinutes;
          if (timelogs[key].totalMinutes > 59) {
            timelogs[key].totalMinutes = timelogs[key].totalMinutes % 60;
            timelogs[key].totalHours += 1;
          }
          if (timelogs[key].idleMinutes > 59) {
            timelogs[key].idleMinutes = timelogs[key].idleMinutes % 60;
            timelogs[key].idleHours += 1;
          }
          timelogs[key].productiveHours =
            timelogs[key].totalHours - timelogs[key].idleHours;
          timelogs[key].productiveMinutes =
            timelogs[key].totalMinutes - timelogs[key].idleMinutes;
          if (timelogs[key].productiveMinutes < 0) {
            timelogs[key].productiveMinutes =
              60 + timelogs[key].productiveMinutes;
            timelogs[key].productiveHours -= 1;
          }
        } else {
          if (user[key].totalMinutes > 59) {
            user[key].totalMinutes = user[key].totalMinutes % 60;
            user[key].totalHours += Math.floor(user[key].totalMinutes / 60);
          }
          if (user[key].idleMinutes > 59) {
            user[key].idleMinutes = user[key].idleMinutes % 60;
            user[key].idleHours += Math.floor(user[key].idleMinutes / 60);
          }
          user[key].productiveHours =
            user[key].totalHours - user[key].idleHours;
          user[key].productiveMinutes =
            user[key].totalMinutes - user[key].idleMinutes;
          if (user[key].productiveMinutes < 0) {
            user[key].productiveMinutes = 60 + user[key].productiveMinutes;
            user[key].productiveHours -= 1;
          }
          timelogs[key] = {
            totalHours: user[key].totalHours,
            totalMinutes: user[key].totalMinutes,
            idleHours: user[key].idleHours,
            idleMinutes: user[key].idleMinutes,
            productiveHours: user[key].productiveHours,
            productiveMinutes: user[key].productiveMinutes,
          };
        }
        // console.log("Key: ", key);
        // console.log("U[K]: ", user[key]);
        // console.log("Time : ", timelogs);
      });
    });

    // eslint-disable-next-line array-callback-return
    teamMembersDetails.data.some((arrVal) => {
      if (arrVal?.userUID in timelogs) {
        timelogs[arrVal?.userUID].userName = arrVal?.userName;
      }
    });

    yield put({
      type: "team/SET_STATE",
      payload: {
        topData: timelogs,
        membersData: teamMembersDetails.data,
      },
    });
  }
}

export function* SET_TODAY_TIMES({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      days: payload?.day,
    },
  });
  let time7Days = [];

  let totalMinutesToHours = 0;
  let newTotalMinutes = 0;

  let idleMinutesToHours = 0;
  let newIdleMinutes = 0;

  let newProductiveMinutes = 0;
  let productiveMinutesToHours = 0;

  let timeData = {
    idleHours: 0,
    idleMinutes: 0,
    totalHours: 0,
    totalMinutes: 0,
    productiveMinutes: 0,
    productiveHours: 0,
  };

  if (payload?.day === 7 || payload?.day === 30) {
    let d = payload?.day;
    for (d; d >= 1; --d) {
      const date = moment().subtract(d, "days").format("D:M:YYYY");
      time7Days.push(
        yield call(teamDatabase.getTimes, payload?.selectedTeamID, date)
      );
    }

    time7Days.forEach((element) => {
      if (element.success) {
        timeData.idleHours += element?.data?.idleHours;
        timeData.totalHours += element?.data?.totalHours;
        timeData.idleMinutes += element?.data?.idleMinutes;
        timeData.totalMinutes += element?.data?.totalMinutes;
        timeData.productiveMinutes += element?.data?.productiveMinutes;
        timeData.productiveHours += element?.data?.productiveHours;
      }
    });

    totalMinutesToHours = timeData.totalMinutes / 60;

    timeData.totalHours += totalMinutesToHours;

    newTotalMinutes = (timeData.totalHours % 1) * 60;
    timeData.totalMinutes = Math.trunc(newTotalMinutes);
    timeData.totalHours = Math.trunc(timeData.totalHours);

    // --------------------------- //

    idleMinutesToHours = timeData.idleMinutes / 60;

    timeData.idleHours += idleMinutesToHours;

    newIdleMinutes = (timeData.idleHours % 1) * 60;
    timeData.idleMinutes = Math.trunc(newIdleMinutes);
    timeData.idleHours = Math.trunc(timeData.idleHours);

    // --------------------------- //

    productiveMinutesToHours = timeData.productiveMinutes / 60;

    timeData.productiveHours += productiveMinutesToHours;

    newProductiveMinutes = (timeData.productiveHours % 1) * 60;
    timeData.productiveMinutes = Math.trunc(newProductiveMinutes);
    timeData.productiveHours = Math.trunc(timeData.productiveHours);

    yield put({
      type: "team/SET_STATE",
      payload: {
        timeData: timeData,
      },
    });
  }

  const date = moment().subtract(payload?.day, "days").format("D:M:YYYY");
  if (payload?.day === 0 || payload?.day === 1) {
    const timeData = yield call(
      teamDatabase.getTimes,
      payload?.selectedTeamID,
      date
    );
    if (timeData.success) {
      yield put({
        type: "team/SET_STATE",
        payload: {
          timeData: timeData.data,
        },
      });
    } else {
      yield put({
        type: "team/SET_STATE",
        payload: {
          timeData: {
            idleHours: 0,
            idleMinutes: 0,
            totalHours: 0,
            totalMinutes: 0,
            productiveMinutes: 0,
            productiveHours: 0,
          },
        },
      });
    }
  }
}

export function* SET_TIMELOG_DAY_RANGE({ payload }) {
  console.log("payload in SET_TIMELOG_DAY_RANGE", payload);
  yield put({
    type: "team/SET_STATE",
    payload: {
      timelogDayRange: payload.days,
      ...payload,
    },
  });
}

export function* GET_DATE_RANGE_DATA({ payload }) {
  console.log("payload in GET_DATE_RANGE_DATA", payload);
  yield put({
    type: "team/SET_STATE",
    payload: {
      timelogDayRange: payload.days,
      teamLoading: true,
      teamTimeData: {},
    },
  });

  let dateRangeData;
  dateRangeData = yield call(
    teamDatabase.getDateRangeTimeData,
    moment(payload?.start, "DD-MM-YYYY").format("YYYY-MM-DD"),
    moment(payload?.end, "DD-MM-YYYY").format("YYYY-MM-DD"),
    payload?.id
  );
  console.log("dateRangeData : ", dateRangeData?.data);

  yield put({
    type: "team/SET_STATE",
    payload: {
      teamLoading: false,
      all: true,
      teamTimeData: dateRangeData?.data,
      topProjects: dateRangeData?.data ? dateRangeData?.data?.topProjects : [],
      topData: dateRangeData?.data ? dateRangeData?.data?.topData : {},
      untrackedUsers: dateRangeData?.data
        ? dateRangeData?.data?.untrackedUsers
        : {},
    },
  });
}

export function* GET_ALL_TEAMS_DATA({ payload }) {
  let data = yield call(
    teamDatabase.getTeamNames,
    payload?.userId,
    payload?.companyId
  );
  localStorage.setItem("Team", JSON.stringify(data));
}

export function* SWITCH_COMPANY_ACTIVE_TEAM({ payload }) {
  let data = yield call(
    teamDatabase.getTeamNames,
    payload?.userId,
    payload?.companyId
  );
  localStorage.setItem("Team", JSON.stringify(data));
  if (data?.data[0]?.Role === "Member" || data?.data[0]?.Role === "Client") {
    localStorage.setItem("cid", payload?.companyId);
    window.location = `/dashboard-individual`;
  } else {
    window.location = `/dashboard-team?cid=${payload?.companyId}`;
  }
}

export function* GET_USER_ACTIVE_TEAM({
  payload: { resetTeamsData, ...payload },
}) {
  if (resetTeamsData)
    yield put({ type: "teams/GET_TEAMS_PENDING", payload: true });
  yield put({
    type: "team/SET_STATE",
    payload: {
      loading: true,
      activeTeam: {},
      teamMembers: [],
      ...payload,
    },
  });

  // const test = yield call(teamDatabase.getTeamData, payload?.userId);
  const activeResponse = yield call(
    teamDatabase.getActiveTeamID,
    payload?.userId
  );

  if (activeResponse.success) {
    if (payload?.activeTeamid) {
      const activeTeamResponse = yield call(
        teamDatabase.getActiveTeam,
        payload?.userId,
        payload?.activeTeamid,
        payload?.companyId
      );

      const teams = yield call(teamDatabase.getTeamsId, payload?.companyId);

      const getTeamNames = yield call(
        teamDatabase.getTeamNames,
        payload?.userId,
        payload?.companyId
      );
      localStorage.setItem(
        "Team",
        JSON.stringify({
          success: true,
          data: getTeamNames?.data,
        })
      );

      const AllTeams = localStorage.getItem("Team");

      // const teams = yield call(
      //   teamDatabase.getTeamsId,
      //   payload?.userId,
      //   payload?.companyId,
      // );

      // const tasks = yield call(teamDatabase.getTaskList, {
      //   teamId: activeResponse.data.activeTeamid,
      //   activeUserId: "",
      // });

      const teamMembers = yield call(
        teamDatabase.getTeamMembers,
        payload.activeTeamid,
        payload?.userId
      );

      const response = yield call(
        teamDatabase.getAllTeamTimeLog,
        payload.activeTeamid
      );
      if (response.success) {
        const sortData = orderBy(
          response.data,
          [
            function (value) {
              return moment(value.date, "D:M:YYYY");
            },
          ],
          ["desc"]
        );

        let form = payload.start;
        let until = payload.end;
        if (payload.days !== null) {
          form = moment().subtract(payload.days, "days");
          until = payload.days === 1 ? moment().subtract(1, "days") : moment();
        }
        const week = [];
        const month = [];

        const teamLogs = sortData.filter((day) => {
          const date = day?.date?.split(":").join("-");

          if (moment(date, "D-M-YYYY").isSame(new Date(), "week")) {
            week.push(day);
          }
          if (moment(date, "D-M-YYYY").isSame(new Date(), "month")) {
            month.push(day);
          }
          const isBetween = moment(date, "D-M-YYYY").isBetween(
            moment(form, "DD-MM-YYYY"),
            moment(until, "DD-MM-YYYY"),
            "day",
            "[]"
          );
          return isBetween;
        });

        if (activeTeamResponse.success) {
          yield put({
            type: "team/SET_STATE",
            payload: {
              loading: false,
              teamMembers: teamMembers.data,
              selectedTeamID: payload.activeTeamid,
              teams: JSON.parse(AllTeams),
              teamLogs: {
                calendar: sortData,
                selected: teamLogs,
                weekLogs: week,
                monthLogs: month,
              },
              // projects: projects?.data()?.projects ?? [],
              // selectedProject: projectId,
              // tasks: taskList,
              all: true,
              // activeUserID: selectedMemberID,
              activeTeam: {
                ...activeResponse.data,
                ...activeTeamResponse.data,
              },
            },
          });
        }
      }
    }
  } else {
    yield put({
      type: "team/SET_STATE",
      payload: {
        loading: false,
      },
    });
    addNotification({
      message: "Something went wrong. please try again!",
      level: notificationStates.error,
    });
  }
}

// export function* GET_SELECTED_TEAM_COLLECTION({ payload, profileID }) {
//   console.log("Active Team Collection : ", payload);
//   yield put({
//     type: "team/SET_STATE",
//     payload: {
//       loading: true,
//       activeTeam: payload,
//     },
//   });
//   const response = yield call(
//     teamDatabase.getActiveTeam,
//     profileID,
//     payload.docId
//   );
//   if (response.success) {
//     const teamMembers = yield call(
//       teamDatabase.getTeamMembers,
//       payload.docId,
//       profileID
//     );
//     let selectedMemberID = null;
//     if (size(teamMembers.data) > 1) {
//       const selectedMember = find(
//         teamMembers.data,
//         (member) => member.userUID === profileID
//       );
//       const selectedMembers = filter(
//         teamMembers.data,
//         (member) => member.role !== "manager"
//       );
//       if (!isEmpty(selectedMembers)) {
//         if (!isEmpty(selectedMember)) {
//           if (selectedMember.role !== "manager") {
//             selectedMemberID = selectedMember.userUID;
//           } else {
//             selectedMemberID = selectedMembers[0].userUID;
//           }
//         } else {
//           selectedMemberID = selectedMembers[0].userUID;
//         }
//       }
//     } else if (size(teamMembers.data) === 1) {
//       selectedMemberID = teamMembers.data[0].userUID;
//     }

//     yield put({
//       type: "team/SET_STATE",
//       payload: {
//         loading: false,
//         teamMembers: teamMembers.data,
//         selectedTeamID: payload.docId,
//         activeUserID: selectedMemberID,
//       },
//     });
//     // yield* GET_ALL_TEAM_TIMELOG({ payload: { teamId, days } });
//   } else {
//     yield put({
//       type: "team/SET_STATE",
//       payload: {
//         loading: false,
//       },
//     });
//     addNotification({
//       message: "Something went wrong. please try again!",
//       level: notificationStates.error,
//     });
//   }
// }

export function* GET_USER_TEAM_COLLECTION({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      teamLoading: true,
      teamLogs: [],
    },
  });
  const response = yield call(
    teamDatabase.getUserTeamCollection,
    payload.teamID,
    payload.selectedDate,
    payload.profileID
  );

  if (response.success) {
    const sortData = orderBy(
      response.data,
      [
        function (value) {
          return moment(value.date, "D:M:YYYY");
        },
      ],
      ["desc"]
    );
    yield put({
      type: "team/SET_STATE",
      payload: {
        teamLoading: false,
        teamLogs: sortData,
      },
    });
  } else {
    yield put({
      type: "team/SET_STATE",
      payload: {
        teamLoading: false,
      },
    });
    addNotification({
      message: "Something went wrong. please try again!",
      level: notificationStates.error,
    });
  }
}

export function* GET_MANAGER_TEAM_COLLECTION({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      teamLoading: true,
      teamLogs: [],
    },
  });

  const response = yield call(
    teamDatabase.getManagerTeamCollection,
    payload.activeTeamId,
    payload.activeUserId
  );
  if (response.success) {
    const sortData = orderBy(
      response.data,
      [
        function (value) {
          return moment(value.date, "D:M:YYYY");
        },
      ],
      ["desc"]
    );

    let form = payload.start;
    let until = payload.end;
    if (payload.days !== null) {
      form = moment().subtract(payload.days, "days");
      until = payload.days === 1 ? moment().subtract(1, "days") : moment();
    }
    const week = [];
    const month = [];
    const teamLogs = sortData.filter((day) => {
      const date = day.date.split(":").join("-");

      if (moment(date, "D-M-YYYY").isSame(new Date(), "week")) {
        week.push(day);
      }
      if (moment(date, "D-M-YYYY").isSame(new Date(), "month")) {
        month.push(day);
      }
      const isBetween = moment(date, "D-M-YYYY").isBetween(
        moment(form, "DD-MM-YYYY"),
        moment(until, "DD-MM-YYYY"),
        "day",
        "[]"
      );
      return isBetween;
    });

    yield put({
      type: "team/SET_STATE",
      payload: {
        teamLoading: false,
        teamLogs: {
          weekLogs: week,
          monthLogs: month,
          selected: teamLogs,
          calendar: sortData,
        },
      },
    });
  } else {
    yield put({
      type: "team/SET_STATE",
      payload: {
        teamLoading: false,
      },
    });
    addNotification({
      message: "Something went wrong. please try again!",
      level: notificationStates.error,
    });
  }
}

export function* GET_USER_TIMELOG_DATA({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      teamLoading: true,
      days: [],
    },
  });
  const response = yield call(
    payload.user.activeUserId
      ? teamDatabase.getMembersTimeLog
      : teamDatabase.getAllTeamTimeLog,
    payload.user.activeTeamId,
    payload.user.activeUserId,
    payload.startDate,
    payload.endDate
  );

  if (response.success) {
    if (payload.user.activeUserId) {
      yield put({
        type: "team/SET_STATE",
        payload: {
          teamLoading: false,
          days: response.data,
        },
      });
    } else {
      const days = [];
      response.data.forEach((doc) => {
        const day = doc.date.replaceAll(":", "-");
        if (
          moment(day, "DD-MM-YYYY").isBetween(
            moment(payload.startDate, "DD-MM-YYYY"),
            moment(payload.endDate, "DD-MM-YYYY"),
            "day",
            "[]"
          )
        ) {
          days.push({
            day,
            status: "Present",
            punchData: doc?.punchinout,
            timeLog: doc?.timelog,
            leave: doc?.leave
          });
        }
      });
      yield put({
        type: "team/SET_STATE",
        payload: {
          teamLoading: false,
          days,
        },
      });
    }
  }
}
export function* GET_ALL_TEAM_TIMELOG({ payload }) {
  console.log(payload, "payload in GET_ALL_TEAM_TIMELOG");
  yield put({
    type: "team/SET_STATE",
    payload: {
      teamLoading: true,
      teamLogs: [],
      activeUserID: "",
      teamTimeData: {},
      timelogDayRange: payload?.days,
    },
  });
  const response = yield call(teamDatabase.getAllTeamTimeLog, payload.teamId);
  let teamResponse = {};
  if (payload?.days === 0 || payload?.days === 1) {
    const date = moment().subtract(payload?.days, "days").format("D:M:YYYY");
    teamResponse = yield call(
      teamDatabase.getAllTeamTimeData,
      payload?.teamId,
      date
    );
    console.log("teamData : ", teamResponse);
  }

  if (payload?.days === 7) {
    // Get week range
    var currentDate = new Date();
    var firstday = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay())
    );
    var lastday = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6)
    );
    console.log(firstday, "firstday in GET_ALL_TEAM_TIMELOG");
    console.log(lastday, "lastday in GET_ALL_TEAM_TIMELOG");

    var d = new Date(firstday);
    var dateF = [
      ("0" + d.getDate()).slice(-2),
      ("0" + (d.getMonth() + 1)).slice(-2),
      d.getFullYear(),
    ].join("-");

    var d2 = new Date(lastday);
    var dateL = [
      ("0" + d2.getDate()).slice(-2),
      ("0" + (d2.getMonth() + 1)).slice(-2),
      d2.getFullYear(),
    ].join("-");

    let docId = `${dateF} to ${dateL}`;
    teamResponse = yield call(
      teamDatabase.getAllTeamWeekTimeData,
      payload?.teamId,
      docId
    );
    console.log("teamData : ", teamResponse);
  }

  if (payload?.days === 30) {
    const localDate = new Date();
    console.log("calculateMonth localDate", localDate);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let currentMonth = months[localDate.getMonth()];
    let year = localDate.getFullYear();
    let yearId = `${currentMonth}-${year}`;
    teamResponse = yield call(
      teamDatabase.getAllTeamMonthTimeData,
      payload?.teamId,
      yearId
    );
    console.log("teamData : ", teamResponse);
  }

  if (response.success) {
    const sortData = orderBy(
      response.data,
      [
        function (value) {
          return moment(value.date, "D:M:YYYY");
        },
      ],
      ["desc"]
    );

    let form = payload.start;
    let until = payload.end;

    if (payload.days !== null) {
      form = moment().subtract(payload.days, "days");
      until = payload.days === 1 ? moment().subtract(1, "days") : moment();
    }

    const week = [];
    const month = [];

    const teamLogs = sortData.filter((day) => {
      const date = day.date.split(":").join("-");

      if (moment(date, "D-M-YYYY").isSame(new Date(), "week")) {
        week.push(day);
      }
      if (moment(date, "D-M-YYYY").isSame(new Date(), "month")) {
        month.push(day);
      }

      const isBetween = moment(date, "D-M-YYYY").isBetween(
        moment(form, "DD-MM-YYYY"),
        moment(until, "DD-MM-YYYY"),
        "day",
        "[]"
      );
      return isBetween;
    });

    console.log(teamResponse.data, "data in GET_ALL_TEAM_TIMELOG");
    console.log(teamLogs, "data of teamLogs in GET_ALL_TEAM_TIMELOG");

    yield put({
      type: "team/SET_STATE",
      payload: {
        teamLoading: false,
        teamLogs: {
          calendar: sortData,
          selected: teamLogs,
          weekLogs: week,
          monthLogs: month,
        },
        all: true,
        teamTimeData: teamResponse.data,
        topProjects: teamResponse?.data ? teamResponse?.data?.topProjects : [],
        topData: teamResponse?.data ? teamResponse?.data?.topData : {},
        untrackedUsers: teamResponse?.data
          ? teamResponse?.data?.untrackedUsers
          : {},
      },
    });
  } else {
    yield put({
      type: "team/SET_STATE",
      payload: {
        teamLoading: false,
      },
    });
    addNotification({
      message: "Something went wrong. please try again!",
      level: notificationStates.error,
    });
  }
}
export function* ADD_PROJECT({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      loading: true,
    },
  });
  try {
    yield call(teamDatabase.addProject, payload);
    const projects = yield call(teamDatabase.getProjects, {
      companyId: payload?.companyId,
    });
    yield put({
      type: "team/SET_STATE",
      payload: {
        loading: false,
        projects: projects?.data,
      },
    });
  } catch (error) {
    addNotification({
      message: "Something went wrong! Please try later.",
      level: notificationStates.error,
    });
  }
}

export function* UPDATE_PROJECT({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      loading: true,
    },
  });
  try {
    yield call(teamDatabase.updateProject, payload);
    const projects = yield call(teamDatabase.getProjects, {
      companyId: payload.companyId,
    });
    yield put({
      type: "team/SET_STATE",
      payload: {
        loading: false,
        projects: projects.data,
      },
    });
  } catch (error) {
    addNotification({
      message: "Something went wrong! Please try later.",
      level: notificationStates.error,
    });
  }
}

export function* GET_PROJECT({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      projects: {},
      loadingProject: true,
    },
  });

  try {
    const projects = yield call(teamDatabase.getProjects, payload);

    yield put({
      type: "team/SET_STATE",
      payload: {
        loadingProject: false,
        projects: projects.data,
      },
    });
  } catch (error) {}
}

export function* ADD_TASKS({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      loading: true,
    },
  });

  try {
    const tasks = yield call(teamDatabase.addTaskList, payload);
    const projects = yield call(teamDatabase.getProjects, {
      companyId: payload.companyId,
    });

    yield put({
      type: "team/SET_STATE",
      payload: {
        loading: false,
        projects: projects.data,
      },
    });
    //   addNotification({
    //     message: tasks.message,
    //     level: notificationStates.success,
    //   });
  } catch (error) {
    addNotification({
      message: "Something went wrond, Please try later!",
      level: notificationStates.error,
    });
  }
}

export function* GET_TASKS({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      loading: true,
    },
  });

  try {
    const tasks = yield call(teamDatabase.getTaskList, payload);
    if (tasks.success) {
      const taskList = [];
      tasks.data.map((task) => {
        for (let t in task) {
          if (task[t]?.project === payload.projectId) {
            taskList.push({ ...task[t], taskId: t });
          }
        }
      });

      yield put({
        type: "team/SET_STATE",
        payload: {
          loading: false,
          tasks: taskList,
          selectedProject: payload.projectId,
        },
      });
    }
  } catch (error) {}
}

export function* DELETE_PROJECT({ payload }) {
  yield put({
    type: "team/SET_STATE",
    payload: {
      loading: true,
    },
  });

  try {
    yield call(teamDatabase.deleteProject, payload);

    const projects = yield call(teamDatabase.getProjects, payload.companyId);

    yield put({
      type: "team/SET_STATE",
      payload: {
        loading: false,
        projects: projects.data,
      },
    });
  } catch (error) {
    addNotification({
      message: "Something went wrong! Please try later.",
      level: notificationStates.error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SET_SELECTED_MEMBER_ID, SET_SELECTED_MEMBER_ID),
    takeLatest(actions.SET_TEAM_DATA, SELECT_TEAM_DATA),
    takeLatest(actions.SET_SELECT_TEAM_ID, SET_SELECT_TEAM),
    takeLatest(actions.SWITCH_COMPANY_ACTIVE_TEAM, SWITCH_COMPANY_ACTIVE_TEAM),
    takeLatest(actions.SET_TRENDS_DATA, SET_TRENDS_DATA),
    takeLatest(actions.SET_TODAY_TIMES, SET_TODAY_TIMES),
    takeLatest(actions.SET_SELECT_DATE, SET_SELECT_DATE),
    takeLatest(actions.SET_TIMELOG_DAY_RANGE, SET_TIMELOG_DAY_RANGE),
    takeLatest(actions.GET_DATE_RANGE_DATA, GET_DATE_RANGE_DATA),
    // takeLatest(
    //   actions.GET_SELECTED_TEAM_COLLECTION,
    //   GET_SELECTED_TEAM_COLLECTION
    // ),
    takeLatest(actions.GET_USER_ACTIVE_TEAM, GET_USER_ACTIVE_TEAM),
    takeLatest(
      actions.GET_MANAGER_TEAM_COLLECTION,
      GET_MANAGER_TEAM_COLLECTION
    ),
    takeLatest(actions.SET_TOP_USERS, SET_TOP_USERS),
    takeLatest(actions.ADD_PROJECT, ADD_PROJECT),
    takeLatest(actions.UPDATE_PROJECT, UPDATE_PROJECT),
    takeLatest(actions.GET_PROJECT, GET_PROJECT),
    takeLatest(actions.DELETE_PROJECT, DELETE_PROJECT),
    takeLatest(actions.GET_TASKS, GET_TASKS),
    takeLatest(actions.ADD_TASKS, ADD_TASKS),
    takeLatest(actions.GET_USER_TEAM_COLLECTION, GET_USER_TEAM_COLLECTION),
    takeLatest(actions.GET_USER_TIMELOG_DATA, GET_USER_TIMELOG_DATA),
    takeLatest(actions.GET_ALL_TEAM_TIMELOG, GET_ALL_TEAM_TIMELOG),
    takeLatest(actions.SET_THRESHOLD, SET_THRESHOLD),
    takeLatest(actions.GET_THRESHOLD, GET_THRESHOLD),
    takeLatest(actions.GET_TIME_DATA, GET_TIME_DATA),
    takeLatest(actions.GET_ALL_TEAMS_DATA, GET_ALL_TEAMS_DATA),
  ]);
}
