import { all, takeLatest, put, call } from "redux-saga/effects";
import memberDatabase from "../services/member";
import teamDatabase from "../services/team";
import actions from "../actions/Member";
import moment from "moment";
import { orderBy } from "lodash";
import {
  addNotification,
  notificationStates,
} from "../components/Notification";

export function* SET_TIMELOG_DAY_RANGE({ payload }) {
  console.log("payload in SET_TIMELOG_DAY_RANGE member", payload);
  yield put({
    type: "member/SET_STATE",
    payload: {
      timelogDayRange: payload.days,
      ...payload,
    },
  });
}

export function* GET_DATE_RANGE_DATA({ payload }) {
  yield put({
    type: "member/SET_STATE",
    payload: {
      timelogDayRange: payload.days,
      selectMember: payload?.selectMember ? payload?.selectMember : false,
      memberLoading: true,
      memberTimeData: {},
    },
  });

  let dateRangeData;
  dateRangeData = yield call(
    memberDatabase.getDateRangeTimeDataMember,
    moment(payload?.start, "DD-MM-YYYY").format("YYYY-MM-DD"),
    moment(payload?.end, "DD-MM-YYYY").format("YYYY-MM-DD"),
    payload?.id,
    payload?.userId
  );
  yield put({
    type: "member/SET_STATE",
    payload: {
      memberLoading: false,
      all: true,
      memberTimeData: dateRangeData.data,
      // activeUserID: payload?.activeUserID,
      topProjects: dateRangeData?.data ? dateRangeData?.data?.topProjects : [],
      topData: dateRangeData?.data ? dateRangeData?.data?.topData : {},
    },
  });
}

// GET_PROJECT_TIMELOG_DATA
export function* GET_PROJECT_TIMELOG_DATA({ payload }) {
  if (payload?.team?.data?.length > 0) {
    // Project data for current day or yesterday
    if (payload?.day === 0 || payload?.day === 1) {
      let idleHours = 0;
      let idleMinutes = 0;
      let taskHours = 0;
      let taskMinutes = 0;
      let productiveHours = 0;
      let productiveMinutes = 0;
      let timeDataArray = [];
      let list = [];
      let filteredList = [];
      const date = moment().subtract(payload.day, "days").format("D:M:YYYY");
      for (let i = 0; i < payload?.team?.data?.length; i++) {
        const timeData = yield call(
          memberDatabase.getMemberTimes,
          payload?.team?.data[i]?.activeTeamid,
          payload?.userId,
          date
        );
        timeDataArray.push(timeData);
      }
      for (let j = 0; j < timeDataArray?.length; j++) {
        if (timeDataArray[j]?.success) {
          if (timeDataArray[j]?.data?.timelogs) {
            Object.entries(timeDataArray[j]?.data?.timelogs).map((res) => {
              list.push(res[1]);
            });
          }
          if (list.length > 0) {
            filteredList = list?.filter(
              (x) => x?.projectId === payload?.projectId
            );
            if (filteredList.length > 0) {
              filteredList.map((r) => {
                idleHours += r?.idleHours ? r?.idleHours : 0;
                idleMinutes += r.idleMinutes;
                taskHours += r.taskHours;
                taskMinutes += r.taskMinutes;
                if (taskMinutes > 59) {
                  taskMinutes = 0;
                  taskHours += 1;
                }
                if (idleMinutes > 59) {
                  idleMinutes = 0;
                  idleHours += 1;
                }
              });
            }
          }
        }
      }
      const newTimeData = {
        idleHours: idleHours,
        idleMinutes: idleMinutes,
        totalHours: taskHours,
        totalMinutes: taskMinutes,
        productiveHours: productiveHours,
        productiveMinutes: productiveMinutes,
      };

      if (newTimeData) {
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: newTimeData,
            loading: false,
          },
        });
      } else {
        const data = {
          idleHours: 0,
          idleMinutes: 0,
          totalHours: 0,
          totalMinutes: 0,
          productiveHours: 0,
          productiveMinutes: 0,
        };
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: data,
            loading: false,
          },
        });
      }
    }

    // Data for last 7 days and last 30 days
    if (payload?.day === 7 || payload?.day === 30) {
      let d = payload?.day;
      let time7Days = [];
      let productiveHours = 0;
      let productiveMinutes = 0;
      let idleHours = 0;
      let idleMinutes = 0;
      let taskHours = 0;
      let taskMinutes = 0;
      let newTimeData = {};
      let list = [];
      let date;

      for (d; d >= 1; --d) {
        date = moment().subtract(d, "days").format("D:M:YYYY");
        for (let i = 0; i < payload?.team?.data?.length; i++) {
          time7Days.push(
            yield call(
              memberDatabase.getMemberTimes,
              payload?.team?.data[i]?.activeTeamid,
              payload?.userId,
              date
            )
          );
        }
      }

      time7Days.forEach((day) => {
        if (day.success) {
          if (day?.data?.timelogs) {
            Object.entries(day?.data?.timelogs).map((res) => {
              list.push(res[1]);
            });
          }
        }
      });
      if (list.length > 0) {
        const filteredList = list?.filter(
          (x) => x?.projectId === payload?.projectId
        );
        if (filteredList.length > 0) {
          filteredList.map((r) => {
            idleHours += r?.idleHours ? r?.idleHours : 0;
            idleMinutes += r.idleMinutes;
            taskHours += r.taskHours;
            taskMinutes += r.taskMinutes;
            if (taskMinutes > 59) {
              taskMinutes = 0;
              taskHours += 1;
            }
            if (idleMinutes > 59) {
              idleMinutes = 0;
              idleHours += 1;
            }
          });
        }
      }
      newTimeData = {
        idleHours: idleHours,
        idleMinutes: idleMinutes,
        totalHours: taskHours,
        totalMinutes: taskMinutes,
        productiveHours: productiveHours,
        productiveMinutes: productiveMinutes,
      };

      if (newTimeData) {
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: newTimeData,
            loading: false,
          },
        });
      } else {
        const data = {
          idleHours: 0,
          idleMinutes: 0,
          totalHours: 0,
          totalMinutes: 0,
          productiveHours: 0,
          productiveMinutes: 0,
        };
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: data,
            loading: false,
          },
        });
      }
    }

    // Data for dates between start and end date
    if (payload?.day === null) {
      let time7Days = [];
      let taskHours = 0;
      let taskMinutes = 0;
      let productiveHours = 0;
      let productiveMinutes = 0;
      let idleHours = 0;
      let idleMinutes = 0;
      let newTimeData = {};
      const s = moment(payload?.start, "DD-MM-YYYY").format("MM/DD/YYYY");
      const e = moment(payload?.end, "DD-MM-YYYY").format("MM/DD/YYYY");
      const startT = new Date(s);
      const endT = new Date(e);
      const date = new Date(startT.getTime());
      const dates = [];
      let list = [];
      while (date <= endT) {
        dates.push(moment(new Date(date)).format("D:M:YYYY"));
        date.setDate(date.getDate() + 1);
      }
      for (let d = 0; d < dates.length; d++) {
        for (let i = 0; i < payload?.team?.data?.length; i++) {
          time7Days.push(
            yield call(
              memberDatabase.getMemberTimes,
              payload?.team?.data[i]?.activeTeamid,
              payload?.userId,
              dates[d]
            )
          );
        }
      }

      time7Days.forEach((day) => {
        if (day.success) {
          if (day?.data?.timelogs) {
            Object.entries(day?.data?.timelogs).map((res) => {
              list.push(res[1]);
            });
          }
        }
      });
      if (list.length > 0) {
        const filteredList = list?.filter(
          (x) => x?.projectId === payload?.projectId
        );
        if (filteredList.length > 0) {
          filteredList.map((r) => {
            idleHours += r?.idleHours ? r?.idleHours : 0;
            idleMinutes += r.idleMinutes;
            taskHours += r.taskHours;
            taskMinutes += r.taskMinutes;
            if (taskMinutes > 59) {
              taskMinutes = 0;
              taskHours += 1;
            }
            if (idleMinutes > 59) {
              idleMinutes = 0;
              idleHours += 1;
            }
          });
        }
      }
      newTimeData = {
        idleHours: idleHours,
        idleMinutes: idleMinutes,
        totalHours: taskHours,
        totalMinutes: taskMinutes,
        productiveHours: productiveHours,
        productiveMinutes: productiveMinutes,
      };
      if (newTimeData) {
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: newTimeData,
            loading: false,
          },
        });
      } else {
        const data = {
          idleHours: 0,
          idleMinutes: 0,
          totalHours: 0,
          totalMinutes: 0,
          productiveHours: 0,
          productiveMinutes: 0,
        };
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: data,
            loading: false,
          },
        });
      }
    }
  } else {
    const data = {
      idleHours: 0,
      idleMinutes: 0,
      totalHours: 0,
      totalMinutes: 0,
      productiveHours: 0,
      productiveMinutes: 0,
    };
    yield put({
      type: "member/SET_STATE",
      payload: {
        timeData: data,
        loading: false,
      },
    });
  }
}

// GET_USER_TIMELOG_DATA
export function* GET_USER_TIMELOG_DATA({ payload }) {
  console.log("payload in GET_USER_TIMELOG_DATA", payload);
  yield put({
    type: "member/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      activeUserID: payload?.activeUserID,
      activeUserName: payload.activeUserName,
      days: payload?.day,
      selectedProjectId: payload?.projectId,
      loading: true,
    },
  });

  if (payload?.day === null) {
    let time7Days = [];
    let totalHours = 0;
    let totalMinutes = 0;
    let taskHours = 0;
    let taskMinutes = 0;
    let productiveHours = 0;
    let productiveMinutes = 0;
    let idleHours = 0;
    let idleMinutes = 0;
    let newTimeData = {};
    const s = moment(payload?.start, "DD-MM-YYYY").format("MM/DD/YYYY");
    const e = moment(payload?.end, "DD-MM-YYYY").format("MM/DD/YYYY");
    const startT = new Date(s);
    const endT = new Date(e);
    const date = new Date(startT.getTime());
    const dates = [];
    let rawData = [];
    let newList = [];
    let list = [];
    while (date <= endT) {
      dates.push(moment(new Date(date)).format("D:M:YYYY"));
      date.setDate(date.getDate() + 1);
    }
    for (let d = 0; d < dates.length; d++) {
      time7Days.push(
        yield call(
          memberDatabase.getMemberTimes,
          payload?.selectedTeamID,
          payload?.activeUserID,
          dates[d]
        )
      );
    }
    if (payload?.projectId) {
      time7Days.forEach((day) => {
        if (day.success) {
          if (day?.data?.timelogs) {
            Object.entries(day?.data?.timelogs).map((res) => {
              list.push(res[1]);
            });
          }
        }
      });
      if (list.length > 0) {
        const filteredList = list?.filter(
          (x) => x?.projectId === payload?.projectId
        );
        if (filteredList.length > 0) {
          filteredList.map((r) => {
            idleHours += r?.idleHours ? r?.idleHours : 0;
            idleMinutes += r.idleMinutes;
            taskHours += r.taskHours;
            taskMinutes += r.taskMinutes;
            if (taskMinutes > 59) {
              taskMinutes = 0;
              taskHours += 1;
            }
            if (idleMinutes > 59) {
              idleMinutes = 0;
              idleHours += 1;
            }
          });
        }
      }
      newTimeData = {
        idleHours: idleHours,
        idleMinutes: idleMinutes,
        totalHours: taskHours,
        totalMinutes: taskMinutes,
        productiveHours: productiveHours,
        productiveMinutes: productiveMinutes,
      };
    } else {
      time7Days.forEach((day) => {
        if (day.success) {
          totalHours += day.data.totalHours;
          totalMinutes += day.data.totalMinutes;
          productiveHours += day.data.productiveHours;
          productiveMinutes += day.data.productiveMinutes;
          idleHours += day.data.idleHours;
          idleMinutes += day.data.idleMinutes;
        }
      });
      newTimeData = {
        idleHours: idleHours,
        idleMinutes: idleMinutes,
        totalHours: totalHours,
        totalMinutes: totalMinutes,
        productiveHours: productiveHours,
        productiveMinutes: productiveMinutes,
      };
      //getTopProjects
      time7Days.forEach((day) => {
        if (day?.success) {
          if (day?.data?.timelogs !== undefined) {
            const arr = Object.entries(day?.data?.timelogs);
            if (arr?.length > 0) {
              for (let i = 0; i < arr?.length; i++) {
                newList.push(arr[i][1]);
              }
            }
          }
        }
      });

      if (newList?.length > 0) {
        for (let i = 0; i < newList?.length; i++) {
          yield call(
            teamDatabase.getTopProjects,
            newList[i]?.projectId,
            rawData,
            newList[i]
          );
        }
      }
    }
    yield put({
      type: "member/SET_STATE",
      payload: {
        timeData: newTimeData,
        loading: false,
        topProjects11: rawData,
      },
    });
  } else {
    if (payload?.day === 0 || payload?.day === 1) {
      let idleHours = 0;
      let idleMinutes = 0;
      let taskHours = 0;
      let taskMinutes = 0;
      let productiveHours = 0;
      let productiveMinutes = 0;
      let newTimeData = {};
      const date = moment().subtract(payload.day, "days").format("D:M:YYYY");
      const timeData = yield call(
        memberDatabase.getMemberTimes,
        payload?.selectedTeamID,
        payload?.activeUserID,
        date
      );
      if (timeData.success) {
        if (payload?.projectId) {
          let list = [];
          if (timeData?.data?.timelogs) {
            Object.entries(timeData?.data?.timelogs).map((res) => {
              list.push(res[1]);
            });
          }
          if (list.length > 0) {
            const filteredList = list?.filter(
              (x) => x?.projectId === payload?.projectId
            );
            if (filteredList.length > 0) {
              filteredList.map((r) => {
                idleHours += r?.idleHours ? r?.idleHours : 0;
                idleMinutes += r.idleMinutes;
                taskHours += r.taskHours;
                taskMinutes += r.taskMinutes;
                if (taskMinutes > 59) {
                  taskMinutes = 0;
                  taskHours += 1;
                }
                if (idleMinutes > 59) {
                  idleMinutes = 0;
                  idleHours += 1;
                }
              });
            }
          }
        }
        const newTimeData = {
          idleHours: idleHours,
          idleMinutes: idleMinutes,
          totalHours: taskHours,
          totalMinutes: taskMinutes,
          productiveHours: productiveHours,
          productiveMinutes: productiveMinutes,
        };

        //getTopProjects
        let rawData = [];
        let ids = [];
        if (timeData?.data?.timelogs !== undefined) {
          const arr = Object.entries(timeData?.data?.timelogs);
          if (arr?.length > 0) {
            for (let i = 0; i < arr?.length; i++) {
              ids.push(arr[i][1].projectId);
              yield call(
                teamDatabase.getTopProjects,
                arr[i][1]?.projectId,
                rawData,
                arr[i][1]
              );
            }
          }
        }
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: payload?.projectId ? newTimeData : timeData.data,
            loading: false,
            topProjects11: rawData,
          },
        });
      } else {
        const data = {
          idleHours: 0,
          idleMinutes: 0,
          totalHours: 0,
          totalMinutes: 0,
          productiveHours: 0,
          productiveMinutes: 0,
        };
        yield put({
          type: "member/SET_STATE",
          payload: {
            timeData: data,
            loading: false,
          },
        });
      }
    }
    if (payload?.day === 7 || payload?.day === 30) {
      let d = payload?.day;
      let time7Days = [];
      let totalHours = 0;
      let totalMinutes = 0;
      let productiveHours = 0;
      let productiveMinutes = 0;
      let idleHours = 0;
      let idleMinutes = 0;
      let taskHours = 0;
      let taskMinutes = 0;
      let newTimeData = {};
      let list = [];
      let date;

      for (d; d >= 1; --d) {
        date = moment().subtract(d, "days").format("D:M:YYYY");
        time7Days.push(
          yield call(
            memberDatabase.getMemberTimes,
            payload?.selectedTeamID,
            payload?.activeUserID,
            date
          )
        );
      }
      if (payload?.projectId) {
        time7Days.forEach((day) => {
          if (day.success) {
            if (day?.data?.timelogs) {
              Object.entries(day?.data?.timelogs).map((res) => {
                list.push(res[1]);
              });
            }
          }
        });
        if (list.length > 0) {
          const filteredList = list?.filter(
            (x) => x?.projectId === payload?.projectId
          );
          if (filteredList.length > 0) {
            filteredList.map((r) => {
              idleHours += r?.idleHours ? r?.idleHours : 0;
              idleMinutes += r.idleMinutes;
              taskHours += r.taskHours;
              taskMinutes += r.taskMinutes;
              if (taskMinutes > 59) {
                taskMinutes = 0;
                taskHours += 1;
              }
              if (idleMinutes > 59) {
                idleMinutes = 0;
                idleHours += 1;
              }
            });
          }
        }
        newTimeData = {
          idleHours: idleHours,
          idleMinutes: idleMinutes,
          totalHours: taskHours,
          totalMinutes: taskMinutes,
          productiveHours: productiveHours,
          productiveMinutes: productiveMinutes,
        };
      } else {
        time7Days.forEach((day) => {
          if (day.success) {
            totalHours += day.data.totalHours;
            totalMinutes += day.data.totalMinutes;
            productiveHours += day.data.productiveHours;
            productiveMinutes += day.data.productiveMinutes;
            idleHours += day.data.idleHours;
            idleMinutes += day.data.idleMinutes;
          }
        });
        newTimeData = {
          idleHours: idleHours,
          idleMinutes: idleMinutes,
          totalHours: totalHours,
          totalMinutes: totalMinutes,
          productiveHours: productiveHours,
          productiveMinutes: productiveMinutes,
        };
      }
      //getTopProjects
      let rawData = [];
      let newList = [];
      time7Days.forEach((day) => {
        if (day?.success) {
          if (day?.data?.timelogs !== undefined) {
            const arr = Object.entries(day?.data?.timelogs);
            if (arr?.length > 0) {
              for (let i = 0; i < arr?.length; i++) {
                newList.push(arr[i][1]);
              }
            }
          }
        }
      });
      if (newList?.length > 0) {
        for (let i = 0; i < newList?.length; i++) {
          yield call(
            teamDatabase.getTopProjects,
            newList[i]?.projectId,
            rawData,
            newList[i]
          );
        }
      }

      yield put({
        type: "member/SET_STATE",
        payload: {
          timeData: newTimeData,
          topProjects11: rawData,
          loading: false,
        },
      });
    }
  }
}

export function* GET_USER_WEEK_DATA({ payload }) {
  yield put({
    type: "member/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      activeUserID: payload?.activeUserID,
      week: payload?.week,
    },
  });

  const weekData = yield call(
    memberDatabase.getMemberWeekTimes,
    payload?.selectedTeamID,
    payload?.activeUserID,
    payload?.week
  );

  if (weekData.success) {
    yield put({
      type: "member/SET_STATE",
      payload: {
        weekData: weekData.data,
      },
    });
  } else {
    const data = {
      idleHours: 0,
      idleMinutes: 0,
      totalHours: 0,
      totalMinutes: 0,
      productiveHours: 0,
      productiveMinutes: 0,
    };
    yield put({
      type: "member/SET_STATE",
      payload: {
        weekData: data,
      },
    });
  }
}

export function* GET_USER_TABLE_DATA({ payload }) {
  yield put({
    type: "member/SET_STATE",
    payload: {
      selectedTeamID: payload?.selectedTeamID,
      activeUserID: payload?.activeUserID,
    },
  });
  let d = 6;
  let tableData = [];
  for (d; d >= 0; --d) {
    let date = moment().subtract(d, "days").format("D:M:YYYY");
    tableData.push(
      yield call(
        memberDatabase.getMemberTableData,
        payload?.selectedTeamID,
        payload?.activeUserID,
        date
      )
    );
  }

  yield put({
    type: "member/SET_STATE",
    payload: {
      tableData: tableData,
    },
  });
}

export function* GET_Member_TIMELOG({ payload }) {
  yield put({
    type: "member/SET_STATE",
    payload: {
      memberLoading: true,
      memberLogs: [],
      activeUserID: payload?.activeUserId,
      memberTimeData: {},
      days: payload?.days,
      timelogDayRange: payload.days,
      selectMember: payload?.selectMember ? payload?.selectMember : false,
    },
  });
  const response = yield call(teamDatabase.getAllTeamTimeLog, payload.teamId);
  let memberResponse = {};
  if (payload?.days === 0 || payload?.days === 1) {
    const date = moment().subtract(payload?.days, "days").format("D:M:YYYY");
    memberResponse = yield call(
      memberDatabase.getMemberTimeData,
      payload?.teamId,
      payload?.activeUserID,
      date
    );
    console.log("memberData : ", memberResponse);
  }

  if (payload?.days === 7) {
    // Get week range
    var currentDate = new Date();
    var firstday = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay())
    );
    var lastday = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6)
    );

    var d = new Date(firstday);
    var dateF = [
      ("0" + d.getDate()).slice(-2),
      ("0" + (d.getMonth() + 1)).slice(-2),
      d.getFullYear(),
    ].join("-");

    var d2 = new Date(lastday);
    var dateL = [
      ("0" + d2.getDate()).slice(-2),
      ("0" + (d2.getMonth() + 1)).slice(-2),
      d2.getFullYear(),
    ].join("-");

    let docId = `${dateF} to ${dateL}`;
    memberResponse = yield call(
      memberDatabase.getMemberWeekTimeData,
      payload?.teamId,
      payload?.activeUserID,
      docId
    );
    console.log("memberData : ", memberResponse);
  }

  if (payload?.days === 30) {
    const localDate = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let currentMonth = months[localDate.getMonth()];
    let year = localDate.getFullYear();
    let yearId = `${currentMonth}-${year}`;
    memberResponse = yield call(
      memberDatabase.getMemberMonthTimeData,
      payload?.teamId,
      payload?.activeUserID,
      yearId
    );
    console.log("memberData : ", memberResponse);
  }

  if (response.success) {
    // const sortData = orderBy(
    //   response.data,
    //   [
    //     function (value) {
    //       return moment(value.date, "D:M:YYYY");
    //     },
    //   ],
    //   ["desc"]
    // );

    // console.log(sortData, "sortData");
    let form = payload.start;
    let until = payload.end;

    if (payload.days !== null) {
      form = moment().subtract(payload.days, "days");
      until = payload.days === 1 ? moment().subtract(1, "days") : moment();
    }

    // const week = [];
    // const month = [];

    // const memberLogs = sortData.filter((day) => {
    //   const date = day.date.split(":").join("-");

    //   if (moment(date, "D-M-YYYY").isSame(new Date(), "week")) {
    //     week.push(day);
    //   }
    //   if (moment(date, "D-M-YYYY").isSame(new Date(), "month")) {
    //     month.push(day);
    //   }

    //   const isBetween = moment(date, "D-M-YYYY").isBetween(
    //     moment(form, "DD-MM-YYYY"),
    //     moment(until, "DD-MM-YYYY"),
    //     "day",
    //     "[]"
    //   );
    //   return isBetween;
    // });
    yield put({
      type: "member/SET_STATE",
      payload: {
        memberLoading: false,
        // memberLogs: {
        //   calendar: sortData,
        //   selected: memberLogs,
        //   weekLogs: week,
        //   monthLogs: month,
        // },
        all: true,
        memberTimeData: memberResponse.data,
        activeUserID: payload?.activeUserID,
        topProjects: memberResponse?.data
          ? memberResponse?.data?.topProjects
          : [],
        topData: memberResponse?.data ? memberResponse?.data?.topData : {},
        untrackedUsers: memberResponse?.data
          ? memberResponse?.data?.untrackedUsers
          : {},
      },
    });
  } else {
    yield put({
      type: "member/SET_STATE",
      payload: {
        memberLoading: false,
      },
    });
    addNotification({
      message: "Something went wrong. please try again!",
      level: notificationStates.error,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_USER_TIMELOG_DATA, GET_USER_TIMELOG_DATA),
    takeLatest(actions.GET_USER_WEEK_DATA, GET_USER_WEEK_DATA),
    takeLatest(actions.GET_USER_TABLE_DATA, GET_USER_TABLE_DATA),
    takeLatest(actions.GET_PROJECT_TIMELOG_DATA, GET_PROJECT_TIMELOG_DATA),
    takeLatest(actions.GET_Member_TIMELOG, GET_Member_TIMELOG),
    takeLatest(actions.SET_TIMELOG_DAY_RANGE, SET_TIMELOG_DAY_RANGE),
    takeLatest(actions.GET_DATE_RANGE_DATA, GET_DATE_RANGE_DATA),
  ]);
}
