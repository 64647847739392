import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import "./CustomSelect.css";

const CustomSelect = ({
  options,
  selected,
  setSelected,
  onChange,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const selectRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [selectRef]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const index = selected.findIndex((item) => item.value === option.value);
    if (index === -1) {
      setSelected([...selected, option]);
    } else {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    }
    onChange && onChange(selected);
  };

  const handleClearSelection = () => {
    setSelected([]);
    onChange && onChange([]);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div id="exampleSelectMulti" className="custom-select" ref={selectRef}>
      <div className="select-header" onClick={toggleOpen}>
        {selected.length > 0
          ? selected.map((option) => (
              <spa className="option-render">{option.label}</spa>
            ))
          : placeholder || "Select an option"}
      </div>
      {selected.length > 0 && (
        <div className="clear-selection-icon" onClick={handleClearSelection}>
          <FaTimes />
        </div>
      )}
      {isOpen && (
        <div className="select-options">
          <div className="select-options-header">
            <input
              type="text"
              value={searchTerm}
              placeholder="Search..."
              onChange={handleSearchChange}
            />
          </div>
          <div className="all-option-container">
            {filteredOptions.map((option) => (
              <div
                className="select-option dropdown-select-option"
                key={option.value}
                onClick={() => handleOptionClick(option)}
              >
                <input
                  type="checkbox"
                  id={option.value}
                  checked={
                    selected.findIndex(
                      (item) => item.value === option.value
                    ) !== -1
                  }
                  onChange={() => handleOptionClick(option)}
                />
                <span onClick={() => handleOptionClick(option)}>
                  {option.label}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
