import { FormFeedback } from 'reactstrap';
import React from 'react';
import _ from 'lodash';

/**
 * ErrorRenderer function
 * @param {*} errors - Errors Object
 * @param {*} fieldName - Field name 
 * @returns - FormFeedback with message
 */

export const errorRenderer = (errors, fieldName) => {

    return (errors && errors[fieldName]) ? <FormFeedback className="d-block">
        {errors[fieldName].message}
    </FormFeedback> : undefined

}


// @@ React-Select Error styles
export const reactSelectErrorStyles = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #ea5455 !important'
    })
}


export const generateDistinctColor = (str) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

// ** Function to extract initials from content
export const getInitials = str => {
    const results = []
    const wordArray = str.split(' ')
    wordArray.forEach(e => {
        results.push(e[0])
    })
    return results.join('')
}

export const currentCompany = () => {
    let cid = localStorage.getItem('cid');
    return !_.isEmpty(cid) ? cid : '';
}