/* eslint-disable array-callback-return */
import React, { useLayoutEffect, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Timeline from "./timeline";
import { Card, Table, Container, Row, Label, Input, Col } from "reactstrap";
import "../../assets/scss/argon-dashboard/custom/body-container/_body-container.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PulseLoader from "react-spinners/PulseLoader";
import "../../assets/css/Calendar.css";
import { css } from "@emotion/core";
import { useSelector } from "react-redux";
import { usePrevious } from "../../hooks/userPrevious";
import HoursTracked from "../../components/Team_member/HoursTrackedCard/hourstracked";
import IdleTimeCard from "../../components/Team_member/IdleTime/indes";
import ProductivityPercentage from "../../components/Team_member/ProducutivePercent";
import ProjectTable from "./projectDetailTable";
import firebase from "../../config"
const database = firebase.firestore();

const override = css`
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 250px;
  border-color: green;
`;
const overrideFullScreen = css`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  border-color: green;
`;

const DashboardIndividual = ({
  week,
  timelogDayRange,
  loading,
  profileID,
  dispatch,
  selectedTeamID,
  weekData,
  tableData,
  active,
}) => {
  const [rate, setRates] = React.useState("")
  const [ratesLoaded, setRatesLoaded] = useState(false);
  const teams = useSelector((state) => ({
    projects: state.team.projects,
    projectId: state.team.selectedProjectId,
    days: state.team.days,
  }));
  const user = useSelector((state) => ({
    companyList: state.auth?.profile?.companyList,
    profile: state?.auth?.profile,
  }));

  const { activeCompany } = useSelector((state) => state.auth.profile);

  const team = useSelector((state) => ({
    start: state.team.start,
    end: state.team.end,
    selectedTeamID: state.team.selectedTeamID,
    projects: state.team.projects,
    topProjects: state?.team?.topProjects,
    membersData: state?.team?.membersData,
    teams: state?.team?.teams,
    timelogDayRange: state?.team?.timelogDayRange,
    days: state?.team?.days,
  }));
  const member = useSelector((state) => ({
    topProjects: state?.member?.topProjects,
    memberLoading: state?.member?.memberLoading,
    days: state?.member?.days,
    timelogDayRange: state?.member?.timelogDayRange,
    activeUserID: state?.member?.activeUserID,
    timeData: state?.member?.memberTimeData,
    selectMember: state?.member?.selectMember,
  }));
  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  let prevTeams = usePrevious(team?.teams);
  let currentTeams = team?.teams;
  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let membersData = successPayload ? successPayload.data : [];
  let members =
    membersData && team?.selectedTeamID && membersData.length > 0
      ? membersData.filter((t) => {
          return t.teams.find(
            (teamIn) => teamIn.teamId === team?.selectedTeamID
          );
        })
      : [];
  useLayoutEffect(() => {
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
      }
    }
    if (!isEmpty(profileID && active)) {
      dispatch({
        type: "member/GET_USER_TIMELOG_DATA",
        payload: {
          selectedTeamID: active.activeTeamid,
          activeUserID: profileID,
          day: member.timelogDayRange,
          // day: timelogDayRange,
          start: team?.start,
          end: team?.end,
        },
      });
      dispatch({
        type: "member/GET_USER_WEEK_DATA",
        payload: {
          selectedTeamID: active.activeTeamid,
          activeUserID: profileID,
          week: week,
        },
      });
      dispatch({
        type: "member/GET_USER_TABLE_DATA",
        payload: {
          selectedTeamID: active?.activeTeamid,
          activeUserID: profileID,
        },
      });
      dispatch({
        type: "team/GET_PROJECT",
        payload: { companyId: activeCompany.companyId },
      });
    }
  }, [selectedTeamID, profileID]);

  useEffect(() => {
    if (
      !isEmpty(profileID && active) &&
      member?.timelogDayRange !== "range"
    ) {
      console.log("in component of individual useeffect 1");
      dispatch({
        type: "member/GET_Member_TIMELOG",
        payload: {
          days: timelogDayRange,
          start: member?.start,
          end: member?.end,
          teamId: active?.activeTeamid,
          activeUserID: member?.activeUserID,
        },
      });
    }
  }, [selectedTeamID, member?.timelogDayRange]);

  useEffect(() => {
    if (!isEmpty(profileID && active)) {
      console.log("in component of individual useeffect 2");
      dispatch({
        type: "member/GET_USER_TIMELOG_DATA",
        payload: {
          selectedTeamID: active.activeTeamid,
          activeUserID: profileID,
          day: timelogDayRange,
          start: team?.start,
          end: team?.end,
        },
      });
      dispatch({
        type: "member/GET_Member_TIMELOG",
        payload: {
          days: timelogDayRange,
          start: member?.start,
          end: member?.end,
          teamId: active?.activeTeamid,
          activeUserID: profileID,
        },
      });
    }
  }, [selectedTeamID]);

  useEffect(() => {
    dispatch({
      type: "team/SET_STATE",
      payload: {
        activeUserID: member.activeUserID
      },
    });
  }, [member?.activeUserID]);

  if (tableData.length !== 0) {
    loading = false;
  }

  let days = [];
  let today = moment();
  let day = today.subtract(0, "days");
  days.push(day.format("ddd, MMM D"));
  for (let i = 0; i < 6; i++) {
    day = today.subtract(1, "days");
    days.push(day.format("ddd, MMM D"));
  }
  // let totalHours = timeData?.totalHours;
  // let totalMinutes = timeData?.totalMinutes;
  // let idleHours = timeData?.idleHours;
  // let idleMinutes = timeData?.idleMinutes;
  // let totalMinutesToHours = totalMinutes / 60;
  // let idleMinutesToHours = idleMinutes / 60;

  // totalHours += Math.trunc(totalMinutesToHours);
  // totalMinutes = totalMinutes % 60;

  // idleHours += Math.trunc(idleMinutesToHours);
  // idleMinutes = idleMinutes % 60;

  // let totalHoursToMinutes = totalHours * 60 + totalMinutes;
  // let idleHoursToMinutes = idleHours * 60 + idleMinutes;

  // let productiveHours = totalHours - idleHours;
  // let productiveMinutes = totalMinutes - idleMinutes;
  // if (productiveMinutes < 0) {
  //   productiveMinutes = 60 + productiveMinutes;
  //   productiveHours -= 1;
  // }
  // let productiveHoursToMinutes = productiveHours * 60 + productiveMinutes;

  // let productiveMinutesToHours = productiveMinutes / 60;
  // productiveHours += Math.trunc(productiveMinutesToHours);
  // productiveMinutes = productiveMinutes % 60;

  // let productiveHoursPercent = 0;
  // let idleHoursPercent = 0;
  // if (productiveHoursToMinutes !== 0) {
  //   productiveHoursPercent =
  //     (productiveHoursToMinutes / totalHoursToMinutes) * 100;
  // }
  // if (idleHoursToMinutes !== 0) {
  //   idleHoursPercent = (idleHoursToMinutes / totalHoursToMinutes) * 100;
  // }

  // let timedata = {
  //   totalHours: totalHours,
  //   totalMinutes: totalMinutes,
  //   idleHours: idleHours,
  //   idleMinutes: idleMinutes,
  //   productiveHours: productiveHours,
  //   productiveMinutes: productiveMinutes,
  //   productiveHoursPercent: productiveHoursPercent,
  //   idleHoursPercent: idleHoursPercent,
  // };

  let newtableData = [];
  let filterNewTableData = [];

  const tableToAdd = () => {
    let tt = [];
    let d = 6;
    let date = [];
    for (d; d >= 0; --d) {
      date.push(moment().subtract(d, "days").format("ddd, MMM D"));
    }

    tt = tableData.map((element, i) => {
      if (element.success && Object.values(element.data.data.tableData).length) {
        return Object.values(element.data.data.tableData);
      } else {
        return [[`datename${date[i]}`, `temp${date[i]}`, `temp${date[i]}`]];
      }
    });

    tt = tt.filter(function (el) {
      return el != null;
    });

    tt.reverse();

    tt = tt.flat();

    let pp = [];

    tt.map((element) => {
      return Object.values(element).map((el) => {
        if (el.match("new")) {
          var ret = el.replace(/new /g, "");
          let parsedTime = moment.utc(ret, "ddd MMM DD YYYY HH:mm:ss [GMT]Z");
          let hh = parsedTime.hours();
          let mm = parsedTime.minutes();
          ret = new Date(0, 0, 0, hh, mm, 0);
          var m = moment(ret, "ddd MMM D YYYY HH:mm:ss");
          ret = m.toDate();

          const date = new Date('1899-12-31T00:00:00');
          date.setHours(hh);
          date.setMinutes(mm);
          pp.push(date);
        } else if (el.match("temp")) {
          var re = new Date(0, 0, 0, 0, 0, 0);
          pp.push(re);
        } else if (el.match("datename")) {
          var ree = el.replace(/datename/g, "");
          pp.push(ree);
        } else {
          pp.push(el);
        }
      });
    });

    // tt.map((element) => {
    //   return Object.values(element).map((el) => {
    //     if (el.match("new")) {
    //       var ret = el.replace(/new /g, "");
    //       let hh = moment(ret).format("HH");
    //       let mm = moment(ret).format("mm");
    //       ret = new Date(0, 0, 0, hh, mm, 0);
    //       pp.push(ret);
    //     } else if (el.match("temp")) {
    //       var re = el.replace(/temp/g, "");
    //       let hh = moment(ret).format("HH");
    //       let mm = moment(ret).format("mm");
    //       re = new Date(0, 0, 0, hh, mm, 0);
    //       pp.push(re);
    //     } else if (el.match("datename")) {
    //       var ree = el.replace(/datename/g, "");
    //       pp.push(ree);
    //     } else {
    //       pp.push(el);
    //     }
    //   });
    // });

    let chunk = [];
    chunk.push([
      { type: "string", id: "Room" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ]);

    const chunkSize = 3;

    for (let i = 0; i < pp.length; i += chunkSize) {
      chunk.push(pp.slice(i, i + chunkSize));
    }

    for (let i = 0; i < chunk.length; i++) {
      if (chunk[i][1] > chunk[i][2]) {
        const saveDate = [
          chunk[i][0],
          new Date(
            "Sun Dec 31 1899 00:00:00 GMT+0428 (Pakistan Standard Time)"
          ),
          chunk[i][2],
        ];
        chunk[i][2] = new Date(
          "Sun Dec 31 1899 23:59:59 GMT+0428 (Pakistan Standard Time)"
        );
        // chunk.push(saveDate);
      }
    }
    // Token auth

    // const myCallableFunction = firebase
    //   .functions()
    //   .httpsCallable("teamDataToday/authenticateUser");
    //   myCallableFunction({ auth: "U2FsdGVkX18ch4x5t/aWjwyaTFZ92YnmAZUkfLTZvOCgqY5G9semfhS6/rhwrtRWDFSeGA0DCsji8SD7DB2B/g==" })
    //   .then((result) => {
    //     console.log("result", result);
    //   })
    //   .catch((err) => {
    //     console.log("wwwwwww", err);
    //   });
    newtableData = chunk;

    // for (let i = 0; i < newtableData.length; i++) {
    //   if (
    //     moment(newtableData[i][1]).diff(
    //       moment(newtableData[i][2]),
    //       "seconds"
    //     ) === 0
    //   ) {
    //     newtableData[i][1] = 0;
    //     newtableData[i][2] = 0;
    //     filterNewTableData.push(newtableData[i]);
    //   }
    // }

  };

  const timeTracked = (d, rates = false) => {
    let time = [];
    let reversedArray = [];
    reversedArray = tableData.map((element) => {
      if (element.success) {
        let h = element.data.data.totalHours;
        let m = element.data.data.totalMinutes;
        if (m > 59) {
          h += Math.floor(m / 60);
          m = m % 60;
        }
        if(rates){
          return ((h + (m/60)) * (rate ? rate : 0)).toFixed(2);
        }
        return `${h}h ${m}m`;
      } else {
        if(rates){
          return "0";
        }
        return "0h 0m";
      }
    });
    time = reversedArray.reverse();
    let sendTime = "";
    days.map((day, i) => {
      if (day === d) {
        sendTime = time[i];
      }
    });
    return sendTime;
  };

  const startTime = (d) => {
    let time = [];
    let reversedArray = [];
    reversedArray = tableData.map((element) => {
      if (element.success && element.data.data.startTime !== undefined) {
        return `${moment(element.data.data.startTime.time).format("LT")}`;
      } else {
        return "-";
      }
    });
    time = reversedArray.reverse();
    let sendTime = "";
    days.map((day, i) => {
      if (day === d) {
        sendTime = time[i];
      }
    });
    return sendTime;
  };

  const endTime = (d) => {
    let time = [];
    let reversedArray = [];
    reversedArray = tableData.map((element) => {
      if (
        element?.success &&
        element?.data?.data?.endTime !== "" &&
        element?.data?.data?.endTime !== undefined
      ) {
        return `${moment(element?.data?.data?.endTime?.time).format("LT")}`;
      } else {
        return "-";
      }
    });
    time = reversedArray.reverse();
    let sendTime = "";
    days.map((day, i) => {
      if (day === d) {
        sendTime = time[i];
      }
    });
    return sendTime;
  };

  const showTableData = () => {
    if (Object.keys(tableData).length > 0) {
      let increment = 0;
      return days.map((day) => {
        increment++;
        return (
          <tr>
            <td style={{ textAlign: "center" }} className="p-0" data-th="Date">
              {day}
            </td>
            <td
              style={{ textAlign: "center" }}
              className="p-0"
              data-th="Time Tracked"
            >
              {timeTracked(day)}
            </td>
            <td
              style={{ textAlign: "center" }}
              className="p-0"
              data-th="Rates($)"
            >
              {rate ? rate : "-"}
            </td>
            <td
              style={{ textAlign: "center" }}
              className="p-0"
              data-th="Total Earnings($)"
            >
              {timeTracked(day, true)}
            </td>
            <td
              style={{ textAlign: "center" }}
              className="p-0"
              data-th="Start Time"
            >
              {startTime(day)}
            </td>
            <td
              style={{ textAlign: "center" }}
              className="p-0"
              data-th="End Time"
            >
              {endTime(day)}
            </td>
            <td className="p-0">{increment === 7 ? tableToAdd() : null}</td>
          </tr>
        );
      });
    }
  };
  if (members?.length > 0) {
    const s = members?.sort(
      (a, b) => (b.userUID === profileID) - (a.userUID === profileID)
    );
  }

  useEffect(() => {
    if (!ratesLoaded) {
      handleGetRates(user?.profile?.id);
      setRatesLoaded(true);
    }
  }, [user?.profile?.id, ratesLoaded]);

  const handleGetRates = async(id) => {
    const teamData = await database.collection("teamDatabase").doc(active?.activeTeamid).get()
    const document = teamData.data()
    const teamMembers = document.memberDetails;
    const objectWithKeySomething = teamMembers[id];
    const rate = objectWithKeySomething.rates ? objectWithKeySomething.rates : 0
    setRates(rate)
  }

  return (
    <>
      {member.memberLoading && (
        <div
          id="overlay"
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.2)",
            zIndex: 2,
          }}
        >
          <div className="sweet-loading">
            <PulseLoader
              css={overrideFullScreen}
              size={50}
              color={"#02a17c"}
              loading={member?.memberLoading}
            />
          </div>
        </div>
      )}
      {user?.profile?.role !== ("Member" || "Client") && (
        <div style={{ paddingTop: "5rem!important" }}>
          <Container className="pt-md-0" fluid>
            <Label for="exampleSelectMulti" className="main">
              Select Member
            </Label>
            <Input
              type="select"
              name="selectMulti"
              id="exampleSelectMulti"
              onChange={async (e) => {
                e.persist();
                handleGetRates(e.target.value);
                if (
                  member?.timelogDayRange === "range" &&
                  member?.selectMember === true
                ) {
                  const selectedUser = members.find(member => member.userUID === e.target.value)?.userName;
                  dispatch({
                    type: "member/GET_Member_TIMELOG",
                    payload: {
                      days: 0,
                      start: member?.start,
                      end: member?.end,
                      teamId: active?.activeTeamid,
                      activeUserID: e?.target?.value,
                      selectMember: true,
                    },
                  });
                  dispatch({
                    type: "member/GET_USER_TIMELOG_DATA",
                    payload: {
                      activeUserName: selectedUser,
                    }});
                } else {
                  if (!isEmpty(profileID && active)) {
                    const selectedUser = members.find(member => member.userUID === e.target.value)?.userName;
                    dispatch({
                      type: "member/GET_USER_TIMELOG_DATA",
                      payload: {
                        selectedTeamID: active.activeTeamid,
                        activeUserID: e?.target?.value,
                        activeUserName: selectedUser,
                        day: member.timelogDayRange,
                        start: team?.start,
                        end: team?.end,
                      },
                    });
                    dispatch({
                      type: "member/GET_Member_TIMELOG",
                      payload: {
                        days: member.timelogDayRange,
                        start: member?.start,
                        end: member?.end,
                        teamId: active?.activeTeamid,
                        activeUserID: e?.target?.value,
                      },
                    });
                    dispatch({
                      type: "member/GET_USER_WEEK_DATA",
                      payload: {
                        selectedTeamID: active.activeTeamid,
                        activeUserID: e?.target?.value,
                        week: week,
                      },
                    });
                    dispatch({
                      type: "member/GET_USER_TABLE_DATA",
                      payload: {
                        selectedTeamID: active?.activeTeamid,
                        activeUserID: e?.target?.value,
                      },
                    });
                    dispatch({
                      type: "team/SET_STATE",
                      payload: {
                        activeUserID: e?.target?.value,
                      },
                    });
                    dispatch({
                      type: "team/GET_PROJECT",
                      payload: { companyId: activeCompany.companyId },
                    });
                  }
                }
              }}
            >
              {members &&
                members.length > 0 &&
                members?.map((res, key) => {
                  return (
                    <option key={res?.userUID} value={res?.userUID}>
                      {res?.userName}
                    </option>
                  );
                })}
            </Input>
          </Container>
        </div>
      )}
      <Timeline rates={rate} />

      <Container className="mt-2" fluid>
        <Card className="shadow p-3">
          <div style={{ height: `auto` }}>
            {user.companyList.length === 0 ? (
              <div className="row row-height">
                <div className="col-md-12 col-sm-12 col-lg-6 pr-0">
                  <Table className="table-height">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time Tracked</th>
                        <th>Rates ($)</th>
                        <th>Total Earnings($)</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>{showTableData()}</tbody>
                  </Table>
                </div>
                <div className="col-md-12 col-sm-12 col-lg-6 pl-3 timeline-chart abc">
                  <Chart
                    chartType="Timeline"
                    data={[{type: "string", id: "Room"}, {type: "date", id: "Start"}, {type: "date", id: "End"}]}
                    width="100%"
                    height="100%"
                    options={{
                      timeline: {
                        showRowLabels: false,
                        singleColor: "#8d8",
                      },
                      backgroundColor: "#fff",
                      alternatingRowStyle: false,
                    }}
                  />
                </div>
              </div>
            ) : member?.memberLoading ? (
              <div className="sweet-loading">
                <PulseLoader
                  css={override}
                  // size={50}
                  color={"#02a17c"}
                  loading={member?.memberLoading}
                />
              </div>
            ) : (
              <div className="row row-height" style={{height: "auto"}}>
                <div className="col-md-12 col-sm-12 col-lg-6 chart-table">
                  <Table className="table-height">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time Tracked</th>
                        <th>Rates($)</th>
                        <th>Total Earnings($)</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>{showTableData()}</tbody>
                  </Table>
                </div>
                <div className="col-md-12 col-sm-12 col-lg-6 pl-0 timeline-chart abc" style={{top: "50px"}}>
                  {newtableData.length > 0 ? (
                    <Chart
                      chartType="Timeline"
                      data={newtableData}
                      width="100%"
                      height="100%"
                      options={{
                        timeline: {
                          showRowLabels: false,
                          singleColor: "#8d8",
                        },
                        backgroundColor: "#fff",
                        alternatingRowStyle: false,
                      }}
                    />
                  ) : (
                    <div className="sweet-loading">
                      <PulseLoader
                        css={override}
                        // size={50}
                        color={"#02a17c"}
                        loading={loading}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <Chart
              className="table-hidden"
              chartType="Timeline"
              data={newtableData}
              width="0px"
              height="0px"
              options={{
                timeline: {
                  singleColor: "#8d8",
                  rowLabelStyle: {
                    fontName: "Helvetica",
                    fontSize: 0.1,
                    color: "#603913",
                  },
                },
              }}
            />
          </div>
        </Card>
      </Container>
      <Container className="trackMain mt-2" fluid>
        <Row>
          <Col lg={6} sm={12} md={12} className="pr-lg-1">
            <HoursTracked weekData={weekData} />
          </Col>
          <Col className="idle" lg={6} sm={12} md={12}>
            <IdleTimeCard weekData={weekData} />
          </Col>
        </Row>
      </Container>
      <Container className="mt-2" fluid>
        <Row>
          <Col>
            <ProductivityPercentage weekData={weekData} />
          </Col>
        </Row>
      </Container>
      {/* <Container className="mt-2" fluid>
        <Row className="d-flex justify-content-center">
          <Col md={12} sm={12}>
            <TopProjectCard
              top={member.topProjects}
              projects={team.projects}
              days={member?.days}
              memberLoading={member?.memberLoading}
              data={member.topProjects}
            />
          </Col>
        </Row>
      </Container> */}
      <Container className="mt-2" fluid>
        <ProjectTable data={member.topProjects} projects={team.projects}/>
      </Container>
    </>
  );
};

DashboardIndividual.propTypes = {
  loading: PropTypes.bool,
  teamLoading: PropTypes.bool,
  profileID: PropTypes.string,
  selectedDate: PropTypes.string,
  dispatch: PropTypes.func,
  timeData: PropTypes.object,
  timelogDayRange: PropTypes.number,
  selectedTeamID: PropTypes.string,
  week: PropTypes.string,
  weekData: PropTypes.object,
  tableData: PropTypes.instanceOf(Array),
};

DashboardIndividual.defaultProps = {
  loading: false,
  teamLoading: false,
  profileID: "",
  selectedDate: moment().format("DD:M:YYYY"),
  week: moment().isoWeek().toString(),
  dispatch: () => {},
  timeData: {},
  timelogDayRange: 0,
  selectedTeamID: null,
  weekData: {},
  tableData: [],
  days: 0,
  start: "",
  end: "",
};
export default DashboardIndividual;
