import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyDigXnnQOeE0NnIQd7s07C9_Y4Jfhylznw",
    authDomain: "workfh-b2cee.firebaseapp.com",
    databaseURL: "https://workfh-b2cee.firebaseio.com",
    projectId: "workfh-b2cee",
    storageBucket: "workfh-b2cee.appspot.com",
    messagingSenderId: "680390938086",
    appId: "1:680390938086:web:12955103859cd9fd091a27",
    measurementId: "G-7VFV1HBCG8",
};

firebase.initializeApp(firebaseConfig);

export default firebase;