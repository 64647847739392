import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import teamReducer from "./teamReducer";
import manageTeamReducer from "./manage-teams/index";
import InvitationReducers from "./invitation/index";
import memberReducer from "./memberReducer";
import taskReducers from "./tasks";
import imageUploadReducer from "./ckEditor";


const rootReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: authReducer,
    team: teamReducer,
    allTasks: taskReducers,
    member: memberReducer,
    manageTeams: manageTeamReducer,
    invitation: InvitationReducers,
    taskImage: imageUploadReducer
  });

export default rootReducers;
