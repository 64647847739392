import React, { useEffect, useState } from 'react'
import { CheckPresent } from './checkPresent';
import { CheckAbsent } from './checkAbsent';
import { WorkingHours } from './workingHours';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';


export default function ShowTable(props) {
    const { dates, teamMembers, data, threshold } = props;

    const [workingHoursData, setWorkingHoursData] = useState([])
    const [workModalData, setWorktModalData] = useState([]);
    const [workModal, setWorkModal] = useState(false);

    const [presentData, setPresentData] = useState([])
    const [presentModal, setPresentModal] = useState(false);
    const [presentModalData, setPresentModalData] = useState([]);

    const [absentData, setAbsentData] = useState([])
    const [absentModalData, setAbsentModalData] = useState([]);
    const [absentModal, setAbsentModal] = useState(false);


    useEffect(() => {
        if (dates !== undefined && teamMembers !== undefined)
            setPresentData(CheckPresent(data, teamMembers));
        setAbsentData(CheckAbsent(data, teamMembers));
        setWorkingHoursData(WorkingHours(data, teamMembers, threshold));
    }, [data, dates])

    const presentToggle = (presents) => {
        setPresentModal(!presentModal);
        setPresentModalData(presents)
    }

    const absentToggle = (absents) => {
        setAbsentModal(!absentModal);
        setAbsentModalData(absents)
    }

    const workToggle = (works) => {
        setWorkModal(!workModal);
        setWorktModalData(works)
    }
    const showPresentModal = () =>
    (
        <>
            {presentModalData.length > 0 ?
                <ModalBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                presentModalData.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    {item.userName}
                                                </td>
                                                <td>
                                                    {item.userEmailid}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                :
                null
            }
        </>
    )

    const showAbsentModal = () =>
    (
        <>
            {absentModalData.length > 0 ?
                <ModalBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                absentModalData.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    {item.userName}
                                                </td>
                                                <td>
                                                    {item.userEmailid}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                :
                null
            }
        </>

    )

    const showWorkModal = () =>
    (
        <>
            {workModalData.length > 0 ?
                <ModalBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                workModalData.map((item, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    {i + 1}
                                                </td>
                                                <td>
                                                    {item.userName}
                                                </td>
                                                <td>
                                                    {item.userEmailid}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                :
                null
            }
        </>

    )


    const dateToShow = () =>
    (
        <>
            {
                dates.map((item, i) =>
                    <tr>
                        <td>{`${i + 1}`}</td>
                        <td>{item}</td>
                        <td className={presentData.presentMembers[i] !== "-" && presentData.dataToShow[i] !== 0 ?
                            "showPointer"
                            :
                            null
                        } onClick={() => {
                            if (presentData.presentMembers[i] !== "-" && presentData.dataToShow[i] !== 0)
                                presentToggle(presentData.presentMembers[i])
                        }}>
                            {presentData.dataToShow[i]}
                        </td>

                        <td className={absentData.absentMembers[i] !== "-" && absentData.dataToShow[i] !== 0 ?
                            "showPointer"
                            :
                            null
                        } onClick={() => {
                            if (absentData.absentMembers[i] !== "-" && absentData.dataToShow[i] !== 0)
                                absentToggle(absentData.absentMembers[i])
                        }}>
                            {absentData.dataToShow[i]}
                        </td>

                        <td className={workingHoursData.workMembers[i] !== "-" && workingHoursData.dataToShow[i] !== 0 ?
                            "showPointer"
                            :
                            null
                        } onClick={() => {
                            if (workingHoursData.workMembers[i] !== "-" && workingHoursData.dataToShow[i] !== 0)
                                workToggle(workingHoursData.workMembers[i])
                        }}>
                            {workingHoursData.dataToShow[i]}
                        </td>
                    </tr>
                )
            }
        </>
    )

    return (
        <>
            {
                presentData.dataToShow || absentData.dataToShow !== undefined ?
                    dateToShow()
                    :
                    null
            }
            <>
                <Modal isOpen={presentModal} toggle={presentToggle}>
                    <ModalHeader toggle={presentToggle}>Present Members</ModalHeader>
                    {presentData.presentMembers !== undefined ? showPresentModal() : null}
                </Modal>

                <Modal isOpen={absentModal} toggle={absentToggle}>
                    <ModalHeader toggle={absentToggle}>Absent Members</ModalHeader>
                    {absentData.absentMembers !== undefined ? showAbsentModal() : null}
                </Modal>

                <Modal isOpen={workModal} toggle={workToggle}>
                    <ModalHeader toggle={workToggle}>Working Hours Not Completed</ModalHeader>
                    {workingHoursData.workMembers !== undefined ? showWorkModal() : null}
                </Modal>
            </>
        </>
    )
}

