import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
  Table,
  Button,
} from "reactstrap";
import _, { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { css } from "@emotion/core";
import "./team_attendance.css";
import moment from "moment";

import GridLoader from "react-spinners/GridLoader";
import ShowTable from "./showTable";

const TeamAttendance = ({
  active,
  threshold,
  loading,
  selectedTeamID,
  attendanceData,
  teamMembers,
}) => {
  const [lastDate, setLastDate] = useState(5);
  const [dateToShow, setDateToShow] = useState([]);
  const [oldAttendanceData, setOldAttendanceData] = useState([]);
  let d = 5;

  const override = css`
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 100px;
    border-color: green;
  `;
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.auth.profile);

  console.log(threshold,"threshold in TeamAttendance");
  useEffect(() => {
    if (userProfile?.activeCompany !== undefined) {
      dispatch({
        type: "team/GET_THRESHOLD",
        payload: {
          companyId: !_.isEmpty(userProfile?.activeCompany)
            ? userProfile?.activeCompany?.companyId
            : "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threshold]);

  useEffect(() => {
    if (!isEmpty(active)) {
      let date = d;
      let newDate = [];
      for (let n = 0; n <= date; n++) {
        newDate.push(moment().subtract(n, "days").format("D:M:YYYY"));
      }
      setDateToShow(newDate);
      setLastDate(lastDate + d);
      showTimeTable();
      dispatch({
        type: "team/GET_TIME_DATA",
        payload: {
          selectedTeamID: active?.activeTeamid,
          days: newDate,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    setOldAttendanceData((prevState) => [...prevState, attendanceData]);
  }, [attendanceData]);

  useEffect(() => {
    // unmount
    return () => {
      dispatch({
        type: "team/SET_STATE",
        payload: {
          attendanceData: [],
        },
      });
    };
  }, []);

  const showMore = () => {
    let date = lastDate;
    setLastDate(lastDate + d);

    let newDate = [];
    for (let n = 0; n <= date; n++) {
      newDate.push(moment().subtract(n, "days").format("D:M:YYYY"));
    }
    dispatch({
      type: "team/GET_TIME_DATA",
      payload: {
        selectedTeamID: active?.activeTeamid,
        days: newDate.slice(date - (d - 1), date + 1),
      },
    });
    setDateToShow(newDate);
  };

  const showTimeTable = () => {
    const attendanceToSend = Object.values(oldAttendanceData.flat());
    console.log(attendanceToSend,"attendanceToSend");
    return (
      <>
        <ShowTable
          threshold={threshold}
          dates={dateToShow}
          data={attendanceToSend}
          teamMembers={JSON.parse(localStorage.getItem("members"))}
        />
      </>
    );
  };

  return (
    <>
      <Container className="pt-md-2" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="m-0 justify-content-between">
                  <Col xs="12" className="m-0 p-0">
                    <div>
                      <h3>Team Attenance</h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              {userProfile?.companyList?.length === 0 ? (
                <CardBody>
                  {" "}
                  <h4 className="text-danger">
                    Please create a team to view attendence!
                  </h4>
                </CardBody>
              ) : (
                <CardBody>
                  {loading ? (
                    <GridLoader
                      css={override}
                      size={20}
                      color={"#02a17c"}
                      loading={loading}
                    />
                  ) : (
                    <Table striped hover responsive borderless>
                      <thead>
                        <tr role="row">
                          <th className="second-col">Sr No.</th>
                          <th className="second-col">Date</th>
                          <th className="second-col">Present</th>
                          <th className="second-col">Absent</th>
                          <th className="second-col">Working Hrs. Not Completed</th>
                        </tr>
                      </thead>
                      <tbody className="teamTable">
                        {dateToShow !== null ? showTimeTable() : null}
                      </tbody>
                    </Table>
                  )}
                  <Button
                    className="mt-3"
                    color="info"
                    size="sm"
                    onClick={showMore}
                  >
                    Show More
                  </Button>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

TeamAttendance.propTypes = {
  threshold: PropTypes.string,
  loading: PropTypes.bool,
  selectedTeamID: PropTypes.string,
  attendanceData: PropTypes.array,
  teamMembers: PropTypes.array,
};

TeamAttendance.defaultProps = {
  threshold: "",
  loading: true,
  selectedTeamID: "",
  attendanceData: [],
  teamMembers: [],
};

export default TeamAttendance;
