import moment from "moment";
import firebase from "../config";
import map from "lodash/map";
import { reject } from "lodash";
const database = firebase.firestore();

function getMemberTimes(teamId, memberId, day) {
  // console.log(memberId, teamId);
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("daysData")
    .doc(memberId)
    .collection("dateId")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        console.log(data,"MEMBER DATA ");
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}
function getMemberTableData(teamId, memberId, day) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("tableData")
    .doc(memberId)
    .collection("dateId")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: { data, day },
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}
function getMemberWeekTimes(teamId, memberId, week) {
  const year = moment().isoWeekYear();
  week = `${week}-${year}`
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("weeksData")
    .doc(memberId)
    .collection("weekId")
    .doc(week)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getMemberTimeData(teamId, memberId, day) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("daysData")
    .doc(memberId)
    .collection("dateId")
    .doc(day)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getMemberWeekTimeData(teamId, memberId, week) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("lastWeekData")
    .doc(memberId)
    .collection("weekId")
    .doc(week)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getMemberMonthTimeData(teamId, memberId, month) {
  return database
    .collection("memberData")
    .doc(teamId)
    .collection("lastMonthData")
    .doc(memberId)
    .collection("monthId")
    .doc(month)
    .get()
    .then((response) => {
      let data;
      if (response.exists) {
        const docData = response.data();
        data = docData;
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          data: data,
        };
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error,
      };
    });
}

async function getDateRangeTimeDataMember(start, end, teamId, userId) {
  console.log(start, end, teamId, "getDateRangeTimeDataMember");
  try {
    const url = `https://us-central1-workfh-b2cee.cloudfunctions.net/dateRangeForMember`;
    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=utf-8");
    headers.append("Access-Control-Allow-Origin", "*");

    return await fetch(url, {
      headers,
      method: "POST",
      body: JSON.stringify({
        start,
        end,
        teamId,
        userId
      }),
    })
      .then((r) => r.json())
      .then((res) => {
        console.log("data in getDateRangeTimeDataMember", res.result);
        let data;
        if (res.success) {
          data = res.result
          console.log("data in success", data);
          return {
            success: true,
            data: res.result,
          };
        } else {
          return {
            success: false,
            data: res.message,
          };
        }
      })
      .catch((error) => {
        return {
          success: false,
          data: error,
        };
      });
  } catch (err) {
    console.log(err);
  }
}

//   async function getMemberTimelog

async function getMemberTimeLog(teamID) {
  const snapshot = await database
    .collection("teamDatabase")
    .doc(teamID)
    .collection("members")
    .get();

  let data = [];
  if (!snapshot.empty) {
    const promiseAr = [];

    snapshot.forEach((doc) => {
      promiseAr.push(
        database
          .collection("teamDatabase")
          .doc(teamID)
          .collection("members")
          .doc(doc.id)
          .collection("days")
          .get()
      );
    });

    const aa = await Promise.all(promiseAr);

    aa.forEach((res) => {
      res.forEach((d) => {
        data.push({
          ...d.data(),
          date: d.id,
        });
      });
    });
    return {
      success: true,
      data,
    };
  } else {
    return { success: true, data: [] };
  }
}

export default {
  getMemberTimes,
  getMemberWeekTimes,
  getMemberTableData,
  getMemberTimeData,
  getMemberWeekTimeData,
  getMemberMonthTimeData,
  getMemberTimeLog,
  getDateRangeTimeDataMember,
};
