import React from 'react';
import { Card, CardHeader, CardBody, Col, Alert } from 'reactstrap';
import AddCompanyForm from './form';
import { Link } from 'react-router-dom';

export default function AddCompany() {
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary border-0">
          <CardHeader className="bg-transparent p-0 m-0 border-0">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>Create a company</h2>
              <small>Add your company details.</small>
            </div>
          </CardHeader>
          {/* <CardBody className="px-lg-5 py-lg-4"> */}
          <AddCompanyForm />
          {/* </CardBody> */}
        </Card>
      </Col>
    </>
  );
}
