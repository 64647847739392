import React, { useEffect, useState, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SpeedDial } from "primereact/speeddial";
import _, { debounce } from "lodash";
import firebase from "../../../config";
import { Tooltip } from "primereact/tooltip";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {
  addNotification,
  notificationStates,
} from "../../../components/Notification";
import { Alert } from "reactstrap";
import { TiWarning } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "./../../../hooks/userPrevious";
import { Row } from "reactstrap";
import { useLayoutEffect } from "react";

import { RiMore2Fill } from "react-icons/ri";
import { InputText } from "primereact/inputtext";
import transitions from "@material-ui/core/styles/transitions";

export default function Teams({ handleTeamAction }) {
  /**
   * Declarations
   */
  const database = firebase.firestore();
  const dispatch = useDispatch();
  const teamState = useSelector((state) => ({ ...state.team }));
  const authState = useSelector((state) => ({ ...state.auth }));
  const allTeamsState = useSelector((state) => ({
    ...state.manageTeams.teams,
  }));
  let prevTeams = usePrevious(teamState?.teams);

  const [expandedRows, setExpandedRows] = useState(null);
  const [searchQuery, setQuery] = useState();
  const [filteredData, setFilteredData] = useState();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({});
  const [team, setTeam] = useState({});

  let currentTeams = teamState?.teams;
  let { success, pending, failure, successPayload, failurePayload } =
    allTeamsState;
  const teams = successPayload ? successPayload.data : [];
  const rawData = currentTeams?.data?.filter((item, index) =>
    ["Manager", "Admin", "Head", "Member"].includes(item.Role)
  );
  const authUserRole = authState?.profile?.role;
  const isMember = !["Admin", "Manager", "Head"].includes(authUserRole);
  const { activeCompany } = useSelector((state) => state.auth.profile);
  const [loader, setLoader] = useState(false);

  let teamsTableData = _.isEmpty(searchQuery) ? teams : filteredData;

  // @@ Debounce Search Query
  const delayedQuery = useCallback(
    debounce((qr) => handleFilter(qr), 700),
    [searchQuery, teams]
  );

  /**
   * Life Cycle Hooks
   */

  useEffect(() => {
    if (prevTeams !== currentTeams) {
      let finalizeData = rawData?.map((itm) => ({
        label: itm.Team,
        value: itm.activeTeamid,
      }));
      fetchTeams(finalizeData);
    }
  }, [teamState]);

  useEffect(() => {
    delayedQuery(searchQuery);
  }, [searchQuery]);

  /**
   * Component functions
   */

  function fetchTeams(selectedTeams) {
    if (selectedTeams !== undefined) {
      if (selectedTeams?.length > 0) {
        dispatch({
          type: "teams/GET_TEAMS",
          payload: {
            teams: [...selectedTeams],
            authUser: authState?.profile?.id,
            role: authState?.profile?.role,
          },
        });
      }
    }
  }

  function handleFilter(sQuery) {
    let result = [];
    if (!_.isEmpty(teams) && !_.isEmpty(sQuery)) {
      let smQ = sQuery.toLowerCase();
      result = teams.filter((tm) => tm.teamName.toLowerCase().includes(smQ));
    }
    setFilteredData([...result]);
  }

  // Open delete modal
  const toggle = () => {
    setModal(!modal);
  };

  // Delete member
  const handleDelete = (data) => {
    setData(data);
    toggle();
  };

  // Confirm member removal
  const handleConfirm = () => {
    setLoader(true);
    let user = {};
    let previousData = {};
    let prevMemData = {};
    return database
      .runTransaction(async (transaction) => {
        const teamRef = database.collection("teamDatabase").doc(team?.team);
        const memRef = database
          .collection("membersDatabase")
          .doc(data?.userUID)
          .collection("companyList")
          .doc(activeCompany?.companyId)
          .collection("teamList")
          .doc(team?.team);
        await transaction?.get(teamRef).then((response) => {
          const res = response.data();
          previousData = res?.memberDetails;
          if (res?.memberDetails[data?.userUID]) {
            user = { ...res?.memberDetails[data?.userUID], deleted: true };
            previousData = { ...previousData, [data?.userUID]: user };
          }
        });
        await transaction?.get(memRef).then((response) => {
          const res = response.data();
          prevMemData = res;
          prevMemData = { ...prevMemData, deleted: true };
        });
        await transaction?.update(teamRef, {
          memberDetails: previousData,
        });
        await transaction?.update(memRef, prevMemData);
      })
      .then((res) => {
        setLoader(false);
        toggle();
        addNotification({
          message: "Member removed successfully",
          level: notificationStates.success,
        });

        let finalizeData = rawData?.map((itm) => ({
          label: itm.Team,
          value: itm.activeTeamid,
        }));
        fetchTeams(finalizeData);
      })
      .catch((error) => {
        addNotification({
          message: "Error removing member",
          level: notificationStates.error,
        });
        return error;
      });
  };

  /**
   * Datatable Templates
   */
  const expandedRowTemplate = (rowData) => {
    setTeam(rowData);
    return (
      <div className="d-flex flex-column">
        {rowData.members.length > 0 ? (
          rowData.members.map((member) => {
            return (
              <Row className="col-12 py-2 text-md d-flex justify-content-between px-5">
                <div className="text-center text-md-left">
                  <span>{member.userName} </span>
                  &nbsp;&nbsp;
                  <span className="font-italic text-muted">
                    ({member.userType})
                  </span>
                </div>
                <div className="text-center text-md-right">
                  {teamState?.role === "Admin" && member?.role !== "Admin" && (
                    <Button onClick={() => handleDelete(member)} color="danger">
                      Delete
                    </Button>
                  )}
                </div>
              </Row>
            );
          })
        ) : (
          <Row
            className="col-12 py-2 text-md text-default"
            style={{ paddingLeft: "5rem" }}
          >
            <span>
              {" "}
              It does not have any members. To add new members edit the team.
            </span>
          </Row>
        )}
        <a href="/manage-teams/invite">Add team members</a>
      </div>
    );
  };

  const teamsBodyTemplate = (data) => <span>{data.teamName}</span>;

  const actionTemplate = (data) => {
    return (
      <UncontrolledButtonDropdown className="">
        <DropdownToggle className="border-0 shadow-none bg-transparent">
          <RiMore2Fill color="primary" size={19} />
        </DropdownToggle>
        <DropdownMenu style={{ boxShadow: "0 2px 6px 0 #d5d0d7" }}>
          <DropdownItem onClick={() => handleTeamAction({ ...data }, "edit")}>
            Edit
          </DropdownItem>
          <DropdownItem
            disabled={!["Head", "Admin"].includes(authUserRole)}
            onClick={() => handleTeamAction({ ...data }, "delete")}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    );
  };

  for (let i = 0; i < teamsTableData?.length; i++) {
    if (teamState?.selectedTeamID === teamsTableData[i]?.team) {
      if (
        teamsTableData[i]?.members?.findIndex(
          (obj) => obj.userUID === teamState?.userId
        ) === -1
      ) {
        window.location = "/select-company";
      }
    }
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="mod">
        <ModalHeader toggle={toggle}>Delete Member</ModalHeader>
        <ModalBody className="pl-4">
          Are you really sure, you want to delete this member from team?
          <br />
          <Alert
            color="warning"
            style={{
              color: "#664d03",
              backgroundColor: "#fff3cd",
              borderColor: "#ffecb5",

              marginRight: "30px",
              marginTop: "4px",
            }}
          >
            <h6 className="alert-heading">
              <TiWarning style={{ color: "#f8a125" }} /> This action cannot be
              reversed
            </h6>
            <hr className="m-0" />
            <p className="mb-0">
              This Member will no longer have access to this Team.
            </p>
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirm}>
            {loader ? (
              <>
                <Spinner size="sm" />
                <span>Delete</span>
              </>
            ) : (
              <span>Delete</span>
            )}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex justify-content-end mb-3">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm p-inputtext"
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search here..."
          />
        </span>
      </div>
      <DataTable
        value={teamsTableData}
        expandedRows={expandedRows}
        rowHover
        loading={pending}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={expandedRowTemplate}
        responsiveLayout="scroll"
        className="teamsTable task-page-table"
        header="All Teams / Members"
      >
        <Column expander className="teamTableExpander" />
        <Column
          headerClassName="text-uppercase"
          bodyClassName="text-dark text-capitalize font-italic"
          field="userName"
          body={teamsBodyTemplate}
        ></Column>
        {!isMember && (
          <Column
            headerStyle={{ width: "9rem", textAlign: "center" }}
            body={actionTemplate}
            className="teamTableDots"
          />
        )}
      </DataTable>
    </>
  );
}
