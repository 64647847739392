import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import Routes from "../routes";
import Notification from "../components/Notification";
import { QueryClient, QueryClientProvider } from "react-query";


const Root = ({ store }) => {
  return (
    <QueryClientProvider client={new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false }}})}>
      <Provider store={store.store}>
        <Routes history={store.history} />
        <Notification />
      </Provider>
    </QueryClientProvider>
  );
};

Root.propTypes = {
  store: PropTypes.objectOf(PropTypes.any)
};

Root.defaultProps = {
  store: {}
};

export default Root;
