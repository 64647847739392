import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Alert,
} from "reactstrap";
import SignUpForm from "./form";
import AppLogo from './../../../assets/img/brand/logo.png';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router';
import queryString from 'query-string';

const SignUp = () => {


    const location = useLocation()
    const { i: invitationId } = queryString.parse(location.search)
    const invitationData = JSON.parse(localStorage.getItem('pendingInvitation'))



    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent border-0">
                        <div className="text-center">
                            <img className="auth-app-logo" src={AppLogo} alt="workfromhome" />
                        </div>

                        <div className="text-muted text-center">
                            <h2>Sign Up</h2>
                            <small>Create your account</small>
                        </div>
                        {(invitationId && (invitationId == invitationData?.invitationId && !invitationData?.userDetails?.registered))
                            && <div className="mt-3">
                                <Alert color="primary">
                                    <p className="mb-0 text-sm">
                                        You have been invited to join the {' '}
                                        <b className="font-weight-bold text-dark">{invitationData?.invitedBy?.fullName}'s</b> {' '}
                                        {invitationData?.invitedAs == 'Admin'
                                            ? <>company <b className="font-weight-bold text-dark">{invitationData?.invitedBy?.companyDetails?.companyName} ({invitationData?.invitedAs})</b></>
                                            : <>team <b className="font-weight-bold text-dark">{invitationData?.invitedTo?.teamName} ({invitationData?.invitedAs})</b></>}.
                                        Please Sign Up, to accept the invitation.
                                    </p>
                                </Alert>
                            </div>}
                    </CardHeader>
                    <CardBody className="px-lg-5 pb-lg-5 pt-lg-1">
                        <SignUpForm />
                    </CardBody>
                </Card>
                <div class="pb-5 pt-2 text-center">
                    <small className="text-white">Already have an account? <Link to={'/auth/login'}>Login here.</Link></small>
                </div>
            </Col>
        </>
    );
};

export default SignUp;
