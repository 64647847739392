import types from "../../actions/allActions"

const initialState = {
    pending: false,
    success: false,
    failure: false,
    successPayload: {},
    failurePayload: {},
}

const acceptInvitationDetails = (state = initialState, action) => {
    switch (action.type) {
        case types.ACCEPT_INVITATION_PENDING:
            return {
                ...state,
                pending: true,
                success: false,
                failure: false,
                successPayload: {},
                failurePayload: {},
            }
        case types.ACCEPT_INVITATION_SUCCESS:
            return {
                ...state,
                pending: false,
                success: true,
                failure: false,
                successPayload: action.payload,
                failurePayload: {},
            }
        case types.ACCEPT_INVITATION_FAILED:
            return {
                ...state,
                pending: false,
                success: false,
                failure: true,
                successPayload: {},
                failurePayload: action.payload,
            }
        case types.ACCEPT_INVITATION_CLEANUP:
            return {
                ...state,
                pending: false,
                success: false,
                failure: false,
                successPayload: {},
                failurePayload: {},
            }
        default:
            return {
                ...state
            }
    }
}

export default acceptInvitationDetails