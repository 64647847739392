import types from "../../../actions/allActions";

const initialState = {
  pending: false,
  success: false,
  failure: false,
  successPayload: {},
  failurePayload: {},
};

const getTeamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TEAMS_PENDING:
      return {
        ...state,
        pending: true,
        success: false,
        failure: false,
        successPayload: {},
        failurePayload: {},
      };
    case types.GET_TEAMS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
        failure: false,
        successPayload: action.payload,
        failurePayload: {},
      };
    case types.GET_TEAMS_FAILED:
      return {
        ...state,
        pending: false,
        success: false,
        failure: true,
        successPayload: {},
        failurePayload: action.payload,
      };
    case types.GET_TEAMS_CLEANUP:
      return {
        ...state,
        pending: false,
        success: false,
        failure: false,
        successPayload: {},
        failurePayload: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default getTeamsReducer;
