import React, { Component } from "react";
import { Card, Row } from "reactstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Popover from "@material-ui/core/Popover";
import isUndefined from "lodash/isUndefined";
class ProductivityPercentage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }

  productivityPercentages = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };

  trendsProductivityPercentages() {
    let Tuesday = this.props?.weekData?.Tuesday;

    let Monday = this.props?.weekData?.Monday;

    let Wednesday = this.props?.weekData?.Wednesday;

    let Thursday = this.props?.weekData?.Thursday;

    let Friday = this.props?.weekData?.Friday;

    let Saturday = this.props?.weekData?.Saturday;

    let Sunday = this.props?.weekData?.Sunday;

    var chart = am4core.create(
      "trends_productivity_percentages",
      am4charts.XYChart
    );

    let productivePercentageForChart = (day) => {
      return !isUndefined(day?.productiveHours)
        ? (
            ((parseInt(day.productiveHours) * 60 +
              +day.productiveHours.toFixed(2).toString().split(".")[1]) *
              100) /
            (parseInt(day.totalHours) * 60 +
              +day.totalHours.toFixed(2).toString().split(".")[1])
          ).toFixed(2)
        : 0;
    };
    let idlePercentageForChart = (day) => {
      return !isUndefined(day?.idleHours)
        ? (
            ((parseInt(day.idleHours) * 60 +
              +day.idleHours.toFixed(2).toString().split(".")[1]) *
              100) /
            (parseInt(day.totalHours) * 60 +
              +day.totalHours.toFixed(2).toString().split(".")[1])
          ).toFixed(2)
        : 0;
    };

    chart.data = [
      {
        days: "Mon",
        value1: !isUndefined(Monday) ? Monday.productiveHours : 0,
        value2: !isUndefined(Monday) ? Monday.idleHours : 0,
        productivePercentage: !isNaN(productivePercentageForChart(Monday))
          ? productivePercentageForChart(Monday)
          : 0,
        idlePercentage: !isNaN(idlePercentageForChart(Monday))
          ? idlePercentageForChart(Monday)
          : 0,
      },
      {
        days: "Tue",
        value1: !isUndefined(Tuesday) ? Tuesday.productiveHours : 0,
        value2: !isUndefined(Tuesday) ? Tuesday.idleHours : 0,
        productivePercentage: !isNaN(productivePercentageForChart(Tuesday))
          ? productivePercentageForChart(Tuesday)
          : 0,
        idlePercentage: !isNaN(idlePercentageForChart(Tuesday))
          ? idlePercentageForChart(Tuesday)
          : 0,
      },
      {
        days: "Wed",
        value1: !isUndefined(Wednesday) ? Wednesday.productiveHours : 0,
        value2: !isUndefined(Wednesday) ? Wednesday.idleHours : 0,
        productivePercentage: !isNaN(productivePercentageForChart(Wednesday))
          ? productivePercentageForChart(Wednesday)
          : 0,
        idlePercentage: !isNaN(idlePercentageForChart(Wednesday))
          ? idlePercentageForChart(Wednesday)
          : 0,
      },
      {
        days: "Thu",
        value1: !isUndefined(Thursday) ? Thursday.productiveHours : 0,
        value2: !isUndefined(Thursday) ? Thursday.idleHours : 0,
        productivePercentage: !isNaN(productivePercentageForChart(Thursday))
          ? productivePercentageForChart(Thursday)
          : 0,
        idlePercentage: !isNaN(idlePercentageForChart(Thursday))
          ? idlePercentageForChart(Thursday)
          : 0,
      },
      {
        days: "Fri",
        value1: !isUndefined(Friday) ? Friday.productiveHours : 0,
        value2: !isUndefined(Friday) ? Friday.idleHours : 0,
        productivePercentage: !isNaN(productivePercentageForChart(Friday))
          ? productivePercentageForChart(Friday)
          : 0,
        idlePercentage: !isNaN(idlePercentageForChart(Friday))
          ? idlePercentageForChart(Friday)
          : 0,
      },
      {
        days: "Sat",
        value1: !isUndefined(Saturday) ? Saturday.productiveHours : 0,
        value2: !isUndefined(Saturday) ? Saturday.idleHours : 0,
        productivePercentage: !isNaN(productivePercentageForChart(Saturday))
          ? productivePercentageForChart(Saturday)
          : 0,
        idlePercentage: !isNaN(idlePercentageForChart(Saturday))
          ? idlePercentageForChart(Saturday)
          : 0,
      },
      {
        days: "Sun",
        value1: !isUndefined(Sunday) ? Sunday.productiveHours : 0,
        value2: !isUndefined(Sunday) ? Sunday.idleHours : 0,
        productivePercentage: !isNaN(productivePercentageForChart(Sunday))
          ? productivePercentageForChart(Sunday)
          : 0,
        idlePercentage: !isNaN(idlePercentageForChart(Sunday))
          ? idlePercentageForChart(Sunday)
          : 0,
      },
    ];

    chart.colors.list = [am4core.color("#27c26c"), am4core.color("#ff0101")];
    // chart.padding(30, 30, 10, 30);
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "days";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.width = am4core.percent(30);
    series.dataFields.valueY = "value1";
    series.dataFields.categoryX = "days";
    series.name = "Productive Hours";
    series.dataFields.valueYShow = "totalPercent";
    // series.tooltipText =
    //   "{name}: {valueY.totalPercent.formatNumber('#.00')}% Day: {days}";
    series.tooltipText = "{name}: {productivePercentage}% Day: {days}";
    series.stacked = true;

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(30);
    series2.dataFields.valueY = "value2";
    series2.dataFields.categoryX = "days";
    series2.dataFields.valueYShow = "totalPercent";
    series2.name = "Idle Hours";
    // series2.tooltipText =
    //   "{name}: {valueY.totalPercent.formatNumber('#.00')}% Day: {days}";
    series2.tooltipText = "{name}: {idlePercentage}% Day: {days}";
    series2.stacked = true;
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  }

  componentWillReceiveProps() {
    this.trendsProductivityPercentages();
  }
  render() {
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Card className="shadow p-3">
          <div style={{ height: `auto` }} className="p--1">
            <Row>
              <div className="col">
                <span className="headline">Productivity percentages</span>
              </div>
            </Row>

            <Row className="dashboard-section-body">
              <div className="col">
                <div id="trends_productivity_percentages"></div>
              </div>
            </Row>
          </div>
        </Card>
      </>
    );
  }
}

export default ProductivityPercentage;
