import {
    all,
    takeEvery,
    takeLatest,
    put,
    call,
} from "redux-saga/effects";
import { push } from 'connected-react-router';
import actions from "../actions/allActions";
import service from '../services/invitation';
import firebase from "../config";
import { addNotification, notificationStates } from "../components/Notification";
const database = firebase.firestore();

export function* GET_INVITATION_DETAILS({ payload }) {
    yield put({ type: "user/GET_INVITATION_DETAILS_PENDING" })
    try {

        const { invitationId } = payload;

        const response = yield call(service.getInvitationDetails, invitationId)


        if (response.success) {
            yield put({ type: "user/GET_INVITATION_DETAILS_SUCCESS", payload: response.data })
        } else {
            yield put({ type: "user/GET_INVITATION_DETAILS_FAILED", payload: response })
        }

    } catch (e) {
        yield put({ type: "user/GET_INVITATION_DETAILS_FAILED", payload: e })
    }
}

export function* ACCEPT_INVITATION({ payload }) {
    yield put({ type: "user/ACCEPT_INVITATION_PENDING" })
    try {
        const response = yield call(service.acceptInvitation, { ...payload })
        if (response.success) {
            yield put({ type: "user/ACCEPT_INVITATION_SUCCESS", payload: response.data })
            addNotification({
                message: "Invite Accepted!",
                level: notificationStates.success
            });
            yield put(push('/'))
        } else {
            yield put({ type: "user/ACCEPT_INVITATION_FAILED", payload: response })
            addNotification({
                message: "Something went wrong! Please try again later.",
                level: notificationStates.error
            });
        }
        yield put({ type: "user/ACCEPT_INVITATION_CLEANUP" })

    } catch (e) {
        yield put({ type: "user/ACCEPT_INVITATION_FAILED", payload: e })
        yield put({ type: "user/ACCEPT_INVITATION_CLEANUP" })
        addNotification({
            message: "Something went wrong! Please try again later.",
            level: notificationStates.error
        });
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_INVITATION_DETAILS, GET_INVITATION_DETAILS),
        takeEvery(actions.ACCEPT_INVITATION, ACCEPT_INVITATION)
    ]);
}