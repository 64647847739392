import moment from "moment";
import actions from "../actions/Member";

const initialState = {
    loading: false,
    memberLoading: false,
    activeUserID: null,
    activeUserName :null,
    selectedDate: moment().format("DD:M:YYYY"),
    week: moment().isoWeek().toString(),
    timelogDayRange: 0,
    start: "",
    end: "",
    days: 0,
    selectMember: false,
    weekData: {},
    membersData: {},
    timeData: {},
    tableData: {},
    topProjects11: [],
    topProjects: [],
    memberLogs: [],
    all: true,
    memberTimeData: {},
    topData: {},
    untrackedUsers: {},
};

export default function memberReducer(state = initialState, action) {
    switch (action.type) {
        case actions.SET_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
