import React from 'react';
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import teamService from "../../../services/manageTeams"
import { TiWarning } from "react-icons/ti"
import { addNotification, notificationStates } from "../../../components/Notification";
import { Alert } from 'reactstrap';

export default function DeleteMemberDialog({ toggleDeleteMemberDialog, deleteMemberDialog, memberDetails }) {
    const dispatch = useDispatch()
    const authState = useSelector((state) => ({ ...state.auth }))
    let userProfile = authState?.profile
    let [requesting, setRequesting] = useState(false)

    const teamState = useSelector((state) => ({ ...state.team }))
    let currentTeams = teamState?.teams
    let rawData = currentTeams?.data?.filter((item, index) => (item.Role == "Manager" || item.Role == "Admin" || item.Role == "Head"))

    const handleDeleteMember = async () => {
        try {
            setRequesting(true)
            let response = await teamService.deleteMember({ ...memberDetails, companyId: userProfile?.activeCompany?.companyId })
            if (response.success) {
                dispatch({ type: "teams/GET_MEMBERS", payload: { teams: [...rawData] } })
                setRequesting(false)
                toggleDeleteMemberDialog(false)
                addNotification({ message: response.data, level: notificationStates.success });
            } else {
                addNotification({ message: response.data, level: notificationStates.error });
            }

        } catch (e) {
            setRequesting(false)
        }
    }

    return (
        <Dialog
            blockScroll
            draggable={false}
            header="Delete team member"
            contentClassName='rounded-bottom'
            visible={deleteMemberDialog}
            onHide={() => toggleDeleteMemberDialog(false)}
            style={{ width: '360px' }}
        >
            <div>
                <p>
                    Are you sure you want to delete <b style={{ fontWeight: 'bolder', color: 'black' }} className='text-capitalize'>{memberDetails?.userName}</b> from the Team?
                </p>
                <div>
                    <Alert color='warning' style={{ color: '#664d03', backgroundColor: '#fff3cd', borderColor: '#ffecb5', padding: '10px 15px' }}>
                        <h6 className="alert-heading">
                            <TiWarning style={{ color: '#f8a125' }} /> This action cannot be reversed
                        </h6>
                        <hr className='m-0' />
                        <p className="mb-0">
                            This Member will no longer have access to this Team and will be removed from all.
                        </p>
                    </Alert>
                </div>
                <div className='w-100 row m-0'>
                    <div className='col-6 pl-0 '>
                        <Button type='button' label="Cancel" onClick={() => toggleDeleteMemberDialog(false)} color='secondary' className="p-button-outlined p-button-secondary p-button-sm w-100" />
                    </div>
                    <div className='col-6 pr-0'>
                        <Button type='submit' loading={requesting} label="Delete" onClick={() => handleDeleteMember()} color='danger' className='p-button-sm p-button-danger w-100' />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}