import { all, takeEvery, takeLatest, put, call } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";
import { push } from "connected-react-router";
import actions from "../../actions/allActions";
import teamService from "../../services/team";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";
import firebase from "../../config";
const database = firebase.firestore();

// returns the finalize data
function getFinalizeAdminData(existedUsers, nonExistedEmails, teams, sender) {
  let finalAdminData = [];
  let finalInviteData = [];

  let nonExistAdmins = nonExistedEmails.map((neEmail) => ({
    email: neEmail,
    registered: false,
    eligibleFor: [...teams],
  }));

  finalAdminData = [...nonExistAdmins, ...existedUsers];

  const invitedTo = teams.map((tm) => ({
    teamId: tm.value,
    teamName: tm.label,
    invitedAs: "Admin",
  }));

  finalAdminData.map((ad) => {
    if (ad.eligibleFor.length > 0) {
      finalInviteData.push({
        invitedBy: {
          emailId: sender.email,
          fullName: sender.name,
          companyDetails: { ...sender.companyDetails },
        },
        invitedTo: [...invitedTo],
        invitedAs: "Admin",
        userDetails: {
          email: ad.email,
          registered: ad.registered,
        },
        accepted: false,
      });
    }
  });

  return [...finalInviteData];
}

export function* SEND_INVITE({ payload }) {
  yield put({ type: "manage-teams/SEND_INVITE_PENDING" });
  try {
    let { userType, emails, memberOfTeams, managingTeams, sender, projects } =
      payload;
    if (["Client"].includes(userType)) {
      let allEmails = emails.map((em) => em.toLowerCase());
      // Existed users on the platform
      let existedUsers = yield call(teamService.getAllMembers, allEmails);
      let existedEmails = existedUsers.map((usr) => usr.accountEmailid);
      // Non-existed users on the platform
      let nonExistedEmails = allEmails.filter(
        (em) => !existedEmails.includes(em)
      );
      // finalizes the data to add into the inviteDatabase
      let finalizeInvitationData = [];
      if (userType === "Client") {
        finalizeInvitationData = yield call(
          teamService.getFinalizedProjectInvitation,
          existedUsers,
          allEmails,
          projects,
          sender,
          userType
        );
      }
      if (finalizeInvitationData) {
        const sendInvitesResponse = yield call(
          [teamService, teamService.addInvitesToDatabase],
          finalizeInvitationData
        );
        if (sendInvitesResponse.success) {
          yield put({
            type: "manage-teams/SEND_INVITE_SUCCESS",
            payload: { success: true, data: "Invite sent successfully" },
          });
          addNotification({
            message: "Invites sent successfully",
            level: notificationStates.success,
          });
        } else {
          yield put({
            type: "manage-teams/SEND_INVITE_FAILED",
            payload: sendInvitesResponse,
          });
          addNotification({
            message: "Something went wrong! Please try later.",
            level: notificationStates.error,
          });
        }
      }
    } else if (["Admin", "Manager", "Member", "Head"].includes(userType)) {
      let allEmails = emails.map((em) => em.toLowerCase());

      // Existed users on the platform
      let existedUsers = yield call(teamService.getAllMembers, allEmails);
      let existedEmails = existedUsers.map((usr) => usr.accountEmailid);

      // Non-existed users on the platform
      let nonExistedEmails = allEmails.filter(
        (em) => !existedEmails.includes(em)
      );

      // Gets team data to verify eligibility of the new users
      let { success, data: teamsData } = yield call(
        teamService.getAllTeamMembers,
        {
          teams: ["Manager", "Head", "Admin"].includes(userType)
            ? managingTeams
            : memberOfTeams,
          authUser: sender?.userId,
          role: sender?.role,
        }
      );
      let teamsIDs = (teamsData ?? []).map((i) => ({
        teamId: i.team,
        teamName: i.teamName,
      }));
      // finds the existed users on the platform & eligible to join the team
      let existedEligibleUsersData = yield call(
        teamService.getExistedEligibleUsers,
        existedUsers,
        teamsData,
        teamsIDs,
        userType == "Admin"
      );
      // finalizes the data to add into the inviteDatabase
      let finalizeInvitationData = [];
      if (userType !== "Admin") {
        finalizeInvitationData = yield call(
          teamService.getFinalizeInvitationData,
          existedUsers,
          allEmails,
          teamsIDs,
          existedEligibleUsersData,
          sender,
          userType
        );
      } else {
        finalizeInvitationData = yield call(
          getFinalizeAdminData,
          existedEligibleUsersData,
          nonExistedEmails,
          managingTeams,
          sender
        );
      }

      if (finalizeInvitationData && existedEligibleUsersData) {
        const sendInvitesResponse = yield call(
          [teamService, teamService.addInvitesToDatabase],
          finalizeInvitationData
        );
        if (sendInvitesResponse.success) {
          yield put({
            type: "manage-teams/SEND_INVITE_SUCCESS",
            payload: { success: true, data: "Invite sent successfully" },
          });
          addNotification({
            message: "Invites sent successfully",
            level: notificationStates.success,
          });
        } else {
          yield put({
            type: "manage-teams/SEND_INVITE_FAILED",
            payload: sendInvitesResponse,
          });
          addNotification({
            message: "Something went wrong! Please try later.",
            level: notificationStates.error,
          });
        }
      }
    } else {
      yield put({
        type: "manage-teams/SEND_INVITE_FAILED",
        payload: {
          success: false,
          data: "Something went wrong! Please try later.",
        },
      });
      addNotification({
        message: "Something went wrong! Please try later.",
        level: notificationStates.error,
      });
      yield put({ type: "manage-teams/SEND_INVITE_CLEANUP" });
    }
  } catch (e) {
    addNotification({
      message: "Something went wrong! Please try later.",
      level: notificationStates.error,
    });
    yield put({ type: "manage-teams/SEND_INVITE_FAILED", payload: e });
  }
  yield put({ type: "manage-teams/SEND_INVITE_CLEANUP" });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SEND_INVITE, SEND_INVITE)]);
}
