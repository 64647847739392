/* eslint-disable no-loop-func */

export function WorkingHours(data, teamMembers, threshold) {
  let memberId = [];
  let userData = [];
  let dataToSend = [];
  let work = [];
  let works = [];

  console.log(data,"WorkingHours data");
  console.log(teamMembers,"WorkingHours teamMembers");
  console.log(threshold,"WorkingHours threshold");
  
  teamMembers.forEach((element) => {
    memberId.push(element.userUID);
  });
  data.forEach((element) => {
    if (element.success) {
      userData.push(element.data);
    } else {
      userData.push(null);
    }
  });
  console.log(memberId,"WorkingHours memberId");
  console.log(userData,"WorkingHours userData");
  userData.forEach((element, i) => {
    let temp = memberId.length;
    if (element === null) {
      dataToSend.push("-");
      works.push("-");
    } else {
      const obj = Object.keys(element);
      const a = teamMembers.filter((person) => !obj.includes(person.userUID));
      for (let i = 0; i < a.length; i++) {
        work.push(a[i]);
      }
      if (Object.keys(element).length === 0) {
        teamMembers.forEach((index) => {
          work.push(index);
        });
      }
      for (let key of Object.keys(element)) {
        if (memberId.includes(key)) {
          if (element[key].totalProductiveTime >= threshold) {
            temp = temp - 1;
          }
        }

        if (element[key].totalProductiveTime < threshold) {
          work.push(teamMembers.filter((person) => key === person.userUID)[0]);
        }
      }
      works[i] = work;
      work = [];
      dataToSend.push(temp);
    }
  });
  console.log(dataToSend,"WorkingHours dataToSend");
  return {
    dataToShow: dataToSend,
    workMembers: works,
  };
}
