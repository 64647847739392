import DashboardIndividual from '../views/Dashboard/individualDashboard'
import { connect } from "react-redux";

const mapStateToProps = state => ({
    loading: state.team.loading,
    teams: state.team.teams,
    teamLoading: state.team.teamLoading,
    profileID: state.auth.profile.id,
    activeUserID: state.team.activeUserID,
    selectedDate: state.team.selectedDate,
    selectedTeamID: state.team.selectedTeamID,
    timelogDayRange: state.team.timelogDayRange,
    week: state.member.week,
    weekData: state.member.weekData,
    tableData: state.member.tableData,
    active: state.team.active
});

const mapDispatchToProps = dispatch => ({
    dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIndividual);