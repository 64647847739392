import React, { useState } from "react";
import BlockUi from "react-block-ui";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { Accordion, AccordionTab } from "primereact/accordion";
//
import Modal from "../../components/Modal";
import ProjectForm from "./project-form";
import TaskForm from "./task-form";
import PulseLoader from "react-spinners/PulseLoader";
import { usePrevious } from "../../hooks/userPrevious";
import team from "../../services/team";
import { isEmpty } from "lodash";
import firebase from "../../config";
import { useHistory } from "react-router-dom";
const database = firebase.firestore();

const Project = () => {
  const history = useHistory();
  const teams = useSelector((state) => ({
    teams: state.team.teams,
    loadingProject: state.team.loadingProject,
    activeTeam: state.team.activeTeam,
    selectedTeamId: state.team.selectedTeamID,
    projects: state.team.projects,
    tasks: state.team.tasks,
    activeUserId: state.team.activeUserID,
    all: state.team.all,
    loading: false,
    selectedProject: state.team.selectedProject,
    active: state.team.active,
  }));
  const auth = useSelector((state) => ({ ...state.auth }));
  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));
  let prevTeams = usePrevious(teams?.teams);
  let currentTeams = teams?.teams;
  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let members = successPayload ? successPayload.data : [];
  const dispatch = useDispatch();
  const [state, setState] = useState({
    defaultProjectIdx: 0,
    modal: "",
    selectedProjectId: "",
  });

  const { activeCompany } = useSelector((state) => state.auth.profile);
  const [list, setList] = useState([]);

  React.useEffect(() => {
    if (!isEmpty(teams.active))
      dispatch({
        type: "team/GET_PROJECT",
        payload: { companyId: activeCompany.companyId },
      });
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
      }
    }
  }, [teams.selectedTeamId]);

  React.useEffect(() => {
    if (!isEmpty(teams.active)) {
      dispatch({
        type: "team/GET_TASKS",
        payload: {
          projectId: teams.selectedProject,
          teamId: teams.active.activeTeamid,
          activeUserId: auth?.profile?.id,
        },
      });
    }
  }, [teams.active]);

  const handleCloseEventModal = () => {
    setState({ ...state, modal: "", selectedProjectId: "" });
  };

  const getTasksList = (projectId) => {
    dispatch({
      type: "team/GET_TASKS",
      payload: {
        companyId: activeCompany.companyId,
        projectId: projectId,
        teamId: teams.active.activeTeamid,
        activeUserId: teams.activeUserId,
      },
    });
  };

  const editProject = (project) => {
    setState({ ...state, project, modal: "PROJECT-FORM" });
  };

  const deleteProject = (project) => {
    dispatch({
      type: "team/DELETE_PROJECT",
      payload: {
        ...project,
        teamId: teams.active.activeTeamid,
        companyId: { companyId: activeCompany.companyId },
      },
    });
  };

  React.useEffect(() => {
    if (!teams.loading) {
      handleCloseEventModal();
    }
  }, [teams.loading]);

  const getTasksNames = async () => {
    if (!isEmpty(teams?.projects)) {
      for (let i = 0; i < (teams?.projects).length; i++) {
        if (
          teams?.projects[i]?.data?.taskList &&
          (teams?.projects[i]?.data?.taskList).length > 0
        ) {
          for (
            let j = 0;
            j < (teams?.projects[i]?.data?.taskList).length;
            j++
          ) {
            const data = await database
              .collection("taskDatabase")
              .doc(teams?.projects[i]?.data?.taskList[j])
              .get();

            list.push({
              id: teams?.projects[i]?.data?.taskList[j],
              title: data?.data()?.Title,
            });
          }
        }
      }
    }
    setList(
      list.filter((v, i, a) => a.findIndex((v2) => v2.title === v.title) === i)
    );
  };

  React.useEffect(() => {
    getTasksNames();
  }, [teams?.projects]);
  return (
    <div
      className="header bg-gradient-info pb-md-2  pt-md-2"
      style={{ paddingTop: "5rem!important" }}
    >
      <Container className="pt-md-0" fluid>
        <BlockUi tag="div" blocking={teams.loading}>
          <Row>
            <div className="col-12 p-3">
              {isEmpty(auth?.profile?.companyList) ? (
                <div className="shadow p-3 card">
                  <h4 className="text-danger">
                    Please create a company first to create a project!
                  </h4>
                </div>
              ) : (
                <div className="shadow p-3 card">
                  {teams.loadingProject ? (
                    <div className="time-track-section">
                      <PulseLoader color="#36d7b7" />
                    </div>
                  ) : (
                    <div style={{ margin: "10px 0px" }}>
                      <Button
                        className="float-right ml-1"
                        size="sm"
                        color="info"
                        onClick={() => {
                          setState({
                            ...state,
                            modal: "PROJECT-FORM",
                            project: null,
                          });
                        }}
                      >
                        Add Project
                      </Button>
                      <Button
                        className="float-right"
                        size="sm"
                        color="info"
                        onClick={() => 
                          window.open("/add-task", "_blank")
                          // setState({
                          //   ...state,
                          //   modal: "TASK-FORM",
                          //   project: null,
                          // });
                        }
                      >
                        Add Task
                      </Button>
                    </div>
                  )}
                  <Accordion multiple activeIndex={0}>
                    {teams?.projects?.length > 0 &&
                      teams?.projects?.map(
                        (res, index) =>
                          !res.data.deleted && (
                            <AccordionTab
                              header={
                                <>
                                  <div className="title btn-title">
                                    <span>{res.data.project_name}</span>
                                  </div>
                                  <div className="button-container">
                                    <Button
                                      className="ml-3"
                                      color="warning"
                                      size="sm"
                                      onClick={() =>
                                        editProject({ projectId: res?.id })
                                      }
                                    >
                                      <i
                                        className="fas fa-edit"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                    <Button
                                      className=""
                                      color="danger"
                                      size="sm"
                                      onClick={() =>
                                        deleteProject({
                                          projectId: res?.id,
                                        })
                                      }
                                    >
                                      <i
                                        className="fas fa-trash-alt"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  </div>
                                </>
                              }
                            >
                              <ListGroup>
                                {res?.data?.taskList?.length > 0 ? (
                                  res?.data?.taskList?.map((res, index) => (
                                    <>
                                      <ListGroupItem
                                        key={res}
                                        active={teams.selectedProject === res}
                                        tag="button"
                                        action
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                        size="sm"
                                      >
                                        <div
                                          onClick={() => {
                                            getTasksList(res, index);
                                          }}
                                        >
                                          {
                                            (list?.filter((a) => {
                                              if (a.id == res) {
                                                return a;
                                              }
                                            }))[0]?.title
                                          }
                                        </div>
                                      </ListGroupItem>
                                    </>
                                  ))
                                ) : (
                                  <div>No Tasks Created Yet</div>
                                )}
                              </ListGroup>
                            </AccordionTab>
                          )
                      )}
                  </Accordion>
                </div>
              )}
            </div>
          </Row>
          <Modal
            isOpen={state.modal === "PROJECT-FORM"}
            size={"sm"}
            onClose={handleCloseEventModal}
            title="Add Project"
          >
            <div style={{ padding: "20px" }}>
              <ProjectForm
                project={state.project}
                handleCloseEventModal={handleCloseEventModal}
              />
            </div>
          </Modal>
          <Modal
            isOpen={state.modal === "TASK-FORM"}
            size={"sm"}
            onClose={handleCloseEventModal}
            title="Add Task"
          >
            <div style={{ padding: "20px" }}>
              <TaskForm
                project={state.project}
                projects={teams.projects}
                handleCloseEventModal={handleCloseEventModal}
                members={members}
              />
            </div>
          </Modal>
        </BlockUi>
      </Container>
    </div>
  );
};
export default Project;
