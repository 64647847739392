import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Input, Card, } from "reactstrap";
import { Button } from "primereact/button";
import { isEmpty } from "lodash";
import PulseLoader from "react-spinners/PulseLoader";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Column } from "primereact/column";
import firebase from "../../config";
import { usePrevious } from "../../hooks/userPrevious";
import "./taskList.css";
import CustomSelect from "./CheckBox";
import { useHistory } from "react-router-dom";
import "./task-form.css";
import TaskForm from "./task-form" //this import hold some styles so its important
const database = firebase.firestore();

const ManageTasksCal = (taskLists, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);
  const sortedItems = useMemo(() => {
    let sortableItems = [...taskLists];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [taskLists, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  return { items: sortedItems, requestSort, sortConfig };
};

export const ManageTasks = () => {
  const teams = useSelector((state) => ({
    teams: state.team.teams,
    loadingProject: state.team.loadingProject,
    activeTeam: state.team.activeTeam,
    selectedTeamId: state.team.selectedTeamID,
    projects: state.team.projects,
    tasks: state.team.tasks,
    activeUserId: state.team.activeUserID,
    all: state.team.all,
    loading: false,
    selectedProject: state.team.selectedProject,
    active: state.team.active,
    membersData: state.team.membersData,
  }));
  const history = useHistory()

  const { activeCompany } = useSelector((state) => state.auth.profile);
  const membersState = useSelector((state) => ({
    ...state.manageTeams.members,
  }));

  const [taskLists, setTaskLists] = useState([]);
  const [initialTaskLists, setInitialTaskLists] = useState([]);
  const [projects, setProjects] = useState([]);
  const [, setTaskRowIndex] = useState();

  const [projectID, setProjectID] = useState();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let prevTeams = usePrevious(teams?.teams);
  let currentTeams = teams?.teams;
  let finalizeData = currentTeams?.data?.filter(
    (item, index) =>
      item.Role == "Manager" ||
      item.Role == "Admin" ||
      item.Role == "Head" ||
      item.Role == "Member"
  );
  let { successPayload } = membersState;
  let members = successPayload ? successPayload.data : [];

  useEffect(() => {
    if (!isEmpty(teams.active))
      dispatch({
        type: "team/GET_PROJECT",
        payload: { companyId: activeCompany.companyId },
      });
    if (prevTeams?.data !== currentTeams?.data) {
      if (currentTeams?.data?.length > 0) {
        dispatch({
          type: "teams/GET_MEMBERS",
          payload: { teams: [...finalizeData] },
        });
      }
    }
  }, [teams.selectedTeamId]);

  useEffect(() => {
    if (JSON.stringify(teams.projects) !== "{}" && members?.length) {
      {
        Object.entries(teams.projects).map((m) => {
          setLoading(true);
          !m[1]?.data?.deleted === true && projects.push(m[1]);
        });
      }
      if (projects.length !== 0 && members?.length) {
        database
          .collection("taskDatabase")
          .where("ProjectID", "==", projects[0].id)
          .get()
          .then(async (res) => {
            let lists = res?.docs?.map((r) => r?.data());
            let listId = res?.docs?.map((r) => r?.id);
            let listsWithId = [];
            for (let i = 0; i < lists.length; i++) {
              const username = await assigneeTempelate(lists[i]);
              listsWithId.push({
                ...lists[i],
                id: listId[i],
                userName: username,
              });
            }
            const filteredTaskList = listsWithId.filter(
              (task) => !task.Deleted
            );
            setTaskLists(filteredTaskList);
            setInitialTaskLists(filteredTaskList);
            setLoading(false);
          });
      }
    }
  }, [ members]);

  const { items, requestSort, sortConfig } = ManageTasksCal(taskLists);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const refreshDataTable = async () => {
		// Check if there is a projectID selected; if not, use the first project in the list
		const projectId =
			projectID || (projects.length > 0 ? projects[0].id : null);

		if (projectId) {
			// Call fetchTask with the current project ID
			await fetchTask(projectId);
		}
  };

  const assigneeTempelate = (rowData) => {
    const assigneeValue = rowData?.Assignee;
    const userName = members
      ?.filter((a) => {
        if (a.userUID == assigneeValue) {
          return true;
        }
        return false;
      })
      .map((matchingUser) => {
        return matchingUser.userName;
      })[0];
    return userName;
  };
  const etaTempelate = (rowData) => {
    return rowData.ETA.split("/").join("-")
  }

  const fieldIndex = (rowData, column) => {
    setTaskRowIndex(column.rowIndex + 1);
    return column.rowIndex + 1 + "";
  };

  // onclicking navigate to task page function
  const navigate = async(data) => {
    let projectData = await database
      .collection("companyDatabase")
      .doc(activeCompany.companyId)
      .collection("projects")
      .doc(data.ProjectID)
      .get();
    const project = projectData.data()
    const projectName = project.project_name.split(" ").join("-")
    const url =`/${projectName}/${data.id}`;
    const newWindow = window.open(url,'_blank');
    newWindow["projectId"] = data.ProjectID
  };

  // filtering states
  const [searchTerm, setSearchTerm] = useState("");
  let [Assignee, setUser] = useState([]);
  let [Status, setStatus] = useState([]);
  let [Priority, setPriority] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);

  // filtering whole functionality of getting filtered results
  function filterTaskList(taskList, filters) {
    return taskList.filter((item) =>
      Object.entries(filters).every(([key, values]) => {
        if (!values.length) return true; // if no values, don't filter by this criteria
        if (key === "searchTerm" && values !== "") {
          return (
            item?.Description?.toLowerCase().includes(values?.toLowerCase()) ||
            item?.Title?.toLowerCase().includes(values?.toLowerCase())
          );
        } else {
          return values.some((value) => item[key] === value?.value);
        }
      })
    );
  }

  // running everytime when any of filter fields is changed
  useEffect(() => {
    const filters = {
      searchTerm,
      Assignee,
      Status,
      Priority,
    };
    const filteredData = filterTaskList(initialTaskLists, filters);
    setTaskLists(filteredData);
  }, [Assignee, Status, Priority, searchTerm, dataStatus]);

  const member = members?.map((member) => ({
    value: member.userUID,
    label: member.userName,
  }));

  const getSeverity = (status) => {
    switch (status) {
      case "DONE":
        return "success";

      case "IN PROGESS":
        return "info";

      case "TO DO":
        return "danger";

      case "QA":
        return "warning";
    }
  };
  const fetchTask = async (projectId) => {
    setLoading(true);
    const taskData = await database
      .collection("taskDatabase")
      .where("ProjectID", "==", projectId)
      .get();
    setProjectID(projectId);
    let lists = taskData.docs.map((res) => res.data());
    let listId = taskData.docs?.map((r) => r?.id);
    let listsWithId = [];
    for (let i = 0; i < lists.length; i++) {
      const userName = await assigneeTempelate(lists[i]);
      listsWithId.push({ ...lists[i], id: listId[i], userName: userName });
    }
    const filteredTaskList = listsWithId.filter((task) => !task.Deleted);
    setTaskLists(filteredTaskList);
    setInitialTaskLists(filteredTaskList);
    setLoading(false);

    setDataStatus(!dataStatus)
  };

  const toggleModal = useCallback(() => {
    window.open("/add-task", "_blank");
  }, []);

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.Status} severity={getSeverity(rowData.Status)} />
    );
  };

  const [selectedProject, setSelectedProject] = useState(teams?.projects[1]?.id || "")
  useEffect(() => {
    if(teams?.projects[1]?.id){
    setSelectedProject(teams.projects[1].id)
    }
  },[teams?.projects[1]?.id])
  
  return (
    <div style={{ paddingTop: "5rem!important" }}>
      <Container className="pt-md-2 " fluid>
        <div className="top-item-options row">
          <div className="task-select-options col-sm-12 col-md-6 col-lg-3 mb-2">
            <Input
              type="select"
              name="selectMulti"
              id="exampleSelectMulti"
              onChange={async (e) => {
                setSelectedProject(e?.target?.value)
                await fetchTask(e?.target?.value);
              }}
            >
              {Object.entries(teams.projects).map((m) => {
                return (
                  !m[1]?.data?.deleted === true && (
                    <option key={m[1]?.id} value={m[1]?.id}>
                      {m[1]?.data?.project_name}
                    </option>
                  )
                );
              })}
            </Input>
          </div>
          <div className="task-select-options col-sm-12 col-md-6 col-lg-3 mb-2 assignee-dropdown">
            {member?.length > 0 && (
              <CustomSelect
                placeholder="Assignee"
                options={member}
                selected={Assignee}
                setSelected={setUser}
              />
            )}
          </div>
          <div className="task-select-options col-sm-12 col-md-6 col-lg-3 mb-2">
            {member?.length > 0 && (
              <CustomSelect
                placeholder="Status"
                options={[
                  { value: "TO DO", label: "TO DO" },
                  { value: "IN PROGRESS", label: "IN PROGRESS" },
                  { value: "QA", label: "QA" },
                  { value: "DONE", label: "DONE" },
                ]}
                selected={Status}
                setSelected={setStatus}
              />
            )}
          </div>
          <div className="task-select-options col-sm-12 col-md-6 col-lg-3 mb-2">
            {member?.length > 0 && (
              <CustomSelect
                placeholder="Priority"
                options={[
                  { value: "P1", label: "P1" },
                  { value: "P2", label: "P2" },
                  { value: "P3", label: "P3" },
                  { value: "P4", label: "P4" },
                ]}
                selected={Priority}
                setSelected={setPriority}
              />
            )}
          </div>
        </div>
        <Card className="bg-secondary shadow mt-2">
          <div style={{display:"flex", flexDirection: "row"}}>
            <Button type="button" icon="pi pi-refresh" className="p-button-text col-1" onClick={refreshDataTable}/>
            <div
              className="search-div row col-11 space-between"
              style={{ margin: "10px 0px" }}
            >
              <input
                type="text"
                placeholder="Search Your Task List"
                className="search-input mb-2"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button
                className="float-right add-button"
                color="info"
                onClick={toggleModal}
              >
                Add Task
              </Button>
            </div>
          </div>
          {loading ? (
            <div className="w-100 d-flex justify-content-center">
              <PulseLoader color="#36d7b7" />
            </div>
          ) : (
            <DataTable
              className="task-table task-page-table"
              responsiveLayout="scroll"
              value={items}
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              style={{ cursor: "pointer" }}
              rows={50}
              rowsPerPageOptions={[10, 20, 50]}
              onRowClick={(e) => navigate(e?.data)}
            >
              <Column
                field="#"
                header="Sr.No."
                body={fieldIndex}
                className="first-col td-start"
              ></Column>
              <Column
                field="Title"
                header="Title"
                className="second-col elipsis"
                sortable
              ></Column>
              <Column
                field="id"
                header="Task Id"
                className="forth-col td-start"
                style={{ minWidth: "130px" }}
              >
              </Column>
              <Column
                field="Status"
                header="Status"
                body={statusBodyTemplate}
                className="forth-col td-start"
                style={{ minWidth: "130px" }}
                sortable
              ></Column>
              <Column
                field="userName"
                header="Assignee"
                body={assigneeTempelate}
                className="fifth-col td-start"
                sortable
              ></Column>
              <Column
                field="eta"
                header="ETA"
                body={etaTempelate}
                className="fifth-col td-start"
                sortable
              ></Column>
              <Column
                field="Priority"
                header="Priority"
                className="first-col td-start"
                sortable
              ></Column>
            </DataTable>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default ManageTasks;
