import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./style.css";
import { Card, Container, Row, Col } from "reactstrap";
import Popover from "@material-ui/core/Popover";

class IdleMinutes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }

  highestIdleMinutes = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };

  filterProductiveTime(times, users) {
    const filtered = users
      .filter((user) => {
        const hours = Math.floor(times[user].idleMinutes / 60);
        times[user].idleMinutes = times[user].idleMinutes % 60;
        times[user].idleHours += hours;
        if (times[user].idleHours > 0) {
          return times[user].idleHours > 0;
        } else if (times[user].idleHours === 0 && times[user].idleMinutes > 0) {
          return times[user].idleMinutes > 0;
        }
      })
      .map((user) => {
        return times[user];
      })
      .sort((a, b) => {
        return (
          b.idleHours + b.idleMinutes / 60 - (a.idleHours + a.idleMinutes / 60)
        );
      })
      .splice(0, 4);
    return filtered;
  }

  productiveTime(times) {
    if (Object.keys(times).length !== 0) {
      const n = Object.keys(times);
      const filtered = this.filterProductiveTime(times, n);
      const f = Object.keys(filtered);
      var colorArray = ["yellow", "pink", "ash", "cyan", "purple"];
      return f.map((element) => {
        let percent =
          ((filtered[element].idleHours + filtered[element].idleMinutes / 60) /
            (filtered[element].totalHours +
              filtered[element].totalMinutes / 60)) *
          100;
        const rand = Math.floor(Math.random() * colorArray.length);
        return (
          <Row>
            <div className="col-2 bar-label-name">
              <div
                className={`inline-block circle-radialbar ${colorArray[rand]}`}
              >
                {filtered[element].userName.charAt(0)}
              </div>
              <div className="inline-block">
                {filtered[element].userName.replace(/ .*/, "")}
              </div>
            </div>
            <div className="col-10 pl-4 percentage-data">
              <div
                className="bar-line-container inline-block line-container"
                style={{ width: `${percent}%` }}
              ></div>
              <div className="bar-label">
                {filtered[element].idleHours}h {filtered[element].idleMinutes}m
              </div>
            </div>
          </Row>
        );
      });
    }
  }
  render() {
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Container className="mt-2" fluid>
          <Row>
            <Col>
              <Card className="shadow p-3">
                <div style={{ minHeight: `auto` }} className="p--1">
                  <Row>
                    <div className="col">
                      <span className="headline">
                        Highest idle hours
                      </span>
                    </div>
                  </Row>
                  <Row className="dashboard-section-body idle-minutes">
                    <div className="col">
                      <div className="bar-container">
                        {this.props.topUsers && this.productiveTime(this.props.topUsers)}
                      </div>
                    </div>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(IdleMinutes);
