import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import IndexLayout from "../layouts";
import NotFoundPage from "../views/404";
import LoginScreen from "../containers/AuthContainer/LoginContainer";
import RegisterScreen from "../views/Auth/signup/";
import DashboardScreen from "../containers/DashboardContainer";
import DashboardScreenIndividual from "../containers/DashboardContainerIndividual";
import UserAttendance from "./../views/UserAttendance/index";
import ManageSettings from "../containers/Settings";
import TeamAttendance from "../containers/TeamAttendance";
import ProjectContainer from "../containers/ProjectContainer";
import ManageTeams from "./../views/Teams/index";
import ManageTasks from "./../views/Tasks/index";
import Profile from "./../views/Profile/index";
import TeamInvite from "./../views/Teams/invite/index";
import Regulations from "./../views/Regulation/index";
import Requests from "./../views/Requests/requests";
import InvitationPage from "./../views/Invitation/index";
import AddCompany from "./../views/Company/AddCompany/index";
import SelectCompany from "./../views/Company/SelectCompany/index";
import Invite from "./../views/Invite/Invite";
import InviteList from "../views/Invite/InviteList";
import AddUpdateTaskPage from "../views/TaskPage/AddUpdateTaskPage";
import Leave from "../views/Leave";
import LeaveRequest from "../views/LeaveRequest";

export const appRoutes = [
  {
    path: "/auth/login",
    exact: true,
    name: "Login",
    hidden: true,
    component: LoginScreen,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/auth/sign-up",
    exact: true,
    name: "Login",
    hidden: true,
    component: RegisterScreen,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/invitation",
    name: "Invitation",
    icon: "ni ni-tv-2 text-primary",
    component: InvitationPage,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/userAttendance",
    name: "userAttendance",
    icon: "ni ni-tv-2 text-primary",
    component: UserAttendance,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/add-company",
    exact: true,
    name: "Add Company",
    hidden: true,
    component: AddCompany,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/select-company",
    exact: true,
    name: "Select Company",
    hidden: true,
    component: SelectCompany,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Teams",
    icon: "ni ni-tv-2 text-primary",
    component: DashboardScreen,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: true,
  },
  {
    path: "/dashboard-team",
    exact: true,
    name: "Teams",
    icon: "ni ni-tv-2 text-primary",
    component: DashboardScreen,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: true,
  },
  {
    path: "/dashboard-individual",
    exact: true,
    name: "User",
    icon: "ni ni-tv-2 text-primary",
    component: DashboardScreenIndividual,
    authorizedFor: ["Member", "Manager"],
    hasSortingOptions: true,
  },
  {
    path: "/manage-settings",
    exact: true,
    name: "Settings",
    icon: "ni ni-tv-2 text-primary",
    component: ManageSettings,
    authorizedFor: ["Manager"],
    hasSortingOptions: true,
  },
  {
    path: "/team-attendance",
    exact: true,
    name: "Team Attendance",
    icon: "ni ni-tv-2 text-primary",
    component: TeamAttendance,
    authorizedFor: ["Manager"],
    hasSortingOptions: true,
  },
  {
    path: "/manage-teams",
    exact: true,
    name: "Manage Teams",
    icon: "ni ni-tv-2 text-primary",
    component: ManageTeams,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/manage-teams/invite",
    exact: true,
    name: "Send Invites",
    icon: "ni ni-tv-2 text-primary",
    component: TeamInvite,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/project",
    exact: true,
    name: "Project",
    icon: "ni ni-tv-2 text-primary",
    component: ProjectContainer,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/invites/:id",
    exact: true,
    name: "Invite",
    icon: "ni ni-tv-2 text-primary",
    component: Invite,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/inviteList",
    exact: true,
    name: "InviteList",
    icon: "ni ni-tv-2 text-primary",
    component: InviteList,
    authorizedFor: ["All"],
    hasSortingOptions: false,
  },
  {
    path: "/manage-tasks",
    exact: true,
    name: "ManageTasks",
    icon: "ni ni-tv-2 text-primary",
    component: ManageTasks,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/add-task",
    exact: true,
    name: "Add Task",
    icon: "ni ni-tv-2 text-primary",
    component: AddUpdateTaskPage,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/:projectName/:taskId",
    exact: true,
    name: "Update Task",
    icon: "ni ni-tv-2 text-primary",
    component: AddUpdateTaskPage,
    authorizedFor: ["Manager", "Admin"],
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    icon: "ni ni-tv-2 text-primary",
    component: Profile,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/regularization",
    exact: true,
    name: "Regularization",
    icon: "ni ni-tv-2 text-primary",
    component: Regulations,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/requestsregularization",
    exact: true,
    name: "requests",
    icon: "ni ni-tv-2 text-primary",
    component: Requests,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/leave",
    exact: true,
    name: "Leave",
    icon: "ni ni-tv-2 text-primary",
    component: Leave,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
  {
    path: "/requestsleave",
    exact: true,
    name: "Leave-request",
    icon: "ni ni-tv-2 text-primary",
    component: LeaveRequest,
    authorizedFor: ["Manager", "Admin"],
    hasSortingOptions: false,
  },
];

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <IndexLayout>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/dashboard-team" />}
        />
        {appRoutes.map((route) => (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        ))}
        <Route component={NotFoundPage} />
      </Switch>
    </IndexLayout>
  </ConnectedRouter>
);

Router.propTypes = {
  history: PropTypes.instanceOf(Object),
};

Router.defaultProps = {
  history: {},
};

export default Router;
