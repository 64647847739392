import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./style.css";
import { Card, Container, Row } from "reactstrap";
import Popover from "@material-ui/core/Popover";
import Chart from "react-apexcharts";

class ProductiveUnproductiveTime extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }

  highestProductiveTime = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };
  highestUnProductiveTime = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };

  // filterUnProductiveTime(times, users) {
  //   const filtered = users
  //     .filter((user) => {
  //       return times[user].idleHours > 0;
  //     })
  //     .map((user) => {
  //       return times[user];
  //     })
  //     .sort((a, b) => {
  //       return (
  //         ((b.idleHours + b.idleMinutes / 60) /
  //           (b.totalHours + b.totalMinutes / 60)) *
  //           100 -
  //         ((a.idleHours + a.idleMinutes / 60) /
  //           (a.totalHours + a.totalMinutes / 60)) *
  //           100
  //       );
  //     })
  //     .splice(0, 4);
  //   return filtered;
  // }
  unProductiveTime(times) {
    if (Object?.keys(times).length !== 0) {
      const n = Object.keys(times);
      const filtered = this.filterProductiveTime(times, n);
      const f = Object.keys(filtered);
      let chartOptions = {
        options: {
          chart: {
            type: "radialBar",
            offsetY: -10,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "30%",
                margin: 10, // margin is in pixels
                dropShadow: {
                  enabled: false,
                },
              },
              dataLabels: {
                name: {
                  show: true,
                  fontSize: "12px",
                  color: "#848da4",
                },
                value: {
                  show: true,
                  offsetY: -30,
                  fontSize: "13px",
                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },
            },
          },
          grid: {
            padding: {
              top: 0,
              left: 0,
              right: 0,
            },
          },
          fill: {
            colors: ["#FF0000"],
          },
        },
      };
      return f.map((element) => {
        console.log("E : ", element);
        chartOptions = {
          ...chartOptions,
          options: {
            ...chartOptions.options,
            labels: [[filtered[element].userName.replace(/ .*/, "")]],
          },
        };

        let idleMinutesToHours = filtered[element].idleMinutes / 60;
        let totalMinutesToHours = filtered[element].totalMinutes / 60;
        let idleHoursPercent = 0;

        if (filtered[element].totalHours !== 0) {
          idleHoursPercent =
            ((filtered[element].idleHours + idleMinutesToHours) /
              (filtered[element].totalHours + totalMinutesToHours)) *
            100;
        }
        if (
          filtered[element].totalHours == 0 &&
          filtered[element].totalMinutes !== 0
        ) {
          idleHoursPercent =
            ((filtered[element].idleMinutes + idleMinutesToHours) /
              (filtered[element].totalMinutes + totalMinutesToHours)) *
            100;
        }
        return (
          idleHoursPercent.toFixed(0) > 0 && (
            <div className="col my-2 mx-0 pl-2 pr-2">
              <Chart
                options={chartOptions.options}
                series={[idleHoursPercent.toFixed(1)]}
                type="radialBar"
                width="100"
                height="120"
              />
            </div>
          )
        );
      });
    }
  }

  filterProductiveTime(times, users) {
    const filtered = users
      .filter((user) => {
        if (times[user].idleMinutes > 0 || times[user].idleHours > 0) {
          if (times[user].idleHours > 0) {
            return times[user].idleHours > 0;
          } else if (
            times[user].idleHours === 0 &&
            times[user].idleMinutes > 0
          ) {
            return times[user].idleMinutes;
          }
        }
      })
      .map((user) => {
        return times[user];
      })
      .sort((a, b) => {
        return (
          ((b.idleHours + b.idleMinutes / 60) /
            (b.totalHours + b.totalMinutes / 60)) *
            100 -
          ((a.idleHours + a.idleMinutes / 60) /
            (a.totalHours + a.totalMinutes / 60)) *
            100
        );
      })
      .splice(0, 4);
    console.log("filtered in filterProductiveTime", filtered);
    return filtered;
  }

  filterUnProductiveTime(times, users) {
    const filtered = users
      .filter((user) => {
        if (times[user].productiveHours > 0) {
          return times[user].productiveHours > 0;
        } else if (
          times[user].productiveHours === 0 &&
          times[user].productiveMinutes > 0
        ) {
          return times[user].productiveMinutes;
        }
      })
      .map((user) => {
        return times[user];
      })
      .sort((a, b) => {
        return (
          ((b.productiveHours + b.productiveMinutes / 60) /
            (b.totalHours + b.totalMinutes / 60)) *
            100 -
          ((a.productiveHours + a.productiveMinutes / 60) /
            (a.totalHours + a.totalMinutes / 60)) *
            100
        );
      })
      .splice(0, 4);
    return filtered;
  }

  productiveTime(times) {
    console.log(times)
    if (Object?.keys(times).length !== 0) {
      const n = Object.keys(times);
      const filtered = this.filterUnProductiveTime(times, n);
      const f = Object.keys(filtered);
      let chartOptions = {
        options: {
          chart: {
            type: "radialBar",
            offsetY: -10,
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: "30%",
                margin: 10, // margin is in pixels
                dropShadow: {
                  enabled: false,
                },
              },
              dataLabels: {
                name: {
                  show: true,
                  fontSize: "12px",
                  color: "#848da4",
                },
                value: {
                  show: true,
                  offsetY: -30,
                  fontSize: "13px",
                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },
            },
          },
          grid: {
            padding: {
              top: 0,
              left: 0,
              right: 0,
            },
          },
          fill: {
            colors: ["#27c26c"],
          },
        },
      };
      return f.map((element) => {
        chartOptions = {
          ...chartOptions,
          options: {
            ...chartOptions.options,
            labels: [[filtered[element].userName.replace(/ .*/, "")]],
          },
        };
        console.log("Fil : ", [filtered[element]]);
        let productiveMinutesToHours = filtered[element].productiveMinutes / 60;
        let totalMinutesToHours = filtered[element].totalMinutes / 60;
        let productiveHoursPercent = 0;

        if (filtered[element].totalHours !== 0) {
          productiveHoursPercent =
            ((filtered[element].productiveHours + productiveMinutesToHours) /
              (filtered[element].totalHours + totalMinutesToHours)) *
            100;
        }
        if (
          filtered[element].totalHours == 0 &&
          filtered[element].totalMinutes !== 0
        ) {
          productiveHoursPercent =
            ((filtered[element].productiveMinutes + productiveMinutesToHours) /
              (filtered[element].totalMinutes + totalMinutesToHours)) *
            100;
        }
        return (
          <div className="col my-2 mx-0 pl-2 pr-2">
            <Chart
              options={chartOptions.options}
              series={[productiveHoursPercent.toFixed(1)]}
              type="radialBar"
              width="100"
              height="120"
            />
          </div>
        );
      });
    }
  }

  render() {
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Container fluid>
          <div className="header-body">
            <Row>
              <div className="col dashboard-section-heading">
                <h3>Top 4 users</h3>
              </div>
            </Row>
          </div>
        </Container>
        <Container className="mt-1" fluid>
          <Row>
            <div className="col-lg-6 col-md-12 col-sm-12 mt-2 pr-lg-1">
              <Card className="shadow p-3">
                <div style={{ minHeight: `${20}vh` }} className="p--1">
                  <Row>
                    <div className="col">
                      <span className="headline">
                        Highest % productive time
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div className="col dashboard-section-header">
                      0% minimum productive time
                    </div>
                  </Row>
                  <Row className="dashboard-section-body">
                    {/* <div className="col"> */}
                    {this.props.topUsers &&
                      this.productiveTime(this.props.topUsers)}
                  </Row>
                </div>
              </Card>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
              <Card className="shadow p-3">
                <div style={{ minHeight: `${20}vh` }} className="p--1">
                  <Row>
                    <div className="col">
                      <span className="headline">
                        Highest % unproductive time
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div className="col dashboard-section-header">
                      0% minimum unproductive time
                    </div>
                  </Row>
                  <Row className="dashboard-section-body">
                    {this.props.topUsers &&
                      this.unProductiveTime(this.props.topUsers)}
                  </Row>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductiveUnproductiveTime);
