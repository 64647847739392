const actions = {
    SET_STATE: "member/SET_STATE",
    SET_SELECT_DATE: "member/SET_SELECT_DATE",  
    SET_SELECTED_MEMBER_ID: "member/SET_SELECTED_MEMBER_ID",  
    SET_TIMELOG_DAY_RANGE: "member/SET_TIMELOG_DAY_RANGE", 
    GET_USER_TIMELOG_DATA: "member/GET_USER_TIMELOG_DATA",
    SET_TODAY_TIMES: "member/SET_TODAY_TIMES", //Unused
    GET_USER_WEEK_DATA: "member/GET_USER_WEEK_DATA",
    GET_USER_TABLE_DATA: "member/GET_USER_TABLE_DATA",
    GET_USER_DATA: "member/GET_USER_DATA",  //Unused
    GET_PROJECT_TIMELOG_DATA: "member/GET_PROJECT_TIMELOG_DATA",
    GET_Member_TIMELOG: "member/GET_Member_TIMELOG",
    GET_DATE_RANGE_DATA: "member/GET_DATE_RANGE_DATA"
};

export default actions;
