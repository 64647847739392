import React, { PureComponent } from "react";
import { Card, Row } from "reactstrap";
import { Line } from "react-chartjs-2";
import Popover from "@material-ui/core/Popover";
import isUndefined from "lodash/isUndefined";

class IdleTimeCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
    };
  }

  idleTime = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };

  idleHoursTracked() {
    // ChartJS.register(ArcElement, Tooltip, Legend);
    let Monday = this.props?.weekData?.Monday?.idleHours;
    Monday = !isUndefined(Monday) ? Monday : 0;

    let Tuesday = this.props?.weekData?.Tuesday?.idleHours;
    Tuesday = !isUndefined(Tuesday) ? Tuesday : 0;

    let Wednesday = this.props?.weekData?.Wednesday?.idleHours;
    Wednesday = !isUndefined(Wednesday) ? Wednesday : 0;

    let Thursday = this.props?.weekData?.Thursday?.idleHours;
    Thursday = !isUndefined(Thursday) ? Thursday : 0;

    let Friday = this.props?.weekData?.Friday?.idleHours;
    Friday = !isUndefined(Friday) ? Friday : 0;

    let Saturday = this.props?.weekData?.Saturday?.idleHours;
    Saturday = !isUndefined(Saturday) ? Saturday : 0;

    let Sunday = this.props?.weekData?.Sunday?.idleHours;
    Sunday = !isUndefined(Sunday) ? Sunday : 0;
    const data = {
      labels: ["M", "T", "W", "T", "F", "S", "S"],
      datasets: [
        {
          label: "Hours tracked",
          data: [
            Monday.toFixed(2),
            Tuesday.toFixed(2),
            Wednesday.toFixed(2),
            Thursday.toFixed(2),
            Friday.toFixed(2),
            Saturday.toFixed(2),
            Sunday.toFixed(2),
          ],
          fill: false,
          tension: 0,
          borderColor: "rgba(255, 0, 0,1)",
          borderWidth: 3,
          pointBorderColor: "rgba(255, 0, 0,1)",
          pointBackgroundColor: "rgba(255,255,255, 1)",
          pointBorderWidth: 4,
          pointRadius: 5,
        },
      ],
    };
    const options = {
      legend: false,
    };
    return (
      <div>
        <Line data={data} options={options} height="100" />
      </div>
    );
  }
  render() {
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        <Card className="shadow p-3">
          <div style={{ height: `auto` }} className="p--1">
            <Row>
              <div className="col">
                <span className="headline">Idle time</span>
              </div>
            </Row>

            <Row className="dashboard-section-body">
              <div className="col">{this.idleHoursTracked()}</div>
            </Row>
          </div>
        </Card>
      </>
    );
  }
}

export default IdleTimeCard;
