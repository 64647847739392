import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import firebase from "../../config";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import map from "lodash/map";
import {
  addNotification,
  notificationStates,
} from "../../components/Notification";
import PulseLoader from "react-spinners/PulseLoader";

const database = firebase.firestore();
const batch = database.batch();

const Invite = (auth) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [expired, setExpired] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  // Get invitation data by id
  const getInviteDetails = async () => {
    await database
      .collection("userInvites")
      .doc(id)
      .get()
      .then((response) => {
        if (response.data() !== undefined) {
          userExists(response.data());
          setData(response.data());
        } else {
          setExpired(true);
        }
      });
  };

  // Accept project Invite
  const acceptProjectInvite = async () => {
    setLoad(true);
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", data?.userDetails?.email)
        .get()
        .then((collection) => {
          const auto = collection.docs.map((res) => res.id);
          const obj = {
            member_id: auto[0],
            project_id: data?.project[0]?.value,
          };
          try {
            database
              .collection("companyDatabase")
              .doc(data?.invitedBy?.companyDetails?.companyId)
              .update({
                clients: firebase.firestore.FieldValue.arrayUnion(obj),
              });
          } catch (error) {
            return error;
          }
        });
    } catch (error) {
      return error;
    }
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .update({ accepted: true });
    } catch (error) {
      return error;
    }
    setLoad(false);
    getInviteDetails();
  };

  // Accept Invitation
  const acceptInvite = async () => {
    setLoad(true);
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .update({ accepted: true });
    } catch (error) {
      return error;
    }
    getInviteDetails();
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .get()
        .then(async (response) => {
          let data = response.data();
          await addTeamToData(data);
        });
    } catch (error) {
      return error;
    }
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", auth?.auth?.profile?.email)
        .get()
        .then((collections) => {
          const auto = collections.docs.map((res) => res.id);
          try {
            database
              .collection("membersDatabase")
              .doc(auto[0])
              .update({
                teamInvites: firebase.firestore.FieldValue.arrayRemove(id),
              });
          } catch (error) {
            addNotification({
              message: "An error occured",
              level: notificationStates.success,
            });
          }
        });
    } catch (error) {
      addNotification({
        message: "An error occured",
        level: notificationStates.success,
      });
    }
    await addNotification({
      message: "Invite accepted successfully",
      level: notificationStates.success,
    });
    setLoad(false);
    if (auth?.auth?.profile?.companyList?.length === 0) {
      localStorage.setItem("list", 1);
    }
    setInterval(window.location.assign("/inviteList"), 100000000);
  };

  // Decline Invitation
  const declineInvite = async () => {
    setLoad(true);
    try {
      await database
        .collection("userInvites")
        .doc(id)
        .update({ declined: true });
    } catch (error) {
      return error;
    }
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", auth?.auth?.profile?.email)
        .get()
        .then((collections) => {
          const auto = collections.docs.map((res) => res.id);
          try {
            database
              .collection("membersDatabase")
              .doc(auto[0])
              .update({
                teamInvites: firebase.firestore.FieldValue.arrayRemove(id),
              });
          } catch (error) {
            addNotification({
              message: "An error occured",
              level: notificationStates.success,
            });
          }
        });
    } catch (error) {
      addNotification({
        message: "An error occured",
        level: notificationStates.success,
      });
    }
    addNotification({
      message: "Invite declined successfully",
      level: notificationStates.success,
    });
    setLoad(false);
    window.location = "/inviteList";
  };

  // Check if user exists
  const userExists = async (email) => {
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", email?.userDetails?.email)
        .get()
        .then((collections) => {
          const auto = collections.docs.map((res) => res.data());

          if (auto.length !== 0) {
            if (auto[0].accountEmailid !== auth?.auth?.profile?.email) {
              dispatch({
                type: "user/LOGOUT_ACTIVE_ACCOUNT",
              });
              addNotification({
                message: "Please login to your account first",
                level: notificationStates.error,
              });
            }
          } else {
            dispatch({
              type: "user/LOGOUT_ACTIVE_ACCOUNT",
            });
            addNotification({
              message: "Please create an account first",
              level: notificationStates.error,
            });
          }
        });
    } catch (error) {
      return error;
    }
  };

  // Add Accepted teams to member database
  const addTeamToData = async (data) => {
    let memberDetails = {};
    let oldData;
    try {
      await database
        .collection("membersDatabase")
        .where("accountEmailid", "==", auth?.auth?.profile?.email)
        .get()
        .then(async (collections) => {
          const auto = collections.docs.map((res) => res.id);
          memberDetails[auto[0]] = {
            userEmailid: auth?.auth?.profile?.email,
            userName: auth?.auth?.profile?.name,
            userType: data?.invitedTo?.invitedAs,
          };

          try {
            await database
              .collection("teamDatabase")
              .doc(data?.invitedTo?.teamId)
              .get()
              .then((res) => {
                const data = res.data();
                oldData = data.memberDetails;
              });
          } catch (error) {
            return error;
          }

          // Adding member to teamDatabase
          try {
            await database
              .collection("teamDatabase")
              .doc(data?.invitedTo?.teamId)
              .update({ memberDetails: { ...oldData, ...memberDetails } });
          } catch (error) {
            console.log("Error : ", error);
          }

          let memberDBRef = database.collection("membersDatabase").doc(auto[0]);
          try {
            await database
              .collection("teamDatabase")
              .doc(data?.invitedTo?.teamId)
              .get()
              .then((response) => {
                let data = [];
                if (!response.empty) {
                  if (response.data()) {
                    const { memberDetails } = response.data();
                    let members = [];
                    if (memberDetails) {
                      members = map(memberDetails, (value, index) => {
                        return {
                          ...value,
                          userUID: index,
                          role: value.userType,
                        };
                      });
                    }
                    data = members;
                    localStorage.setItem("members", JSON.stringify(data));
                  }
                }
                
              });
          } catch (error) {
            console.log("Error : ", error);
          }
            
          //       let memberTeamRef = memberDBRef
          //         .collection("teamList")
          //         .doc(data?.invitedTo?.teamId);
          let memberCompanyRef = memberDBRef
            .collection("companyList")
            .doc(data?.invitedBy?.companyDetails?.companyId);
          let memTeamListRef = memberCompanyRef
            .collection("teamList")
            .doc(data?.invitedTo?.teamId);

          // Add data for company list
          try {
            await memberDBRef
              .collection("companyList")
              .get()
              .then(async (doc) => {
                if (doc.size > 0) {
                  await memberCompanyRef.get().then(async (doc) => {
                    if (doc.exists) {
                      batch.set(
                        memTeamListRef,
                        {
                          Role: data?.invitedTo?.invitedAs,
                          Team: data?.invitedTo?.teamName,
                          activeTeamid: memTeamListRef.id,
                          email: data?.invitedBy?.emailId,
                        },
                        { merge: true }
                      );
                      // await batch.commit();
                    } else {
                      batch.set(
                        memberCompanyRef,
                        {
                          companyId: data?.invitedBy?.companyDetails?.companyId,
                          companyName:
                            data?.invitedBy?.companyDetails?.companyName,
                          role: data?.invitedTo?.invitedAs,
                        },
                        { merge: true }
                      );
                      batch.set(
                        memTeamListRef,
                        {
                          Role: data?.invitedTo?.invitedAs,
                          Team: data?.invitedTo?.teamName,
                          activeTeamid: memTeamListRef.id,
                          email: data?.invitedBy?.emailId,
                        },
                        { merge: true }
                      );
                      // await batch.commit();
                    }
                    await batch.commit();
                    
                    let teamRefData = await memberCompanyRef
                    .collection("teamList").get()
                    .then((querySnapshot) => {
                      const tempDoc = querySnapshot.docs.map((doc) => {
                        return { ...doc.data() };
                      });
                      let x = tempDoc.filter((a) => {
                        if (a?.deleted !== true) {
                          return a;
                        }
                      });
                      console.log(x, "xxxxxxxxxxx")
                      localStorage.setItem("Team", JSON.stringify({
                        success: true,
                        data: x,
                      }));
                    });

                    return {
                      success: true,
                      data: "Team created successfully!",
                    };
                  });
                } else {
                  batch.set(
                    memberCompanyRef,
                    {
                      companyId: data?.invitedBy?.companyDetails?.companyId,
                      companyName: data?.invitedBy?.companyDetails?.companyName,
                      role: data?.invitedTo?.invitedAs,
                    },
                    { merge: true }
                  );

                  batch.set(
                    memTeamListRef,
                    {
                      Role: data?.invitedTo?.invitedAs,
                      Team: data?.invitedTo?.teamName,
                      activeTeamid: memTeamListRef.id,
                      email: data?.invitedBy?.emailId,
                    },
                    { merge: true }
                  );
                  await batch.commit();
                  let teamRefData = await memberCompanyRef
                    .collection("teamList").get()
                    .then((querySnapshot) => {
                      const tempDoc = querySnapshot.docs.map((doc) => {
                        return { ...doc.data() };
                      });
                      let x = tempDoc.filter((a) => {
                        if (a?.deleted !== true) {
                          return a;
                        }
                      });
                      console.log(x, "xxxxxxxxxxx else")
                      localStorage.setItem("Team", JSON.stringify({
                        success: true,
                        data: x,
                      }));
                    });
                  return {
                    success: true,
                    data: "Team created successfully!",
                  };
                }
              });
          } catch (error) {
            console.log("Error : ", error);
          }
          memTeamListRef.get().then((res) => {
            const teamData = res.data();
            const obj = { Team: teamData?.Team, activeTeamid: teamData?.activeTeamid }
            localStorage.setItem('teamData', JSON.stringify(obj))
          });
        });
    } catch (error) {
      return error;
    }
    // await batch.commit();
    // return {
    //   success: true,
    //   data: "Team created successfully!",
    // };
    // window.location = "/inviteList";
  };

  useEffect(() => {
    getInviteDetails();
  }, []);

  return (
    <div style={{ paddingTop: "5rem!important" }}>
      {/* {userData !== [] ? ( */}
      {load ? (
        <Container
          className="pt-md-6 d-flex flex-column align-items-center justify-center"
          fluid
        >
          <PulseLoader color={"#02a17c"} />
        </Container>
      ) : (
        <Container
          className="pt-md-6 d-flex flex-column align-items-center justify-center"
          fluid
        >
          {data?.accepted === false && (
            <>
              {data?.invitedAs !== "Client" ? (
                <>
                  <p className="">
                    Hi, you have been invited to join team{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedTo?.teamName}
                    </span>{" "}
                    by company{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedBy?.companyDetails?.companyName}
                    </span>{" "}
                    as a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedAs}
                    </span>
                    .
                  </p>
                  {/* <Button onClick={userExists(data)}>Click</Button> */}
                  <div className="d-flex">
                    <Button color="primary" onClick={acceptInvite}>
                      Accept
                    </Button>
                    <Button
                      className=""
                      onClick={() => {
                        declineInvite();
                      }}
                    >
                      Decline
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p className="">
                    Hi, you have been invited to join project{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.project[0]?.label}
                    </span>{" "}
                    by company{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedBy?.companyDetails?.companyName}
                    </span>{" "}
                    as a{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {data?.invitedAs}
                    </span>
                    .
                  </p>
                  {/* <Button onClick={userExists(data)}>Click</Button> */}
                  <div className="d-flex">
                    <Button color="primary" onClick={acceptProjectInvite}>
                      Accept
                    </Button>
                    <Button
                      className=""
                      onClick={() => {
                        declineInvite();
                      }}
                    >
                      Decline
                    </Button>
                  </div>
                </>
              )}
            </>
          )}{" "}
          {data?.declined && (
            <p>
              Invitation declined or expired. Please contact team admin to send
              invitaion again
            </p>
          )}
          {data?.accepted && <p>Invitation accepted successfully</p>}
        </Container>
      )}
    </div>
  );
};

export default connect(({ auth }) => ({ auth }))(withRouter(Invite));
